<template>
  <div id="app">
    <transition
      name="fade"
      mode="out-in"
    >
      <modal-nps-score
        v-if="modal.modalVisible && modal.modalType === 'modalNpsScore'"
      />
      <modal-report-download
        v-if="modal.modalVisible && modal.modalType === 'modalReportDownload'"
      />
      <modal-upload-imagecontent
        v-if="modal.modalVisible && modal.modalType === 'modalUploadImageContent'"
      />

      <modal-journey-user_journey_inside
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyUserJourneyInside'"
      />
      <modal-add-journey-to-journey-group
        v-if="modal.modalVisible && modal.modalType === 'modalAddJourneyToJourneyGroup'"
      />
      <modal-ed-demo
        v-if="modal.modalVisible && modal.modalType === 'modalSetupEdDemo'"
      />

      <modal-package-code
        v-if="modal.modalVisible && modal.modalType === 'modalPackageCode'"
      />
      <modal-create-flipbookeditable
        v-if="modal.modalVisible && modal.modalType === 'modalFlipbookEditable'"
      />
      <modal-report-notificaiton
        v-if="modal.modalVisible && modal.modalType === 'modalReportNotification'"
      />
      <modal-journey-add-user
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyAddUserOverlay'"
      />
      <modal-journey-v2-add-user
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyV2AddUserOverlay'"
      />
      <modal-communication-add-user
        v-if="modal.modalVisible && modal.modalType === 'modalCommunicationAddUserOverlay'"
      />
      <modal-template
        v-if="modal.modalVisible && modal.modalType === 'modalCreateContent'"
      />
      <modal-v2-template
        v-if="modal.modalVisible && modal.modalType === 'modalCreateContentV2'"
      />
      <modal-quiz-detail
        v-if="modal.modalVisible && modal.modalType === 'modalQuizDetail'"
      />
      <modal-request-detail v-if="modal.modalVisible && modal.modalType === 'modalRequestDetail'" />
      <modal-checklist-detail
        v-if="modal.modalVisible && modal.modalType === 'modalChecklistDetail'"
      />
      <modal-survey-detail
        v-if="modal.modalVisible && modal.modalType === 'modalSurveyDetail'"
      />
      <modal-journey
        v-if="modal.modalVisible && modal.modalType === 'modalJourney'"
      />
      <modal-journey-v2
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyV2'"
      />
      <modal-select-journey
        v-if="modal.modalVisible && modal.modalType === 'modalSelectJourney'"
      />

      <modal-assign
        v-if="modal.modalVisible && modal.modalType === 'modalAssign'"
      />
      <modal-onboarding-assign
        v-if="modal.modalVisible && modal.modalType === 'modalOnboardingAssign'"
      />
      <modal-notification
        v-if="modal.modalVisible && modal.modalType === 'modalNotification'"
      />

      <modal-user-add
        v-if="modal.modalVisible && modal.modalType === 'modalAddUser'"
      />
      <modal-pin-add
        v-if="modal.modalVisible && modal.modalType === 'modalAddPin'"
      />
      <modal-journeyitem-users
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyItemUsers'"
      />
      <modal-journeyitem-users-v2
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyItemUsersV2'"
      />
      <modal-communicationitem-users
        v-if="modal.modalVisible && modal.modalType === 'modalCommunicaitonItemUsers'"
      />
      <modal-customer-add
        v-if="modal.modalVisible && modal.modalType === 'modalAddCustomer'"
      />

      <modal-communication-add v-if="modal.modalVisible && modal.modalType === 'modalAddCommunication'" />

      <modal-user-import
        v-if="modal.modalVisible && modal.modalType === 'modalImportUser'"
      />
      <modal-lxp-recommend
        v-if="modal.modalVisible && modal.modalType === 'modalLxpRecommend'"
      />
      <modal-surveyquiz-import
        v-if="modal.modalVisible && modal.modalType === 'modalSQBulkImport'"
      />

      <modal-journey-add-user
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyAddUser'"
      />
      <modal-journey-add-group
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyAddGroup'"
      />
      <modal-add-content-to-journey
        v-if="modal.modalVisible && modal.modalType === 'modalAddContentToJourney'"
      />
      <modal-contentpreview
        v-if="modal.modalVisible && modal.modalType === 'modalPreviewContent'"
      />
      <modal-date-time
        v-if="modal.modalVisible && modal.modalType === 'modalDateTime'"
      />
      <modal-date-time-v2
        v-if="modal.modalVisible && modal.modalType === 'modalDateTimeV2'"
      />
    </transition>
    <vue-snotify />
    <div
      v-if="$store.state.modal.multiProcessProgressBar > 0"
      class="progressbar-container"
    >
      <div class="progressbar-dialog">
        <div class="progressbar-content">
          <label>{{ $t('please_wait') }}</label>
          <div class="multiprocessbox">
            <div class="multiprocessprogressbar" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$store.state.modal.progressBarValue >= 0"
      class="progressbar-container"
    >
      <div class="progressbar-dialog">
        <div class="progressbar-content">
          <label>{{ $t('please_wait') }}</label>
          <progress-bar
            bar-color="#4a4"
            size="large"
            :val="$store.state.modal.progressBarValue"
            :text="$store.state.modal.progressBarValue +'%'"
          />
        </div>
      </div>
    </div>
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Validator } from 'vee-validate';/* eslint-disable import/extensions */
import enLocale from 'vee-validate/dist/locale/en.js';
import trLocale from 'vee-validate/dist/locale/tr.js';
import ProgressBar from 'vue-simple-progress';
import ModalTemplate from '@/container/modal/Index.vue';
import ModalV2Template from '@/container/modal/IndexModal.v2.vue';
import ModalQuizDetailTemplate from '@/container/modal/ModalQuizDetail.vue';
import ModalChecklistDetailTemplate from '@/container/modal/ModalChecklistDetail.vue';
import ModalSurveyDetailTemplate from '@/container/modal/ModalSurveyDetail.vue';
import ModalJourneyTemplate from '@/container/modal/createJourney/Index.vue';
import ModalJourneyTemplateV2 from '@/container/modal/createJourney/Index.v2.vue';
import ModalFlipbookEditable from '@/container/modal/flipbookEditable/Index.vue';
import ModalNotificationTemplate from '@/container/modal/ModalNotification.vue';
import ModalAssignTemplate from '@/container/modal/journeyAssign/Index.vue';
import ModalUserAddTemplate from '@/container/modal/user/Add.vue';
import ModalCustomerAddTemplate from '@/container/modal/ModalCustomerAdd.vue';
import ModalUserImportTemplate from '@/container/modal/user/Import.vue';
import ModalLxpRecomendationImportTemplate from '@/container/modal/user/LxpRecomendImport.vue';
import ModalJourneyAddUser from '@/container/modal/ModalJourneyAddUser.vue';
import ModalJourneyV2AddUser from '@/container/modal/ModalJourneyV2AddUser.vue';
import ModalCommunicationAddUser from '@/container/modal/ModalCommunicationAddUser.vue';
import ModalJourneyAddGroup from '@/container/modal/ModalJourneyAddGroup.vue';
import ModalAddContentToJourney from '@/container/modal/ModalAddContentToJourney.vue';
import ModalSelectJourneyTemplate from '@/container/modal/ModalSelectJourney.vue';
import ModalSurveyQuizImportTemplate from '@/container/modal/ModalSQBulkImport.vue';
import ModalJourneyItemsUsersTemplate from '@/container/modal/ModalJourneyItemsUsers.vue';
import ModalJourneyItemsUsersV2Template from '@/container/modal/ModalJourneyItemsUsersV2.vue';
import ModalReportNotification from '@/container/modal/ModalReportNotification.vue';
import ModalContentPreview from '@/container/modal/previewContent/Index.vue';
import ModalDateTime from '@/container/modal/ModalDateTime.vue';
import ModalDateTimeV2 from '@/container/modal/ModalDateTimeV2.vue';
import ModalPackageCode from '@/container/modal/ModalPackageCode.vue';
import ModalSetupEdDemo from '@/container/modal/ModalSetupEdDemo.vue';
import ModalReportDownload from '@/container/modal/ModalReportDownload.vue';
import ModalOnboardingAssign from '@/container/modal/journeyAssign/Onboarding.vue';
import ModalAddPin from '@/container/modal/ModalAddPin.vue';
import ModalAddJourneyToJourneyGroup from '@/container/modal/ModalAddJourneyToJourneyGroup.vue';
import { Translation } from '@/helpers/translation';
import ModalUploadImageContent from '@/container/modal/ModalUploadImageContent.vue';
import ModalRequestDetail from '@/container/modal/ModalRequestDetail.vue';
import ModalCommunicationAddVue from '@/container/modal/ModalCommunicationAdd.vue';
import ModalCommunicationItemUsersVue from '@/container/modal/ModalCommunicationItemUsers.vue';
import ModalJourneyUserJourneyInside from '@/container/modal/ModalJourneyUserJourneyInside.vue';
import ModalNpsScore from '@/container/modal/ModalNpsScore.vue';

const EVENTS = ['online', 'offline'];

export default {
  name: 'App',

  components: {
    'modal-journey-user_journey_inside': ModalJourneyUserJourneyInside,
    'modal-template': ModalTemplate,
    'modal-quiz-detail': ModalQuizDetailTemplate,
    'modal-checklist-detail': ModalChecklistDetailTemplate,
    'modal-survey-detail': ModalSurveyDetailTemplate,
    'modal-journey': ModalJourneyTemplate,
    'modal-journey-v2': ModalJourneyTemplateV2,
    'modal-select-journey': ModalSelectJourneyTemplate,
    'modal-assign': ModalAssignTemplate,
    'modal-onboarding-assign': ModalOnboardingAssign,
    'modal-user-add': ModalUserAddTemplate,
    'modal-customer-add': ModalCustomerAddTemplate,
    'modal-user-import': ModalUserImportTemplate,
    'modal-surveyquiz-import': ModalSurveyQuizImportTemplate,
    'modal-notification': ModalNotificationTemplate,
    'modal-journey-add-user': ModalJourneyAddUser,
    'modal-journey-v2-add-user': ModalJourneyV2AddUser,
    'modal-communication-add-user': ModalCommunicationAddUser,
    'modal-journey-add-group': ModalJourneyAddGroup,
    'modal-add-content-to-journey': ModalAddContentToJourney,
    'modal-contentpreview': ModalContentPreview,
    'modal-date-time': ModalDateTime,
    'modal-date-time-v2': ModalDateTimeV2,
    'modal-journeyitem-users': ModalJourneyItemsUsersTemplate,
    'modal-journeyitem-users-v2': ModalJourneyItemsUsersV2Template,
    'modal-create-flipbookeditable': ModalFlipbookEditable,
    'modal-report-notificaiton': ModalReportNotification,
    'modal-package-code': ModalPackageCode,
    'modal-upload-imagecontent': ModalUploadImageContent,
    'modal-ed-demo': ModalSetupEdDemo,
    'modal-pin-add': ModalAddPin,
    'modal-report-download': ModalReportDownload,
    'modal-add-journey-to-journey-group': ModalAddJourneyToJourneyGroup,
    ProgressBar,
    'modal-request-detail': ModalRequestDetail,
    'modal-lxp-recommend': ModalLxpRecomendationImportTemplate,
    'modal-communication-add': ModalCommunicationAddVue,
    'modal-communicationitem-users': ModalCommunicationItemUsersVue,
    'modal-nps-score': ModalNpsScore,
    'modal-v2-template': ModalV2Template,

  },
  data: () => ({
    isOnline: navigator.onLine || false,
    isRefreshing: false,
  }),

  watch: {
    $route(to, from) {
      const toDepth = to.name;
      const fromDepth = from.name;

      if (toDepth === 'login') {
        if (this.$recaptchaInstance) {
          this.$recaptchaInstance.showBadge();
        }
      } else if (this.isLoggedIn && this.$recaptchaInstance) {
        this.$recaptchaInstance.hideBadge();
      }
      console.log('from:', fromDepth, 'To:', toDepth);
    },
    isOnline(newValue) {
      if (newValue) {
        this.$snotify.clear();
        this.$snotify.success(this.$t('yes_network'));
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        this.$helpers.displayError(this.$t('no_network'));
      }
    },
    $recaptchaInstance(val) {
      if (this.isLoggedIn && val) {
        val.hideBadge();
      }
    },
    userLang(newValue) {
      if (newValue) {
        this.loadLocale(newValue);
        Translation.changeLanguage(newValue);
      }
    },
    isLoggedIn: {
      handler(val) {
        if (val) {
          if (this.$recaptchaInstance) {
            this.$recaptchaInstance.hideBadge();
          }
          // eslint-disable-next-line no-unused-vars
          this.randomInterval((stop) => {
            if (!this.isLoggedIn) {
              stop();
            }
            const $timeToExpire = this.$store.state.auth.timeToExpire;
            const currentTime = Math.floor(Date.now() / 1000);
            const isRefreshable = $timeToExpire - currentTime < 3600;
            if (isRefreshable && this.isLoggedIn) {
              this.$store.dispatch('auth/refreshToken');
            }
          }, 1800000, 3600000);// check between 30min to 1 hour any random time
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    ...mapGetters({
      userLang: 'auth/userLang',
      isLoggedIn: 'auth/isLoggedIn',
    }),

    modalVisible() {
      return this.modal.modalVisible;
    },
  },

  created() {
    EVENTS.forEach(event => window.addEventListener(event, this.onlineStatus));
    if (this.userLang) {
      Translation.changeLanguage(this.userLang);
      this.loadLocale(this.userLang);
    }
  },

  beforeDestroy() {
    EVENTS.forEach(event => window.removeEventListener(event, this.onlineStatus));
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login');
      });
    },
    randomInterval(callback, min, max) {
      const randomNum = (mmax, mmin = 0) => Math.random() * (mmax - mmin) + mmin;

      let targetTime = randomNum(min, max);
      let lastInvoke = performance.now();

      const stop = () => { targetTime = null; };

      const tick = () => {
        if (!targetTime) return;

        if (performance.now() - lastInvoke > targetTime) {
          lastInvoke = performance.now();
          targetTime = randomNum(min, max);
          if (callback && typeof callback === 'function') {
            callback(stop);
          }
        }

        requestAnimationFrame(tick);
      };

      tick();
    },
    loadLocale(code) {
      if (code === 'en') {
        Validator.localize(code, enLocale);
      } else {
        Validator.localize(code, trLocale);
      }
    },

    onlineStatus() {
      this.isOnline = navigator.onLine || false;
    },
  },
};
</script>
