<template>
  <div class="page mb-4 pb-4">
    <app-header
      child-header-class="container"
      :child-header-navigation="customerSubNavigation"
      is-child-header-left-actions
    >
      <div class="button-group">
        <div class="button-group__item dropdown">
          <label
            class="button button--shadow button--type-icon size-sm d-flex align-items-center"
            @click="dropdownAction('showFilters')"
          >
            <icon-container
              name="search"
              :width="14"
              :height="14"
              color="#727d92"
              view-box="0 0 14 14"
              :is-icon-class="false"
            >
              <icon-search />
            </icon-container>
          </label>
          <div
            class="dropdown__menu dropdown__menu--filter"
            v-if="isDropdown === 'showFilters'"
            v-on-clickaway="hideDropdown"
          >
            <div class="form-group form-group--filter">
              <!--suppress HtmlFormInputWithoutLabel-->
              <input
                type="text"
                v-model="filterText"
                class="form-control"
                v-sanitize
                @keyup.enter="doFilter"
                :placeholder="$t('filter_by_name')"
              >
              <button
                v-if="filterText"
                class="icon button-reset"
                @click="resetFilter"
              >
                <icon-container
                  name="reset"
                  view-box="0 0 20 20"
                >
                  <icon-reset />
                </icon-container>
              </button>
            </div>
          </div>
        </div>
        <div class="button-group__item dropdown">
          <button
            class="button button--shadow button__dropdown size-sm"
            :class="{'active': isDropdown === 'perPageDropdown' }"
            @click="dropdownShow('perPageDropdown')"
          >
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            {{ $t('view') }} {{ perPage }}
            <icon-container
              name="down"
              view-box="0 0 11 8"
              :is-icon-class="false"
            >
              <icon-down />
            </icon-container>
          </button>
          <div
            class="dropdown__menu dropdown__menu--per-page"
            v-if="isDropdown === 'perPageDropdown'"
            v-on-clickaway="hideDropdown"
          >
            <div
              class="dropdown__menu-item"
              v-for="option in pageOptions"
              :key="option.value"
              @click="pageOptionsSelected(option.value)"
            >
              {{ option.text }}
            </div>
          </div>
        </div>
      </div>
    </app-header>
    <div class="container">
      <div class="'col-6 d-flex align-content-center'">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <div class="table-responsive">
          <div
            class="col-7 d-flex justify-content-end align-items-center"
          >
            <div style="max-width: 200px">
              <label class="form-label text-center">
                {{ $t('select_dates') }}
              </label>
              <date-picker
                v-model="impressionDate"
                :type="analyzeType.name === 'daily' ? 'date':'month'"
                value-type="YYYY-MM-DD"
                :format="analyzeType.name === 'daily' ? userLang === 'en' ? 'MM-DD-YYYY':'DD-MM-YYYY':'MMMM YYYY'"
                :lang="userLang"
              />
            </div>
          </div>
          <vue-table
            :class="{ 'table-fade': tableIsLoading }"
            ref="myvuetable"
            :api-url="apiUrl"
            :api-mode="true"
            :http-fetch="myFetch"
            :fields="fields"
            :no-data-template="$t('no_data_info')"
            :per-page="perPage"
            :css="css.table"
            pagination-path="meta"
            http-method="post"
            :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
            :append-params="moreParams"
            :query-params="makeQueryParams"
            @vuetable:pagination-data="onPaginationData"
          >
            <template
              slot="active_user_ratio"
              slot-scope="props"
            >
              {{ $t('rate_info', { rate: activeUserRatio(props.rowData)}) }}
            </template>
            <template
              slot="download_ratio"
              slot-scope="props"
            >
              {{ $t('rate_info', { rate: appDownloadRatio(props.rowData)}) }}
            </template>
            <template
              slot="actions"
              slot-scope="props"
            >
              <button
                class="ui button"
                @click="onAction('customer-detail', props.rowData)"
              >
                <icon-container
                  name="search"
                  :width="14"
                  :height="14"
                  color="#727d92"
                  view-box="0 0 14 14"
                  :is-icon-class="false"
                >
                  <icon-search />
                </icon-container>
              </button>
            </template>
          </vue-table>
          <div class="pagination__info">
            <vuetable-pagination-info
              :class="{ 'table-fade': tableIsLoading }"
              ref="paginationInfo"
              :info-template="$t('pagination_info')"
              :no-data-template="$t('no_data_info')"
            />
            <div class="pagination__items">
              <vuetable-pagination
                ref="customerpagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconReset from '@/components/icons/Reset.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconDown from '@/components/icons/Down.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import AppHeader from '@/components/AppHeader.vue';
import API from '@/services/';
import myTableMixin from '@/helpers/myTableMixin.vue';

import { API_GET_DASHBOARD_CUSTOMERS } from '@/helpers/config';
import moment from 'moment';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

console.log('DashboardCustomers');

export default {
  name: 'DashboardCustomers',
  mixins: [myTableMixin],
  data() {
    return {
      analyzeType: {
        name: 'monthly',
        value: this.$t('monthly'),
      },
      moreParams: {
        year: Number(moment().format('YYYY')),
        month: Number(moment().format('MM')),
        // customer_id: this.$store.state.auth.customerId, // buna ihtiyac belki olabılır olursa kaldırısın //
      },
      isDropdown: null,
      filterText: '',
      showFilters: false,
      searchFor: '',
      perPage: 10,
      totalDataCount: 0,
      impressionDate: null,
      tableIsLoading: false,
      impressionCountCharts: {
        title: false,
        credits: {
          enabled: false,
        },
        chart: {
          type: 'bar',
          borderRadiusTopLeft: 15,
          borderRadiusTopRight: 15,
          maxHeight: 250,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: {
          min: 0,
          softMax: 100,
          title: {
            text: '',
          },
          labels: {
            overflow: 'justify',
            align: 'left',
          },
        },
        legend: false,
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
      },
      data: [],
      pagination: {
        total: null,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        next_page_url: '?per_page=10&page=2',
        prev_page_url: null,
        from: 1,
        to: 10,
      },
      css: {
        table: {
          tableWrapper: '',
          tableHeaderClass: 'mb-0',
          tableBodyClass: 'mb-0',
          tableClass: '',
          loadingClass: 'blur',
          ascendingIcon: 'fa fa-angle-up',
          descendingIcon: 'fa fa-angle-down',
          ascendingClass: 'sorted-asc',
          descendingClass: 'sorted-desc',
          sortableIcon: '',
          detailRowClass: 'vuetable-detail-row',
          handleIcon: 'fa-bars text-secondary',
          renderIcon(classes, options) {
            return `<i class="${classes.join(' ')}" ${options}></span>`;
          },
        },
        pagination: {
          wrapperClass: 'page-items-list',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'page-item',
          linkClass: 'page-link',
          paginationClass: 'pagination',
          paginationInfoClass: 'float-left',
          dropdownClass: 'form-control',
          icons: {
            first: 'fa fa-angle-double-left',
            prev: 'fa fa-angle-left',
            next: 'fa fa-angle-right',
            last: 'fa fa-angle-double-right',
          },
        },
      },

    };
  },

  components: {
    DatePicker,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'icon-container': IconContainer,
    'icon-reset': IconReset,
    'icon-search': IconSearch,
    'icon-down': IconDown,
  },

  directives: {
    'on-clickaway': onClickaway,
  },
  created() {
    this.analyzeType = {
      name: 'monthly',
      value: this.$t('monthly'),
    };
  },
  mounted() {
    this.impressionDate = moment().format('YYYY-MM-DD');
    this.getCustomerImpressionCount();
  },
  watch: {
    impressionDate: {
      // watch date then update moreparams
      handler(val) {
        if (val) {
          this.moreParams.month = Number(moment(val).format('MM'));
          this.moreParams.year = Number(moment(val).format('YYYY'));

          // if (this.$refs && this.$refs.myvuetable) {
          //   this.$refs.myvuetable.refresh();
          // }
        }
      },
      immediate: true,
    },
    totalDataCount() {
      this.pagination.total = this.totalDataCount;
    },
    perPage() {
      this.pagination.per_page = this.perPage;
    },
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'DashboardCustomers') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    userLang() {
      if (this.$store.state.auth.customerInfo) {
        return this.$store.state.auth.customerInfo.lang;
      }
      return 'en';
    },
    analyzeTypeOptions() {
      return [
        {
          name: 'daily',
          value: this.$t('daily'),
        },
        {
          name: 'monthly',
          value: this.$t('monthly'),
        },
      ];
    },
    apiUrl() {
      if (API_GET_DASHBOARD_CUSTOMERS) {
        return `${API_GET_DASHBOARD_CUSTOMERS}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    formData() {
      const postData = new FormData();
      let orderByColumnName;
      postData.append('length', this.pagination.per_page);
      postData.append('start', this.pagination.from - 1);

      if (this.orderDirection) {
        postData.append('order[0][dir]', this.orderDirection);
      } else {
        postData.append('order[0][dir]', 'desc');
      }
      if (this.orderField) {
        if (this.orderField === 'first_name') {
          orderByColumnName = 1;
        }
        if (this.orderField === 'last_name') {
          orderByColumnName = 2;
        }
        if (this.orderField === 'email') {
          orderByColumnName = 3;
        }
        if (this.orderField === 'completion_rate') {
          orderByColumnName = 4;
        }
        postData.append('order[0][column]', orderByColumnName);
      } else {
        postData.append('order[0][column]', 4);
      }
      if (this.searchFor) {
        postData.append('search[value]', this.searchFor);
      }

      return postData;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },

    pageOptions() {
      return [
        { text: `${this.$t('view')} 10`, value: 10 },
        { text: `${this.$t('view')} 20`, value: 20 },
        { text: `${this.$t('view')} 50`, value: 50 },
        { text: `${this.$t('view')} 100`, value: 100 },
      ];
    },

    customerSubNavigation() {
      return [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
        },
        {
          text: this.$t('customers_sub'),
          url: '/dashboard/customers',
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
        },

      ];
    },

    fields() {
      return [
        {
          name: 'customer_name',
          title: this.$t('customer_'),
          sortField: 'name',
          width: '30%',
        },
        {
          name: 'total_gb',
          title: this.$t('total_download_gb'),
          width: '33%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'request_count',
          title: this.$t('request_count'),
          width: '10%',
          titleClass: 'center',
          dataClass: 'center',
          formatter: (value) => {
            const formattedValue = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return formattedValue.endsWith('.00') ? formattedValue.slice(0, -3) : formattedValue;
          },
        }];
    },
  },

  /*   activated() {
    this.$refs.customertable.refresh();
  }, */

  methods: {
    activeUserRatio(data) {
      if (data && data.last_month_active_users && data.active_users_since_today) {
        return parseInt((data.last_month_active_users / data.active_users_since_today) * 100, 10);
      }
      return '-';
    },
    appDownloadRatio(data) {
      if (data && data.mobile_download_rate) {
        return data.mobile_download_rate;
      }
      return '-';
    },
    dropdownAction(value) {
      this.isDropdown = value;
    },
    getCustomerImpressionCount() {
      if (this.userCustomerId) {
        const formData = new FormData();
        formData.append('customer_id', this.userCustomerId);
        formData.append('date', this.impressionDate);
        formData.append('type', this.analyzeType.name);

        API.post(`${API_GET_DASHBOARD_CUSTOMERS}`, formData)
          .then((response) => {
            const impressionData = response.data.data;
            console.log('imression data:', impressionData);
            if (impressionData) {
              this.impressionCountCharts.xAxis.categories = Object.keys(impressionData);
              this.impressionCountCharts.series = [{ name: this.$t('total'), data: Object.values(impressionData) }];
            }
            console.log('imression data:', impressionData);
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      }
      return false;
    },
    pageOptionsSelected(value) {
      this.perPage = value;
      this.hideDropdown();
    },
    loadData() {
      return new Promise((resolve, reject) => {
        this.isLoading();
        API.post(`${API_GET_DASHBOARD_CUSTOMERS}`, this.formData)
          .then((response) => {
            this.totalDataCount = this.searchFor ? response.data.recordsFiltered : response.data.recordsTotal;
            resolve(response.data.data);
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            reject(error);
          });
      });
    },

    onPaginationData(paginationData) {
      this.$refs.customerpagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.myvuetable.changePage(page);
    },

    isLoading() {
      this.tableIsLoading = true;
    },

    isLoaded() {
      this.tableIsLoading = false;
    },

    onAction(action, data) {
      const customerId = data.id;
      if (action === 'customer-detail') {
        if (customerId) {
          this.$store.commit('modal/SET_MODAL_DATA', data);
          this.$router.push({ path: `/dashboard/customers/detail/${customerId}` });
        }
      }
    },
    dropdownShow(value) {
      this.isDropdown = value;
    },

    hideDropdown() {
      this.isDropdown = null;
    },

    doFilter() {
      this.searchFor = this.filterText;
      this.$refs.vuetable.refresh();
    },
    resetFilter() {
      this.searchFor = null;
      this.filterText = '';
      this.$refs.vuetable.refresh();
    },
  },

  table: {
    tableWrapper: '',
    tableHeaderClass: '',
    tableClass: 'ui selectable unstackable celled table',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};
</script>
