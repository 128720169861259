<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="dashboardSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="date-slot"
            slot-scope="props"
          >
            {{ getCurrentLocaleDate(props.rowData.item.activation_dt) }}
          </template>
          <template
            slot="content-name-slot"
            slot-scope="props"
          >
            {{ props.rowData.item.name }}
          </template>
          <template
            slot="journey-name-slot"
            slot-scope="props"
          >
            {{ props.rowData.as_journey_name }}
          </template>
          <template
            slot="type-name-slot"
            slot-scope="props"
          >
            <div class="d-flex justify-content-start align-items-center">
              <span
                class="icon-item mr-2"
                v-html="$helpers.iconType(props.rowData.type)"
              />
              <span>{{ $helpers.getSubTypeName(props.rowData.type) }}</span>
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button edit"
              @click="onAction('item-download', props.rowData)"
            >
              <icon-container
                name="flying"
                width="36"
                height="24"
                view-box="0 0 19 12"
              >
                <icon-flying />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconFlying from '@/components/icons/Flying.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_SURVEY_QUIZ_DATATABLES, API_EXPORT_CUSTOMER_QUIZ, API_EXPORT_CUSTOMER_SURVEY } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'DashboardQuizSurvey',
  mixins: [myTableMixin],
  data() {
    return {

    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-flying': IconFlying,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'QuizSurvey') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    apiUrl() {
      if (API_SURVEY_QUIZ_DATATABLES) {
        return `${API_SURVEY_QUIZ_DATATABLES}`;
      }
      return null;
    },

    userCustomerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    dashboardSubNavigation() {
      if (this.isVibonsAdmin) {
        return [
          {
            text: this.$t('overview'),
            url: '/dashboard/overview',
          },
          {
            text: this.$t('journeys'),
            url: '/dashboard/journeys',
          },
          {
            text: this.$t('users'),
            url: '/dashboard/users',
          },
          {
            text: this.$t('quiz_survey'),
            url: '/dashboard/quiz-survey',
          },
          {
            text: this.$t('customers_sub'),
            url: '/dashboard/customers',
          },
          {
            text: this.$t('report_demands'),
            url: '/dashboard/reportdemands',
          },
        ];
      }
      return [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
        },
      ];
    },

    fields() {
      return [{
        name: 'date-slot',
        title: this.$t('activation_date'),
        width: '15%',
        titleClass: 'center',
        dataClass: 'center',
      },
      {
        name: 'content-name-slot',
        title: this.$t('content_name'),
        width: '30%',
      },
      {
        name: 'journey-name-slot',
        title: this.$t('journey_name'),
        width: '30%',
      },
      {
        name: 'type-name-slot',
        title: this.$t('type'),
        width: '15%',
        titleClass: 'center',
        dataClass: 'center',
      },
      {
        name: 'actions',
        title: this.$t('actions'),
        width: '10%',
        titleClass: 'center',
        dataClass: 'center',
      }];
    },
  },

  methods: {
    getCurrentLocaleDate(dateVal) {
      if (this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.lang) {
        return this.$helpers.getUserLangBasedDateTimeFormat(dateVal, this.$store.state.auth.userInfo.lang, 'surveyquiz');
      }
      return dateVal;
    },

    downloadExcel(row) {
      if (!row) {
        return;
      }
      const typeName = row.item.type;
      let downloadLink = '';
      let dateOnly = false;
      if (typeName === 'QUIZ') {
        downloadLink = `${API_EXPORT_CUSTOMER_QUIZ}`;
        dateOnly = true;
      } else if (typeName === 'SURVEY') {
        downloadLink = `${API_EXPORT_CUSTOMER_SURVEY}`;
      }
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${downloadLink}`,
        dateOnly,
        reportDownloadName: `Reports-${row.as_journey_name}-${row.item.name}-${row.item.id}`,
        reportDownloadPayload: {
          customer_id: this.userCustomerId,
          content_id: row.item.content_id,
          is_first_result: 0,
        },
      };
      this.$helpers.toggleModal(obj);
    },

    onAction(action, data) {
      console.log('data:', data);
      const quizsurveyID = data.id;
      if (!quizsurveyID) {
        return;
      }
      if (action === 'item-download') {
        this.downloadExcel(data);
      }
    },
  },
};
</script>
