<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{ 'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ editMode ? $t("edit_customer") : $t("add_new_customer") }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <nav class="navigation navigation--secondary">
            <div class="container">
              <ul
                class="navigation__list nav"
                id="contentCreateTab"
                role="tablist"
              >
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ active: tabPane === 'mainTab' }"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t("main_tab") }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ active: tabPane === 'advancedSettingsTab' }"
                    @click="tabNavigation('advancedSettingsTab')"
                  >
                    {{ $t("advanced_tab") }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ active: tabPane === 'brandingTab' }"
                    @click="tabNavigation('brandingTab')"
                  >
                    {{ $t("branding_tab") }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ active: tabPane === 'welcomeemailTab' }"
                    @click="tabNavigation('welcomeemailTab')"
                  >
                    {{ $t("welcomeemailTab") }}
                  </button>
                </li>
              </ul>
            </div>
          </nav>
          <div class="modal-body">
            <div class="tab-content">
              <div
                class="tab-pane"
                v-if="tabPane === 'mainTab'"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="name"
                          class="form-label"
                        >{{ $t("name_uppercase") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.name') }"
                          type="text"
                          class="form-control"
                          id="name"
                          v-model="name"
                          data-vv-scope="customer"
                          data-vv-name="name"
                          :data-vv-as="$t('name_uppercase')"
                          :placeholder="$t('customer_name_placeholder')"
                          v-validate="'required'"
                        >
                        <span
                          v-if="errors.has('customer.name')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.name")
                        }}</span>
                      </div>

                      <div class="form-group">
                        <label
                          for="contactName"
                          class="form-label"
                        >{{
                          $t("contact_name").toUpperCase()
                        }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.contactName') }"
                          type="text"
                          class="form-control"
                          id="contactName"
                          v-model="contactName"
                          data-vv-name="contactName"
                          :data-vv-as="$t('contact_name').toUpperCase()"
                          data-vv-scope="customer"
                          :placeholder="$t('contact_name_placeholder')"
                          v-validate="'required'"
                        >
                        <span
                          v-if="errors.has('customer.contactName')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.contactName")
                        }}</span>
                      </div>

                      <div class="form-group">
                        <label
                          for="e-mail"
                          class="form-label"
                        >{{ $t("email_uppercase") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.e-mail') }"
                          type="text"
                          class="form-control"
                          id="customerEmail"
                          v-model="customerEmail"
                          data-vv-scope="customer"
                          data-vv-name="e-mail"
                          :data-vv-as="$t('email_uppercase')"
                          :placeholder="$t('customer_email_placeholder')"
                          v-validate="'required|email'"
                        >
                        <span
                          v-if="errors.has('customer.e-mail')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.e-mail")
                        }}</span>
                      </div>

                      <div class="form-group">
                        <label
                          for="contentTitle"
                          class="form-label"
                        >{{ $t("language") }}</label>
                        <multi-select
                          :class="{ 'is-danger': errors.has('customer.language') }"
                          track-by="value"
                          label="value"
                          clear-on-select
                          deselect-label
                          select-label
                          :multiple="true"
                          v-model="selectLanguages"
                          data-vv-scope="customer"
                          :options="languageOptions"
                          class="selectbox selectbox--secondary"
                          v-validate="'required'"
                          data-vv-name="language"
                          :data-vv-as="$t('language')"
                        />
                        <span
                          v-if="errors.has('customer.language')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.language")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t("error_msg_title") }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="
                      editMode
                        ? processCustomer('UPDATE_CUSTOMER')
                        : processCustomer('CREATE_CUSTOMER')
                    "
                    :disabled="isPending"
                  >
                    <span v-if="!isPending">{{ editMode ? $t("update_button") : $t("add") }}</span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-show="tabPane === 'advancedSettingsTab'"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="contentTags"
                          class="form-label"
                        >{{ $t("domain_name") }}</label>
                        <vue-tags-input
                          v-model="domain_name"
                          :tags="domain_names"
                          :add-on-key="[13, ':', ';', ',']"
                          @tags-changed="domainsChanged"
                          @before-adding-tag="checkDomain"
                          :placeholder="$t('domain_name_placeholder')"
                        /><span
                          v-if="errors.has('customer.domain_name')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.domain_name")
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="col-12"
                      v-if="editMode"
                    >
                      <div class="form-group">
                        <label
                          for="journey"
                          class="form-label"
                        >{{
                          $t("pre_assigned_journeys")
                        }}</label>
                        <vue-tags-input
                          v-model="journey"
                          :tags="journeys"
                          @tags-changed="inputUpdateJourneys"
                          :autocomplete-items="journeysToAdd"
                          :add-only-from-autocomplete="true"
                          :delete-on-backspace="false"
                          @before-adding-tag="journeyTagAdded"
                          @before-deleting-tag="journeyTagDeleted"
                          :placeholder="$t('addjourney_placeholder')"
                          class="pre-assigned-journey"
                        />
                        <input
                          type="hidden"
                          data-vv-scope="add"
                          data-vv-name="journey"
                          :data-vv-as="$t('journeys')"
                          v-model="journeysSelected"
                        >
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="kolayik_token"
                          class="form-label"
                        >{{ $t("kolayik_token") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.kolayik_token') }"
                          type="text"
                          class="form-control"
                          :disabled="editMode && disableKolayIk"
                          id="kolayik_token"
                          v-model="kolayik_token"
                          data-vv-scope="customer"
                          data-vv-name="kolayik_token"
                          :data-vv-as="$t('kolayik_token')"
                          :placeholder="$t('kolayik_token_placeholder')"
                        >
                        <button
                          v-if="disableKolayIk"
                          type="button"
                          class="button button--alert size-sm button-kolayik"
                          @click="deleteKOLAYIKTOKEN"
                        >
                          {{ $t("delete") }}
                        </button>
                        <span
                          v-if="errors.has('customer.kolayik_token')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.kolayik_token")
                        }}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="auth_token_ttl_minutes"
                          class="form-label"
                        >{{ $t("user_session_minute") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.auth_token_ttl_minutes') }"
                          type="number"
                          class="form-control"
                          id="auth_token_ttl_minutes"
                          v-model="auth_token_ttl_minutes"
                          data-vv-scope="customer"
                          data-vv-name="auth_token_ttl_minutes"
                          :data-vv-as="$t('user_session_minute')"
                          :placeholder="$t('user_session_minute_placeholder')"
                        >
                        <span
                          v-if="errors.has('customer.auth_token_ttl_minutes')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.auth_token_ttl_minutes")
                        }}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="contentTitle"
                          class="form-label"
                        >{{ $t("timezone") }}</label>
                        <multi-select
                          :class="{ 'is-danger': errors.has('customer.timezone') }"
                          clear-on-select
                          deselect-label
                          select-label
                          v-model="selectedTimezone"
                          :options="timezoneOptions"
                          class="selectbox selectbox--secondary"
                          data-vv-scope="customer"
                          v-validate="'required'"
                          data-vv-name="timezone"
                          :data-vv-as="$t('timezone')"
                        />
                        <span
                          v-if="errors.has('customer.timezone')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.timezone")
                        }}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            v-model="create_user_account_from_sso"
                            id="create_user_account_from_sso"
                            :true-value="1"
                            :false-value="0"
                          >
                          <label
                            class="swicth-label"
                            for="create_user_account_from_sso"
                          >{{
                            $t("create_user_account_from_sso")
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <div class="swicth-checkbox">
                              <input
                                type="checkbox"
                                v-model="is_pushnotification_enabled"
                                id="is_pushnotification_enabled"
                                :true-value="1"
                                :false-value="0"
                              >
                              <label
                                class="swicth-label"
                                for="is_pushnotification_enabled"
                              >{{
                                $t("is_pushnotification_enabled")
                              }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <div class="swicth-checkbox">
                              <input
                                type="checkbox"
                                v-model="is_weekly_reports_enabled"
                                id="is_weekly_reports_enabled"
                                :true-value="1"
                                :false-value="0"
                              >
                              <label
                                class="swicth-label"
                                for="is_weekly_reports_enabled"
                              >{{
                                $t("is_weekly_reports_enabled")
                              }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <transition name="accordion">
                        <div v-show="is_pushnotification_enabled">
                          <div class="form-group">
                            <label
                              for="pushServerEndpoint"
                              class="form-label"
                            >{{
                              $t("pushserver_endpoint")
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              id="pushServerEndpoint"
                              v-model="pushServerEndpoint"
                              :placeholder="$t('pushserver_endpoint_placeholder')"
                            >
                          </div>
                          <div class="form-group">
                            <label
                              for="pushServerUsername"
                              class="form-label"
                            >{{
                              $t("pushserver_username")
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              id="pushServerUsername"
                              v-model="pushServerUsername"
                              :placeholder="$t('pushserver_username_placeholder')"
                            >
                          </div>
                          <div class="form-group">
                            <label
                              for="pushServerPassword"
                              class="form-label"
                            >{{
                              $t("pushserver_password")
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              id="pushServerPassword"
                              v-model="pushServerPassword"
                              :placeholder="$t('pushserver_password_placeholder')"
                            >
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label
                        class="form-label"
                      >{{
                        $t("modules")
                      }}</label>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            v-model="lxp_services"
                            id="lxp_services"
                            :true-value="true"
                            :false-value="false"
                          >
                          <label
                            class="swicth-label"
                            for="lxp_services"
                          >{{
                            $t("lxp_services")
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            v-model="onboarding_services"
                            id="onboarding_services"
                            :true-value="true"
                            :false-value="false"
                          >
                          <label
                            class="swicth-label"
                            for="onboarding_services"
                          >{{
                            $t("onboarding_services")
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            v-model="development_services"
                            id="development_services"
                            :true-value="true"
                            :false-value="false"
                          >
                          <label
                            class="swicth-label"
                            for="development_services"
                          >{{
                            $t("development_services")
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            v-model="communication_services"
                            id="communication_services"
                            :true-value="true"
                            :false-value="false"
                          >
                          <label
                            class="swicth-label"
                            for="communication_services"
                          >{{
                            $t("communication_services")
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="button button--primary"
                      @click="tabNavigation('mainTab')"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="tabPane === 'brandingTab'"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-8">
                      <div class="form-group">
                        <label
                          for="name"
                          class="form-label"
                        >{{ $t("email_sender_name") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.email_sender_name') }"
                          type="text"
                          class="form-control"
                          id="email_sender_name"
                          v-model="email_sender_name"
                          data-vv-scope="customer"
                          data-vv-name="email_sender_name"
                          :data-vv-as="$t('email_sender_name')"
                          :placeholder="$t('customer_email_sender_name_placeholder')"
                        >
                        <span
                          v-if="errors.has('customer.email_sender_name')"
                          class="help is-danger"
                        >{{ errors.first("customer.email_sender_name") }}</span>
                      </div>
                      <div class="form-group">
                        <label
                          for="splashImage"
                          class="form-label"
                        >{{
                          $t("email_logo_image")
                        }}</label>
                        <thumbnail-container
                          :class="{ 'is-danger': errors.has('customer.logo') }"
                          :thumbnail-image="logoImagePreview"
                          vee-rule="size:2000|mimes:image/jpeg,image/png"
                          vee-scoop-name="customer"
                          vee-name="logo"
                          @update="logoImageAdded"
                          class="card card--upload logo"
                        />
                        <span
                          v-show="errors.has('customer.logo')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.logo")
                        }}</span>
                      </div>
                      <div class="form-group">
                        <label
                          for="Ssouri"
                          class="form-label"
                        >{{ $t("sso_uri") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.sso_uri') }"
                          type="text"
                          class="form-control"
                          id="sso_uri"
                          v-model="sso_uri"
                          @blur="sso_uri = $helpers.addhttp($event.target.value)"
                          vee-rule="{url: {require_protocol: true }}"
                          data-vv-scope="customer"
                          data-vv-name="sso_uri"
                          :data-vv-as="$t('sso_uri')"
                          :placeholder="$t('sso_uri_placeholder')"
                        >
                        <span
                          v-if="errors.has('customer.sso_uri')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.sso_uri")
                        }}</span>
                      </div>
                      <div class="form-group">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            v-model="is_privacy_policy_needed"
                            id="is_privacy_policy_needed"
                            :true-value="1"
                            :false-value="0"
                          >
                          <label
                            class="swicth-label"
                            for="is_privacy_policy_needed"
                          >{{
                            $t("is_privacy_policy_needed")
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label
                          for="splashImage"
                          class="form-label"
                        >{{ $t("splash_image") }}</label>
                        <thumbnail-container
                          :class="{ 'is-danger': errors.has('customer.splash') }"
                          :thumbnail-image="splashImagePreview"
                          vee-rule="size:5000|mimes:image/jpeg,image/png"
                          vee-scoop-name="customer"
                          vee-name="splash"
                          @update="splashImageAdded"
                          class="card card--upload"
                        />
                        <span
                          v-show="errors.has('customer.splash')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.splash")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="button button--primary"
                      @click="tabNavigation('mainTab')"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane"
                v-show="tabPane === 'welcomeemailTab'"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="name"
                          class="form-label"
                        >{{ $t("subject_line") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('customer.welcome_email_subject') }"
                          type="text"
                          class="form-control"
                          id="welcome_email_subject"
                          v-model="welcome_email_subject"
                          data-vv-scope="customer"
                          data-vv-name="welcome_email_subject"
                          :data-vv-as="$t('subject_line')"
                          :placeholder="$t('enter_email_subject')"
                        >
                        <span
                          v-if="errors.has('customer.welcome_email_subject')"
                          class="help is-danger"
                        >{{ errors.first("customer.welcome_email_subject") }}</span>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group h-100 d-flex flex-column">
                        <label
                          for="name"
                          class="form-label"
                        >{{
                          $t("welcomeemail_message")
                        }}</label>
                        <vue-editor
                          id="cbody"
                          :editor-toolbar="customToolbar"
                          v-model="welcome_email_content"
                        />
                        <span
                          v-if="errors.has('customer.welcome_email_content')"
                          class="help is-danger"
                        >{{ errors.first("customer.welcome_email_content") }}</span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label
                          for="welcomeImage"
                          class="form-label"
                        >{{
                          $t("welcome_image")
                        }}</label>
                        <thumbnail-container
                          :class="{ 'is-danger': errors.has('customer.welcomeimage') }"
                          :thumbnail-image="welcomeImagePreview"
                          vee-rule="size:5000|mimes:image/jpeg,image/png"
                          vee-scoop-name="customer"
                          vee-name="welcomeimage"
                          @update="welcomeImageAdded"
                          class="card card--upload"
                        />
                        <span
                          v-show="errors.has('customer.welcomeimage')"
                          class="help is-danger"
                        >{{
                          errors.first("customer.welcomeimage")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="button button--primary"
                      @click="tabNavigation('mainTab')"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vueTagsInput from '@johmun/vue-tags-input';
import ThumbnailContainer from '@/components/Thumbnail.vue';
import { VueEditor } from 'vue2-editor';

import API from '@/services/';
import {
  API_GET_TIMEZONES,
  API_GET_LANGUAGES,
  API_JOURNEY_FILTERED,
  API_CUSTOMER_PREASSIGN_JOURNEY,
  API_CUSTOMER_GET_PREASSIGN_JOURNEYS,
  API_RESET_KOLAYIK_TOKEN,
} from '@/helpers/config';

export default {
  name: 'ModalAddCustomer',
  inject: ['$validator'],

  data() {
    return {
      domain_name: '',
      domain_names: [],
      domainInput: '',
      domainNames: '',
      splashImagePreview: '',
      splashImage: null,
      welcomeImagePreview: '',
      welcomeImage: null,
      welcome_email_content: '',
      sso_uri: '',
      logoImage: null,
      logoImagePreview: '',
      email_sender_name: '',
      welcome_email_subject: '',
      is_privacy_policy_needed: 0,
      is_weekly_reports_enabled: 0,
      create_user_account_from_sso: 0,
      is_pushnotification_enabled: 0,
      pushServerEndpoint: null,
      pushServerUsername: null,
      pushServerPassword: null,
      preAssignedJourneyIds: [],
      journeysToAdd: [],
      journey: '',
      journeys: [],
      journeysSelected: null,
      debounce: null,
      isPending: false,
      snotifyQueueList: [],
      services: [],
      lxp_services: false,
      onboarding_services: false,
      development_services: false,
      communication_services: false,
      formSending: false,
      tabPane: 'mainTab',
      contactName: '',
      name: '',
      kolayik_token: '',
      disableKolayIk: false,
      customerEmail: '',
      customer_id: null,
      auth_token_ttl_minutes: 262800,
      data: [],
      selectedLanguages: null,
      selectLanguages: [],
      languageOptions: [],
      selectedTimezone: 'Europe/Istanbul',
      timezoneOptions: [],
      is_slack_enabled: 0,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
    };
  },

  components: {
    'multi-select': Multiselect,
    'thumbnail-container': ThumbnailContainer,
    VueEditor,
    VueTagsInput: vueTagsInput,
  },

  computed: {
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    customerId() {
      return this.$store.state.modal.customerIdToEdit;
    },
    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },
    editMode() {
      return this.$store.state.modal.editMode;
    },
    modalData() {
      return this.$store.state.modal.modalData;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    multiProcessProgressBar: {
      get() {
        return this.$store.state.modal.multiProcessProgressBar;
      },
      set(newValue) {
        this.$store.state.modal.multiProcessProgressBar = newValue;
      },
    },
  },

  watch: {
    async modalData(newValue) {
      const $newValue = newValue;
      if (this.editMode && $newValue) {
        this.name = $newValue.name;
        this.contactName = $newValue.contact_name;
        this.customerEmail = $newValue.email;
        if ($newValue.auth_token_ttl_minutes) {
          this.auth_token_ttl_minutes = $newValue.auth_token_ttl_minutes;
        }
        this.kolayik_token = $newValue.kolayik_token_preview;
        if ($newValue.kolayik_token_preview) {
          this.disableKolayIk = true;
        }
        this.selectedTimezone = $newValue.timezone;
        this.customer_id = $newValue.id;
        this.domainInput = $newValue.domain_name;
        this.is_slack_enabled = $newValue.is_slack_enabled;
        this.is_pushnotification_enabled = $newValue.push_server_type;
        this.pushServerEndpoint = $newValue.push_endpoint;
        this.pushServerUsername = $newValue.push_username;
        this.pushServerPassword = $newValue.push_password;
        this.sso_uri = $newValue.sso_uri;
        this.email_sender_name = $newValue.email_sender_name;
        this.welcome_email_subject = $newValue.welcome_email_subject;
        this.is_privacy_policy_needed = $newValue.is_privacy_policy_needed;
        this.is_weekly_reports_enabled = $newValue.is_weekly_reports_enabled;
        this.create_user_account_from_sso = $newValue.create_user_account_from_sso;
        this.welcome_email_content = $newValue.welcome_email_content;
        if ($newValue.logo_url) {
          this.logoImagePreview = $newValue.logo_url;
        }
        if ($newValue.services) {
          const newArr = $newValue.services.filter(a => a);
          this.services = newArr;
          if (this.services.includes('LXP')) {
            this.lxp_services = true;
          }
          if (this.services.includes('Development')) {
            this.development_services = true;
          }
          if (this.services.includes('Onboarding')) {
            this.onboarding_services = true;
          }
          if (this.services.includes('Communication')) {
            this.communication_services = true;
          }
        }
        if ($newValue.splash_image_url) {
          this.splashImagePreview = $newValue.splash_image_url;
        }
        if ($newValue.welcome_email_img_url) {
          this.welcomeImagePreview = $newValue.welcome_email_img_url;
        }

        if ($newValue.langs) {
          this.selectLanguages = $newValue.langs.split(',').map((k) => {
            if (k === 'en') {
              return { name: k, value: 'English' };
            }
            if (k === 'tr') {
              return { name: k, value: 'Türkçe' };
            }
            return {};
          });
        }
        const searchInput = document.querySelector('.pre-assigned-journey .ti-new-tag-input');
        searchInput.classList.add('loading-icon');
        const preAssignedJourneys = await this.getPreAssignedJourneys();
        preAssignedJourneys.forEach(a => this.journeys.push({
          text: `${a.name} - [${a.id}]`,
          id: `${a.id}`,
          user_count: a.users_count,
        }));
        searchInput.classList.remove('loading-icon');
        this.multiProcessProgressBar = false;
      }
    },

    selectLanguages: {
      handler(newValue) {
        if (newValue) {
          this.selectedLanguages = newValue.map(k => k.name).join(',');
        }
      },
      immediate: false,
      deep: true,
    },

    domainInput: {
      handler(newVal) {
        if (newVal) {
          this.domain_names = newVal.split(',').map(k => ({ text: k }));
        }
      },
      immediate: true,
    },

    domain_names: {
      handler(newVal) {
        if (newVal) {
          this.domainNames = newVal.map(k => k.text).join(',');
        }
      },
      immediate: true,
    },
    lxp_services: {
      handler(newVal) {
        if (newVal && !this.services.includes('LXP')) {
          this.services.push('LXP');
        }
        if (newVal === false && this.services.includes('LXP')) {
          const index = this.services.findIndex(a => a === 'LXP');
          if (index > -1) {
            this.services.splice(index, 1);
          }
        }
      },
      immediate: true,
    },
    onboarding_services: {
      handler(newVal) {
        if (newVal && !this.services.includes('Onboarding')) {
          this.services.push('Onboarding');
        }
        if (newVal === false && this.services.includes('Onboarding')) {
          const index = this.services.findIndex(a => a === 'Onboarding');
          if (index > -1) {
            this.services.splice(index, 1);
          }
        }
      },
      immediate: true,
    },
    development_services: {
      handler(newVal) {
        if (newVal && !this.services.includes('Development')) {
          this.services.push('Development');
        }
        if (newVal === false && this.services.includes('Development')) {
          const index = this.services.findIndex(a => a === 'Development');
          if (index > -1) {
            this.services.splice(index, 1);
          }
        }
      },
      immediate: true,
    },
    communication_services: {
      handler(newVal) {
        if (newVal && !this.services.includes('Communication')) {
          this.services.push('Communication');
        }
        if (newVal === false && this.services.includes('Communication')) {
          const index = this.services.findIndex(a => a === 'Communication');
          if (index > -1) {
            this.services.splice(index, 1);
          }
        }
      },
      immediate: true,
    },
    journey: 'getJourneys',
  },

  created() {
    const self = this;
    API.get(API_GET_LANGUAGES).then((response) => {
      self.languageOptions = response.data;
    });

    API.get(API_GET_TIMEZONES).then((response) => {
      self.timezoneOptions = response.data;
    });
  },

  mounted() {
    if (this.customerId && this.editMode) {
      this.putInEditMode();
      this.multiProcessProgressBar = true;
    }
    if (this.userLang && !this.editMode) {
      if (this.userLang === 'en') {
        this.selectLanguages.push({ name: this.userLang, value: 'English' });
      }
      if (this.userLang === 'tr') {
        this.selectLanguages.push({ name: this.userLang, value: 'Türkçe' });
      }
    }
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
      this.deleteIdsOncePreassignedCancelled();
    },
    checkEvent($event) {
      this.$nextTick(() => {
        console.log('$event:', $event);
      });
    },
    deleteIdsOncePreassignedCancelled() {
      if (this.preAssignedJourneyIds.length > 0) {
        this.preAssignedJourneyIds.forEach(journeyid => this.deletePreassignedJourney(journeyid));
      }
    },

    getPreAssignedJourneys() {
      return new Promise((resolve, reject) => {
        const formData = { params: {} };
        formData.params.customer_id = this.customer_id;
        API.get(API_CUSTOMER_GET_PREASSIGN_JOURNEYS, formData)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    filterFromJourneysArray(array, arrayForFilters) {
      const filters = {
        id: arrayForFilters,
      };
      const filterKeys = Object.keys(filters);
      const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);
      return array.filter(item => filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        return filters[key].find(filter => getValue(filter) === getValue(item[key]));
      }));
    },

    tabNavigation(value) {
      this.tabPane = value;
    },

    domainsChanged(newVal) {
      this.$validator.errors.clear();
      this.domain_names = newVal;
    },

    checkDomain(obj) {
      const rule = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/gim;
      if (rule.test(obj.tag.text)) {
        obj.addTag();
      } else {
        this.$validator.errors.clear();
        this.$validator.errors.add({
          field: 'domain_name',
          msg: this.$t('invalid_domain_name'),
          scope: 'customer',
        });
      }
    },

    journeyTagAdded(event) {
      event.addTag();
      if (event.tag.id) {
        this.preAssignedJourneyIds.push(event.tag.id);
        const formData = new FormData();
        formData.append('journey_id', event.tag.id);
        formData.append('customer_id', this.customer_id);
        const searchInput = document.querySelector('.pre-assigned-journey .ti-new-tag-input');
        API.post(API_CUSTOMER_PREASSIGN_JOURNEY, formData)
          .then((response) => {
            if (response.status === 201) {
              searchInput.classList.add('assign-check-success');
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
        setTimeout(() => {
          searchInput.classList.remove('assign-check-success');
        }, 2000);
      }
    },

    journeyTagDeleted(event) {
      event.deleteTag();
      if (event.tag.id) {
        this.deletePreassignedJourney(event.tag.id);
      }
    },
    deleteKOLAYIKTOKEN() {
      API.post(`${API_RESET_KOLAYIK_TOKEN}`, { customer_id: this.customer_id })
        .then((response) => {
          if (response.status === 201) {
            this.kolayik_token = '';
            this.disableKolayIk = false;
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },
    deletePreassignedJourney(journeyId) {
      const searchInput = document.querySelector('.pre-assigned-journey .ti-new-tag-input');
      API.delete(`${API_CUSTOMER_PREASSIGN_JOURNEY}/${this.customer_id}/${journeyId}`)
        .then((response) => {
          if (response.status === 201) {
            searchInput.classList.add('assign-check-success');
            const index = this.preAssignedJourneyIds.indexOf(journeyId);
            if (index > -1) {
              this.preAssignedJourneyIds.splice(index, 1);
            }
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
      setTimeout(() => {
        searchInput.classList.remove('assign-check-success');
      }, 2000);
    },

    splashImageAdded(file) {
      if (file) {
        [this.splashImagePreview, this.splashImage] = file;
      } else {
        this.splashImagePreview = null;
        this.splashImage = null;
      }
    },
    welcomeImageAdded(file) {
      if (file) {
        [this.welcomeImagePreview, this.welcomeImage] = file;
      } else {
        this.welcomeImagePreview = null;
        this.welcomeImage = null;
      }
    },
    logoImageAdded(file) {
      if (file) {
        [this.logoImagePreview, this.logoImage] = file;
      } else {
        this.logoImagePreview = null;
        this.logoImage = null;
      }
    },

    processCustomer(type) {
      this.$validator.validateAll('customer').then((result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          this.$store.commit('modal/setPageToRefresh', 'Customers');
          const self = this;
          const successMsg = type === 'CREATE_CUSTOMER'
            ? this.$t('customer_added_success')
            : this.$t('customer_updated_success');
          this.$store.dispatch(`modal/${type}`, self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
              self.$store.commit('modal/toggleModal');
              this.preAssignedJourneyIds = [];
            } else {
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            }
          });
        }
      });
    },

    putInEditMode() {
      this.$store.dispatch('modal/GET_CUSTOMER');
    },

    inputUpdateJourneys(newTags) {
      this.journeys = newTags;
    },

    getJourneys() {
      this.journeysToAdd = [];

      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.search = this.journey;
      formData.params.customer_id = this.customerId;

      if (this.journey.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.pre-assigned-journey .ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_JOURNEY_FILTERED, formData)
          .then((response) => {
            this.journeysToAdd = response.data.data
              .map(a => ({
                text: `${a.name} - [${a.id}]`,
                id: `${a.id}`,
                user_count: a.user_count,
              }))
              .filter(item => item.user_count > 0);
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    async getAllCustomerJourneysForFilter() {
      let customerAllJourneys = null;
      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.customer_id = this.customerId;

      await API.get(API_JOURNEY_FILTERED, formData)
        .then((response) => {
          customerAllJourneys = response.data.data;
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
      return customerAllJourneys;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.pagination__items {
  width: 100%;
  text-align: center;
}
.card--upload {
  height: 280px;
  &.logo {
    height: 90px;
    width: 160px;
  }
}

::v-deep .media-object__media svg {
  position: absolute;
  pointer-events: none;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}
.button-kolayik {
  position: absolute;
  display: inline-flex;
  right: 18px;
  margin-top: 3.5px;
}
</style>
