import Vue from 'vue';
import { flip } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import {
  API_COMMUNICATION,
  API_GET_CONTENT_INFO,
  API_GET_CONTENT_TYPES,
  API_GET_CONTENT_SUBTYPES,
  API_CONTENT_VIDEO,
  API_CONTENT_VIDEO_CDN,
  API_CONTENT_SCORM,
  API_CONTENT_FLIPBOARD,
  API_CONTENT_INFOGRAPHICS,
  API_CONTENT_ARTICLE,
  API_CONTENT_EXTERNAL_LINK,
  API_POST_JOURNEY,
  API_JOURNEY_ITEM,
  API_UPDATE_CONTENT_VIDEO,
  API_UPDATE_CONTENT_VIDEO_CDN,
  API_UPDATE_CONTENT_EXTERNAL_LINK,
  API_UPDATE_CONTENT_EXTERNAL_PDF_LINK,
  API_CONTENT_EXTERNAL_PDF_LINK,
  API_UPDATE_CONTENT_INFOGRAPHICS,
  API_UPDATE_CONTENT_ARTICLE,
  API_UPDATE_CONTENT_FLIPBOARD,
  API_JOURNEY_UPDATE,
  API_USER,
  API_CONTENT_QUIZ,
  API_CONTENT_QUIZ_QUESTION,
  API_CONTENT_QUIZ_QUESTION_CHOICE,
  API_UPDATE_CONTENT_QUIZ,
  API_CONTENT_QUIZ_DELETE,
  API_CONTENT_SURVEY,
  API_CONTENT_SURVEY_QUESTION,
  API_CONTENT_SURVEY_QUESTION_CHOICE,
  API_UPDATE_CONTENT_SURVEY,
  API_SURVEY_ORDER_UPDATE,
  API_CONTENT_REQUEST,
  API_CONTENT_REQUEST_QUESTION,
  API_UPDATE_CONTENT_REQUEST,
  API_REQUEST_ORDER_UPDATE,
  API_USER_GROUP,
  API_GET_GROUP_USERS,
  API_USER_GROUP_USER,
  API_JOURNEY_NOTIFICATION,
  API_JOURNEY_ITEM_NOTIFICATION,
  API_USER_NOTIFICATION,
  API_GROUP_NOTIFICATION,
  API_CONTENT_CHECKLIST,
  API_CONTENT_CHECKLIST_ITEM,
  API_UPDATE_CONTENT_CHECKLIST,
  API_JOURNEY_SCHEDULED_NOTIFICATION,
  API_CUSTOMER,
  API_CUSTOMER_UPDATE,
  API_SERVER_TIME_FORMAT,
  API_CONTENT_LIVE_EVENT,
  API_UPDATE_LIVE_EVENT,
  API_CONTENT_REMOTE_CLASS,
  API_UPDATE_REMOTE_CLASS,
  API_FLIPBOOK_EDITABLE,
  API_DELETE_CONTENT_QUIZ,
  API_CONTENT_CHAPTER,
  API_FILE_DOWNLOAD_ADD,
  API_FILE_DOWNLOAD_UPDATE,
  API_FILE_DOWNLOAD_ADD_FILE,
  API_FILE_DOWNLOAD_FILE_UPDATE,
  API_FILE_DOWNLOAD_FILE_DELETE,
  API_JOURNEY_REMINDER,
  API_JOURNEY_V2_USERS,
  // API_POST_JOURNEY_V2_LIST,
  API_POST_JOURNEY_V2_CREATE,
  API_POST_JOURNEY_V2_UPDATE,
  API_POST_JOURNEY_V2_CONTENT_ADD,
  API_POST_JOURNEY_V2_CONTENT_UPDATE,
} from '@/helpers/config';
import subtypeList from '../../helpers/subtypes';

import sanitizeSVG from '../../helpers/sanitizeSVG';

const moment = require('moment');
const { ConcurrencyManager } = require('axios-concurrency');
// a concurrency parameter of 1 makes all api requests sequential
const MAX_CONCURRENT_REQUESTS = 1;
const CONTENT_NAME = '{CONTENT_NAME}';

export default {
  namespaced: true,

  state: {
    contentDetail: null,
    contentId: null,
    contentSubTypes: null,
    contentTypes: null,
    customerIdToEdit: null,
    communicationIdToEdit: null,
    editMode: false,
    flipsData: [],
    formError: false,
    groupId: null,
    importType: null,
    isDisabled: false,
    isInfoLabelsHidden: 0,
    isStatic: 0,
    journeyCustomerId: null,
    journeyDetail: null,
    journeyId: null,
    journeyItemActivationDate: null,
    journeyItemDetail: null,
    journeyItemId: null,
    journeyItems: null,
    lastItemActivationTime: null,
    isOnlyDatesEditable: false,
    launchedFrom: null,
    isJourneyTypeV2: false,
    modalContentType: 'video',
    modalData: null,
    modalItemV2: null,
    modalType: 'modalCreateContent',
    modalVisible: false,
    multiProcessProgressBar: false,
    overlayModal: false,
    pageOptions: null,
    pageToRefresh: null,
    progressBarValue: -1,
    refreshPage: false,
    selectedToAssign: null,
    showPreview: false,
    sNotification: false,
    subtype_id: 1,
    syncIsActive: true,
    tabPane: 'mainTab',
    uploadFileStatus: [],
    previewContent: null,
    totalChapterNum: 0,
    reportDownloadUrl: null,
    dateOnly: false,
    omitDate: false,
    reportDownloadPayload: null,
    reportModalTitle: null,
    reportDownloadName: null,
    reportDownloadRequest: null,
    reportDownloadHasEndDate: null,
    reportDownloadHasUserStatus: null,
    previousState: null,
  },

  mutations: {
    setFlipsData(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.flipsData = $payload;
    },
    resetDowloadReportParams(state) {
      const $state = state;
      $state.reportDownloadUrl = null;
      $state.reportDownloadPayload = null;
      $state.reportModalTitle = null;
      $state.reportDownloadName = null;
      $state.dateOnly = false;
      $state.omitDate = false;
      $state.reportDownloadRequest = null;
      $state.reportDownloadHasEndDate = null;
      $state.reportDownloadHasUserStatus = null;
    },
    setFlipSyncStatus(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.syncIsActive = $payload;
    },

    pushFlipsData(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.flipsData.push($payload);
    },

    setJourneyDetail(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.journeyDetail = $payload;
    },
    setTotalChapter(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.totalChapterNum = $payload;
    },
    setJourneyItems(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.journeyItems = $payload;
    },

    setJourneyItemDetail(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.journeyItemDetail = $payload;
    },

    setJourneyId(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.journeyId = $payload;
    },

    setJourneyItemID(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.journeyItemId = $payload;
    },

    setProgressBarValue(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.progressBarValue = $payload;
    },

    deleteUploadTempFile(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.uploadFileStatus.splice($state.uploadFileStatus.findIndex(i => i.id === $payload), 1);
    },

    setUploadFileStatus(state, payload) {
      const $state = state;
      const $payload = payload;
      const isExist = $state.uploadFileStatus.find(file => file.id === $payload.id);
      if (isExist) {
        isExist.progress = $payload.progress;
        isExist.hasError = $payload.hasError;
      } else {
        $state.uploadFileStatus.push($payload);
      }
    },

    resetUploadFileStatus(state) {
      const $state = state;
      $state.uploadFileStatus = [];
    },

    setMultiProcessProgressBarValue(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.multiProcessProgressBar = $payload;
    },

    setLastItemActivationTime(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.lastItemActivationTime = $payload;
    },

    setPageToRefresh(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.pageToRefresh = $payload;
    },

    toggleModal(state, payload) {
      const $state = state;
      const $payload = payload;
      if ($payload && typeof $payload === 'object') {
        Object.assign($state, $payload);
      } else if (typeof $payload !== 'undefined' && typeof $payload !== 'object') {
        $state.modalType = $payload;
      }
      $state.modalVisible = !$state.modalVisible;
      if ($state.modalVisible === false) {
        $state.editMode = false;
        $state.modalData = null;
        $state.modalItemV2 = null;
        $state.dayNumber = null;
        $state.activationTime = null;
        $state.roleType = null;
        $state.isJourneyTypeV2 = false;
      }
      if (typeof $payload === 'undefined' || $payload === false) {
        if ($state.previousState) {
          Object.assign($state, $state.previousState);
          $state.modalVisible = true;
        }
      }
    },

    togglePreviewModal(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.modalType = 'modalPreviewContent';
      if ($payload && typeof $payload === 'object') {
        if ($payload.id) {
          $state.contentId = $payload.id;
        }
        if ($payload.launchedFrom) {
          $state.launchedFrom = $payload.launchedFrom;
        }
        if ($payload.isDisabled) {
          $state.isDisabled = $payload.isDisabled;
        }
        if ($payload.isJourneyTypeV2) {
          $state.isJourneyTypeV2 = $payload.isJourneyTypeV2;
        }

        $state.modalVisible = true;
      } else {
        $state.modalVisible = false;
        $state.launchedFrom = null;
        $state.isDisabled = false;
        $state.isJourneyTypeV2 = false;
      }
    },
    toggleCalendarPreviewModal(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.contentId = $payload.id;
      $state.previewContent = $payload.preview_content;
      $state.launchedFrom = 'calendar';
    },
    MODAL_TYPE: (state, payload) => {
      const $state = state;
      const $payload = payload;
      $state.modalType = $payload;
    },

    MODAL_CONTENT_TYPE: (state, payload) => {
      const $state = state;
      const $payload = payload;
      $state.modalContentType = $payload;
    },

    SET_MODAL_DATA: (state, payload) => {
      const $state = state;
      const $payload = payload;
      $state.modalData = $payload;
      $state.formError = false;
    },

    SET_GROUP_USERS: (state, payload) => {
      const $state = state;
      const $payload = { groupUsers: payload.data };
      $state.modalData = { ...$state.modalData, ...$payload };
    },

    ERROR_MODAL_DATA: (state, payload) => {
      const $state = state;
      const $payload = payload;
      $state.modalData = null;
      $state.formError = $payload;
    },

    ADD_TYPES: (state, payload) => {
      const $state = state;
      const $payload = payload;
      $state.contentTypes = $payload;
    },

    ADD_SUBTYPES: (state, payload) => {
      const $state = state;
      const $payload = payload;
      $state.contentSubTypes = $payload;
    },

    REFRESH_PAGE: (state, payload) => {
      const $state = state;
      const $payload = payload;
      $state.refreshPage = $payload;
    },
    SAVE_PREVIOUS_STATE: (state) => {
      const $state = state;
      const cloned = cloneDeep($state);
      $state.previousState = cloned;
      $state.modalVisible = false;
    },
  },

  getters: {
    showModal: (state) => {
      const $state = state;
      return $state.modalVisible;
    },

    getFlipsData: (state) => {
      const $state = state;
      return $state.flipsData;
    },

    getTotalChapterNumber: (state) => {
      const $state = state;
      return $state.totalChapterNum;
    },

    getJourneyId: (state) => {
      const $state = state;
      return $state.journeyId;
    },

    getFileUploadProgress: state => (id) => {
      const $state = state;
      const item = $state.uploadFileStatus.find(file => file.id === id);
      if (item && item.progress) {
        return item.progress;
      }
      return 0;
    },

    getFileUploadStatus: state => (id) => {
      const $state = state;
      const item = $state.uploadFileStatus.find(file => file.id === id);
      if (item) {
        return !!item.hasError;
      }
      return false;
    },

    isFileUploading: (state) => {
      const $state = state;
      const isUploading = $state.uploadFileStatus.filter(file => file.progress < 101);
      return !!(isUploading.length);
    },

    MODAL_TYPE: (state) => {
      const $state = state;
      return $state.modalType;
    },

    MODAL_CONTENT_TYPE: (state) => {
      const $state = state;
      return $state.modalContentType;
    },

    MODAL_DATA: (state) => {
      const $state = state;
      return $state.modalData;
    },
  },

  actions: {
    SAVE_PREVIOUS_STATE: async ({ commit }) => {
      commit('SAVE_PREVIOUS_STATE');
    },
    ADD_FLIPS: async ({ commit }, payload) => {
      if (payload instanceof Array) {
        commit('setFlipsData', payload);
      } else {
        commit('pushFlipsData', payload);
      }
    },

    SET_FLIPS_SYNC: async ({ commit }, payload) => {
      commit('setFlipSyncStatus', payload);
    },

    RESET_DOWNLOAD_REPORT: async ({ commit }) => {
      commit('resetDowloadReportParams');
    },

    DELETE_UPLOAD_FILE_PREVIEW_ITEM: async ({ commit }, payload) => {
      commit('deleteUploadTempFile', payload);
    },

    DISPLAY_ERROR: async (_context, payload) => {
      let errorMsgBody = '';
      let errorMsgTitle = '';

      if (typeof payload === 'object' && payload.response && payload.response.data && payload.response.data.errors) {
        const { errors } = payload.response.data;
        Object.keys(errors).forEach((error) => {
          errorMsgBody += `${errors[error]}\n\n`;
        });
        errorMsgTitle = 'Error';
      } else if (typeof payload === 'object' && payload.response && payload.response.data && (payload.response.data.StatusMessage || payload.response.data.message)) {
        errorMsgTitle = 'Error';
        errorMsgBody = payload.response.data.StatusMessage || payload.response.data.message;
      } else {
        errorMsgTitle = 'Error';
        errorMsgBody = payload;
      }

      Vue.prototype.$snotify.error(errorMsgBody, errorMsgTitle, {
        showProgressBar: false,
        closeOnClick: true,
        timeout: 0,
        type: 'error',
        titleMaxLength: 20,
        pauseOnHover: false,
        buttons: [
          {
            text: 'I got it',
            action: (toast) => {
              Vue.prototype.$snotify.remove(toast.id);
            },
          },
        ],
      });
    },

    GET_ITEM_LAST_ACTIVATION_TIME: async (context) => {
      const now = moment();
      let itemTime = null;
      const { lastItemActivationTime } = context.state;
      if (lastItemActivationTime) {
        const timeDiff = moment(lastItemActivationTime, API_SERVER_TIME_FORMAT).diff(now, 'minutes');
        if (timeDiff > 6) {
          itemTime = now;
        } else {
          itemTime = moment(lastItemActivationTime, API_SERVER_TIME_FORMAT).add(6, 'minutes');
        }
      } else {
        itemTime = now;
      }
      if (typeof itemTime === 'object' && itemTime !== null) {
        itemTime = itemTime.format(API_SERVER_TIME_FORMAT);
      }
      console.log('GET_ITEM_LAST_ACTIVATION_TIME:', itemTime);
      return itemTime;
    },

    GET_CONTENT_TYPES: async (context, payload) => {
      await Vue.prototype.$API.get(API_GET_CONTENT_TYPES)
        .then(() => {
          context.commit('ADD_TYPES', payload);
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    GET_CONTENT_SUBTYPES: async (context, payload) => {
      await Vue.prototype.$API.get(API_GET_CONTENT_SUBTYPES)
        .then(() => {
          context.commit('ADD_SUBTYPES', payload);
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    GET_CONTENT: async (context) => {
      const ctx = context;
      ctx.modalData = null;
      await Vue.prototype.$API.get(`${API_GET_CONTENT_INFO}/${context.state.contentId}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
            // setTimeout(() => {
            //   ctx.state.contentId = null;
            // }, 1000);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    DELETE_CONTENT: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_GET_CONTENT_INFO}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    GET_SNOTIFICATION_CONTENT: async (context) => {
      const ctx = context;
      ctx.modalData = null;
      await Vue.prototype.$API.get(`${API_JOURNEY_SCHEDULED_NOTIFICATION}/${context.state.contentId}`)
        .then((response) => {
          if (response.status === 200) {
            let snotification = {
              sub_type: 'notification',
              type: 'notification',
            };
            snotification = { ...response.data, ...snotification };
            context.commit('SET_MODAL_DATA', snotification);
            setTimeout(() => {
              ctx.state.contentId = null;
            }, 100);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    GET_JOURNEY: async (context) => {
      const ctx = context;
      ctx.modalData = null;
      await Vue.prototype.$API.get(`${API_POST_JOURNEY}/${context.state.journeyId}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
            // setTimeout(() => {
            //   ctx.state.journeyId = null;
            // }, 100);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    CREATE_JOURNEY_V2: async (context, payload) => {
      const formData = new FormData();
      formData.append('as_customer_id', context.rootState.auth.customerId);
      formData.append('name', Vue.prototype.$sanitize(payload.journeyName));
      formData.append('is_mandatory', payload.is_mandatory);
      formData.append('is_public', payload.is_public);
      formData.append('is_in_lxp_library', payload.is_in_lxp_library);
      formData.append('type', payload.journeyType);
      const isSelfPaced = payload.is_self_paced ? 1 : 0;
      formData.append('is_self_paced', isSelfPaced);

      if (payload.journeyThumbnail !== null) {
        formData.append('cover', payload.journeyThumbnail);
      }
      formData.append('friendly_name', Vue.prototype.$sanitize(payload.journeyNameFriendly));

      const options = {};
      options.lang = payload.selectedLanguage.name;
      options.notification = {
        title: Vue.prototype.$sanitize(payload.push_notification_title),
        body: Vue.prototype.$sanitize(payload.push_notification_body),
        is_enabled: payload.is_welcome_notifications_enabled,
      };
      options.is_list_on_the_catalog_enabled = payload.statusCatalog;
      formData.append('options', JSON.stringify(options));
      await Vue.prototype.$API.post(API_POST_JOURNEY_V2_CREATE, formData)
        .then((response) => {
          if (response.status === 200) {
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_JOURNEY_V2: async (context, payload) => {
      const formData = new FormData();
      formData.append('as_customer_id', payload.as_customer_id);
      formData.append('id', payload.journey_id);
      formData.append('name', Vue.prototype.$sanitize(payload.journeyName));
      formData.append('is_mandatory', payload.is_mandatory);
      formData.append('is_public', payload.is_public);
      formData.append('is_in_lxp_library', payload.is_in_lxp_library);
      formData.append('type', payload.journeyType);
      const isSelfPaced = payload.is_self_paced ? 1 : 0;
      formData.append('is_self_paced', isSelfPaced);

      if (payload.journeyThumbnail !== null) {
        formData.append('cover', payload.journeyThumbnail);
      }
      formData.append('friendly_name', Vue.prototype.$sanitize(payload.journeyNameFriendly));

      const options = {};
      options.lang = payload.selectedLanguage.name;
      options.notification = {
        title: Vue.prototype.$sanitize(payload.push_notification_title),
        body: Vue.prototype.$sanitize(payload.push_notification_body),
        is_enabled: payload.is_welcome_notifications_enabled,
      };
      options.is_list_on_the_catalog_enabled = payload.statusCatalog;
      formData.append('options', JSON.stringify(options));
      await Vue.prototype.$API.post(API_POST_JOURNEY_V2_UPDATE, formData)
        .then((response) => {
          if (response.status === 200) {
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    CREATE_JOURNEY: async (context, payload) => {
      // const time = moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DD 00:00:01');
      const formData = new FormData();
      formData.append('customer_id', context.rootState.auth.customerId);
      formData.append('name', Vue.prototype.$sanitize(payload.journeyName));
      formData.append('desc', Vue.prototype.$sanitize(payload.journeyName));
      formData.append('activation_date', payload.activation_date);
      if (payload.deadline) {
        formData.append('deadline', moment(payload.deadline).format(API_SERVER_TIME_FORMAT));
      }
      formData.append('is_mandatory', payload.is_mandatory);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('lang', payload.selectedLanguage.name);
      if (payload.journeyType) {
        formData.append('journey_type', payload.journeyType);
      }
      const isSelfPaced = payload.is_self_paced ? 1 : 0;
      formData.append('is_self_paced', isSelfPaced);
      if (payload.journeyIcon !== null) {
        formData.append('cover_image', payload.journeyIcon);
      }
      if (payload.journeyThumbnail !== null) {
        formData.append('template_thumbnail_image', payload.journeyThumbnail);
      }
      formData.append('friendly_name', Vue.prototype.$sanitize(payload.journeyNameFriendly));
      formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body));
      formData.append('is_welcome_notifications_enabled', payload.is_welcome_notifications_enabled);
      formData.append('is_list_on_the_catalog_enabled', payload.statusCatalog);

      await Vue.prototype.$API.post(API_POST_JOURNEY, formData)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_JOURNEY: async (context, payload) => {
      const formData = new FormData();
      formData.append('journey_id', payload.journey_id);
      formData.append('name', Vue.prototype.$sanitize(payload.journeyName));
      formData.append('is_mandatory', payload.is_mandatory);
      formData.append('friendly_name', Vue.prototype.$sanitize(payload.journeyNameFriendly));
      formData.append('activation_date', moment(payload.activation_date).format(API_SERVER_TIME_FORMAT));
      if (payload.deadline) {
        formData.append('deadline', moment(payload.deadline).format(API_SERVER_TIME_FORMAT));
      }
      formData.append('lang', payload.selectedLanguage.name);
      if (payload.journeyType) {
        formData.append('journey_type', payload.journeyType);
      }
      const isSelfPaced = payload.is_self_paced ? 1 : 0;
      formData.append('is_self_paced', isSelfPaced);
      formData.append('is_list_on_the_catalog_enabled', payload.statusCatalog);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body));
      }
      formData.append('is_welcome_notifications_enabled', payload.is_welcome_notifications_enabled);

      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.journeyIcon) {
        formData.append('cover_image', payload.journeyIcon);
      }
      if (payload.journeyThumbnail) {
        formData.append('template_thumbnail_image', payload.journeyThumbnail);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_JOURNEY_UPDATE, formData)
          .then((response) => {
            if (response.status === 200) {
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('REFRESH_PAGE', true);
    },

    ADD_CONTENT_ITEM_TO_JOURNEY_V2: async (context, payload) => {
      console.log('ADD_CONTENT_ITEM_TO_JOURNEY_V2 payload:', payload);
      const formData = new FormData();
      formData.append('as_journey2_id', payload.as_journey2_id);
      formData.append('as_content_id', payload.as_content_id);
      formData.append('days', payload.day);
      formData.append('role', payload.roleType?.value);
      const time = moment(payload.beginActivationDateTime).format('HH:mm:ss');
      formData.append('activation_time', time);
      const options = {};
      // options.lang = payload.selectedLanguage.name;
      options.notification = {
        title: Vue.prototype.$sanitize(payload?.content?.push_notification_title),
        body: Vue.prototype.$sanitize(payload?.content?.push_notification_body),
        is_email_enabled: payload.is_email_enabled,
        is_notifications_enabled: payload.is_notifications_enabled, // payload.is_notifications_enabled ? is this the one?
      };
      options.is_info_labels_hidden = payload.is_info_labels_hidden ? 1 : 0;
      // options.is_static = payload.is_static ? 1 : 0;
      // options.is_reminder_enabled = payload.is_reminder_enabled;
      formData.append('options', JSON.stringify(options));

      await Vue.prototype.$API.post(API_POST_JOURNEY_V2_CONTENT_ADD, formData)
        .then((response) => {
          if (response.status === 200) {
            // context.commit('toggleModal', false); // not really need
            // returnedJourneyItem = response.data.content;
            // context.commit('setPageToRefresh', 'journeyitemsv2');
            // context.commit('REFRESH_PAGE', true);
            console.log('Libraryden ekleneme API_POST_JOURNEY_V2_CONTENT_ADD: id:', response?.data?.data?.id);
            const returnedJourneyItem = {
              id: response?.data?.data?.id,
              as_journey2_id: payload.as_journey2_id,
              as_content_id: payload.as_content_id,
              content: payload.content,
              days: payload.day,
              role: payload.roleType?.value,
              activation_time: time,
              options,
            };
            Vue.prototype.$bus.$root.$emit('refreshJourneyitemsV2Page', { type: 'add', data: returnedJourneyItem });
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    CREATE_JOURNEY_ITEM: async (context, payload) => {
      console.log('CREATE_JOURNEY_ITEM payload:', payload);
      if (!payload.content_id) return null;
      const formData = new FormData();
      let time = null;
      let returnedJourneyItem = null;
      if (payload.isJourneyTypeV2) {
        formData.append('as_journey2_id', context.state.journeyId);
        formData.append('as_content_id', payload.content_id);
        formData.append('days', payload.day);
        formData.append('role', payload.roleType?.value);
        time = moment(payload.beginActivationDateTime).format('HH:mm:ss');
        formData.append('activation_time', time);
        const options = {};
        // options.lang = payload.selectedLanguage.name;
        options.notification = {
          title: Vue.prototype.$sanitize(payload.push_notification_title),
          body: Vue.prototype.$sanitize(payload.push_notification_body),
          is_email_enabled: payload.is_email_enabled,
          is_notifications_enabled: payload.is_notifications_enabled, // payload.is_notifications_enabled ? is this the one?
        };
        options.is_info_labels_hidden = payload.is_info_labels_hidden ? 1 : 0;
        options.is_static = payload.is_static ? 1 : 0;
        options.is_reminder_enabled = payload.is_reminder_enabled;
        formData.append('options', JSON.stringify(options));

        await Vue.prototype.$API.post(API_POST_JOURNEY_V2_CONTENT_ADD, formData)
          .then(async (response) => {
            if (response.status === 200) {
              // context.commit('toggleModal', false); // not really need
              // returnedJourneyItem = response.data.content;
              // context.commit('setPageToRefresh', 'journeyitemsv2');
              // context.commit('REFRESH_PAGE', true);
              // console.log('2API_POST_JOURNEY_V2_CONTENT_ADD: id:', response?.data?.id);
              const tempModalData = await context.dispatch('GET_CONTENT_DETAIL', payload.content_id);

              const returnedJourneyItemv2 = {
                id: response?.data?.id,
                as_journey2_id: context.state.journeyId,
                as_content_id: payload.content_id,
                days: payload.day,
                content: tempModalData,
                role: payload.roleType?.value,
                activation_time: time,
                options,
              };
              Vue.prototype.$bus.$root.$emit('refreshJourneyitemsV2Page', { type: 'add', data: returnedJourneyItemv2 });
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
        return null;
      }

      if (context.state.journeyDetail && context.state.journeyDetail.mobile_device_penetration.number_of_total_users > 0) {
        time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
      } else if (payload.isMeetingEntry) {
        time = payload.meetingActivationTime;
      } else if (payload.isWeeklyEntry) {
        time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
      } else if (payload.isFlipbookEditable) {
        time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
      } else {
        time = await context.dispatch('GET_ITEM_LAST_ACTIVATION_TIME');
      }
      if (typeof time === 'object' && time !== null) {
        time = time.format(API_SERVER_TIME_FORMAT);
      }
      if (context.state.journeyId !== null) {
        formData.append('journey_id', context.state.journeyId);
      }

      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      // formData.append('sub_type', payload.contentCreateValue);
      formData.append('date_of_activation', time);
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      const isStatic = payload.is_static ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_static', isStatic);

      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      console.log('CREATE_JOURNEY_ITEM: formData:', formData);

      await Vue.prototype.$API.post(API_JOURNEY_ITEM, formData)
        .then((response) => {
          if (response.status === 200) {
            // context.commit('toggleModal', false); // not really need
            returnedJourneyItem = response.data.content;
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      return returnedJourneyItem;
      // already refreshed in content create funct.
    },

    UPDATE_JOURNEY_ITEM: async (context, payload) => {
      console.log('UPDATE;_JOURNEY_ITEM payload:', payload);
      if (payload.isJourneyTypeV2) {
        const formData = new FormData();
        formData.append('as_journey2_content_id', payload.as_journey2_content_id);
        formData.append('days', payload.day);
        formData.append('role', payload.roleType?.value || 0);
        const time = moment(payload.beginActivationDateTime).format('HH:mm:ss');
        formData.append('activation_time', time);
        const options = {};
        // options.lang = payload.selectedLanguage.name;
        options.notification = {
          title: Vue.prototype.$sanitize(payload.push_notification_title),
          body: Vue.prototype.$sanitize(payload.push_notification_body),
          is_email_enabled: payload.is_email_enabled,
          is_notifications_enabled: payload.is_notifications_enabled,
        };

        options.is_info_labels_hidden = payload.is_info_labels_hidden ? 1 : 0;
        options.is_static = payload.is_static ? 1 : 0;
        options.is_reminder_enabled = payload.is_reminder_enabled;
        formData.append('options', JSON.stringify(options));

        await Vue.prototype.$API.post(API_POST_JOURNEY_V2_CONTENT_UPDATE, formData)
          .then((response) => {
            if (response.status === 200) {
              // context.commit('toggleModal', false); // not really need
              // returnedJourneyItem = response.data.content;
              context.commit('setPageToRefresh', 'journeyitemsv2');
              context.commit('REFRESH_PAGE', true);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      } else {
        const time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
        const formData = new URLSearchParams();
        formData.append('content_id', payload.content_id);
        formData.append('journey_id', context.state.journeyId);
        formData.append('journey_item_id', context.state.journeyItemId);
        formData.append('name', Vue.prototype.$sanitize(payload.name));
        if (payload.desc) {
          formData.append('desc', Vue.prototype.$sanitize(payload.desc));
        } else {
          formData.append('desc', ' ');
        }
        if ((context.state.journeyDetail && context.state.journeyDetail.mobile_device_penetration.number_of_total_users > 0) || context.rootState.auth.currentViewMode === 'weekly' || context.rootState.auth.currentViewMode === 'daily') {
          formData.append('date_of_activation', time);
        } else {
          const oldTime = moment(context.state.journeyItemActivationDate, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
          formData.append('date_of_activation', oldTime);
        }
        formData.append('is_info_labels_hidden', payload.is_info_labels_hidden);
        formData.append('is_static', payload.is_static);
        formData.append('is_notifications_enabled', payload.is_notifications_enabled);
        formData.append('is_email_enabled', payload.is_email_enabled);
        formData.append('is_reminder_enabled', payload.is_reminder_enabled);
        if (payload.push_notification_title) {
          formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
        }
        if (payload.push_notification_body) {
          formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name))));
        }

        const isPublic = payload.is_public ? 1 : 0;
        formData.append('is_public', isPublic);
        if (payload.showHideFromLxp) {
          formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
        }
        await Vue.prototype.$API.put(API_JOURNEY_ITEM, formData)
          .then((response) => {
            if (response.status === 200) {
              if (isPublic || (!context.rootState.auth.isVibonsAdmin && !isPublic)) { // otherwise the modal would be closed after the content update process
                context.commit('toggleModal', false);
              }
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
        if (context.rootState.auth.currentViewMode === 'weekly' || context.rootState.auth.currentViewMode === 'daily') {
          context.commit('setPageToRefresh', 'journeyitems');
        }
      // already refreshed in content update funct.
      }
    },

    ADD_CONTENT_ITEM_TO_JOURNEY: async (context, payload) => {
      console.log('add item payload:', payload);
      const formData = new FormData();
      let time = null;
      let now = null;
      let infoLabelHidden;
      if (payload.isDropFromLibrary) {
        // weekly or daily de iken kendi aralarında drag drop sonrası oluşan tarih set ediliyor . burda tarih su mantıkla olusturuluyordu. diyelim iki iteimin arasına gelicek sekilde set edilecek .. o iki itemin tarihleri arasının tam ortasına denk gelen tarih hesaplanıp veriliyordu.
        // * bu fonsiyon, kendi aralarında drag etme, artık kaldırıldı kullanılmıyor, yeni duzende olucaksa bu durumda düşünülmeli
        time = payload.activation_date;
        console.log('ADD_CONTENT_ITEM_TO_JOURNEY step 1');
      } else if (context.state.journeyDetail && context.state.journeyDetail.mobile_device_penetration.number_of_total_users > 0) {
        // Journeyde aktif kullanıcı varsa ozaman tarih modali çıkıp hangi gun sonra önceye set edileceği soruluyor
        time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
        console.log('ADD_CONTENT_ITEM_TO_JOURNEY step 2');
      } else if (context.rootState.auth.currentViewMode === 'weekly' || context.rootState.auth.currentViewMode === 'daily') {
        // Journeyde aktif kullanıcı yoksa ve modda daily veya weekly ise o zaman çıkan modal da istenilen tarih set ediliyor
        time = payload.beginActivationDateTime;
        console.log('ADD_CONTENT_ITEM_TO_JOURNEY step 3');
      } else {
        // Journeyde aktif kullanıcı yoksa ve catalog default list modda ise ozaman  mevcut journeyde bulunan en son tarihdeki itemin tarihine 6 dk eklenerek yeni tarih oluşturuluyor. hernagi bir item yoksa ozaman journeyin activation date ine gore 6 dk sonraya hesaplanıyor.
        now = moment();
        console.log('ADD_CONTENT_ITEM_TO_JOURNEY step 4');
        let lastItemActivationTime = null;
        if (context.state.lastItemActivationTime) {
          // eslint-disable-next-line prefer-destructuring
          lastItemActivationTime = moment(context.state.lastItemActivationTime, API_SERVER_TIME_FORMAT);
        }
        if (lastItemActivationTime) {
          const timeDiff = lastItemActivationTime.diff(now, 'minutes');
          if (timeDiff > 6) {
            time = now;
          } else {
            time = lastItemActivationTime.add(6, 'minutes');
          }
        } else {
          time = now;
        }
        if (typeof time === 'object' && time !== null) {
          time = time.format(API_SERVER_TIME_FORMAT);
        }
      }
      console.log('given activation date:', time);
      if (payload.sub_type === 'QUOTES' || payload.sub_type === 'BOOK_SUGGESTION') {
        infoLabelHidden = 1;
      } else {
        infoLabelHidden = payload.is_info_labels_hidden;
      }

      let isStatic;
      if (payload.sub_type === 'QUOTES') {
        isStatic = 1;
      } else {
        isStatic = 0;
      }

      formData.append('content_id', payload.options);
      formData.append('journey_id', context.state.journeyId || payload.journeyId);
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('date_of_activation', time);
      formData.append('is_info_labels_hidden', infoLabelHidden);
      formData.append('is_static', isStatic);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', payload.push_notification_title);
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', payload.push_notification_body.replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }

      let returnedJourneyItem = null;
      await Vue.prototype.$API.post(API_JOURNEY_ITEM, formData)
        .then((response) => {
          if (response.status === 200) {
            // context.commit('setPageToRefresh', 'journeyitems');
            returnedJourneyItem = response.data.content;
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      // if (payload.canIRefresh) {
      //   context.commit('REFRESH_PAGE', true);
      // }
      return returnedJourneyItem;
    },

    CREATE_VIDEO_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('seekbar_enabled', payload.seekbar_enabled || 0);
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 3);
      let VIDEOAPIEND = API_CONTENT_VIDEO;
      if (payload.file && payload.videoType === 'videoFile') {
        const thefilef = await sanitizeSVG(payload.file);
        formData.append('file', thefilef);
      }
      if (payload.videoType === 'videoUrl') {
        VIDEOAPIEND = API_CONTENT_VIDEO_CDN;
        formData.append('file_url', payload.videoPreview);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(VIDEOAPIEND,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          if (response.status === 200) {
            const contentId = response.data.id;
            payloadHolder.content_id = contentId;
            // eslint-disable-next-line prefer-destructuring
            payloadHolder.temp_sub_type = subtypeList[3];

            if (payloadHolder.isAddingQuestion) {
              payloadHolder.parent_content_id = contentId;
              payloadHolder.quizType = 'content';
              payloadHolder.is_retake_enabled = 0;
              payloadHolder.is_randomize_questions_enabled = 0;
              payloadHolder.is_randomize_choices_enabled = 0;
              payloadHolder.is_show_correct_answers_enabled = 1;
              payloadHolder.is_show_score = 1;
              await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
            }
            context.commit('SET_MODAL_DATA', payloadHolder);
            if (context.state.journeyId) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_PODCAST_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 37);
      if (payload.file) {
        const thefilef = await sanitizeSVG(payload.file);
        formData.append('file', thefilef);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_VIDEO,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          if (response.status === 200) {
            const contentId = response.data.id;
            payloadHolder.content_id = contentId;
            // eslint-disable-next-line prefer-destructuring
            payloadHolder.temp_sub_type = subtypeList[37];

            if (payloadHolder.isAddingQuestion) {
              payloadHolder.parent_content_id = contentId;
              payloadHolder.quizType = 'content';
              await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
            }
            context.commit('SET_MODAL_DATA', payloadHolder);
            if (context.state.journeyId) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_FLIPBOOK_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }
      if (payload.isEditable) {
        formData.append('name', Vue.prototype.$sanitize(context.state.flipsData[0].datam.bodyText.trim()));
        payloadHolder.name = Vue.prototype.$sanitize(context.state.flipsData[0].datam.bodyText.trim());
      } else {
        formData.append('name', Vue.prototype.$sanitize(payload.name));
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 9);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('flipboard_items', payload.flipbookPreviewIds);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_FLIPBOARD,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[9];

          if (payload.isEditable) {
            await context.dispatch('CREATEUPDATE_FLIPBOOK_EDITABLE', payloadHolder);
            if (payloadHolder.isAddingQuestion) {
              payloadHolder.parent_content_id = contentId;
              payloadHolder.quizType = 'content';
              payloadHolder.is_retake_enabled = 0;
              payloadHolder.is_randomize_questions_enabled = 0;
              payloadHolder.is_randomize_choices_enabled = 0;
              payloadHolder.is_show_correct_answers_enabled = 1;
              payloadHolder.is_show_score = 1;
              await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
            }
          } else {
            if (payloadHolder.isAddingQuestion) {
              payloadHolder.parent_content_id = contentId;
              payloadHolder.quizType = 'content';
              payloadHolder.is_retake_enabled = 0;
              payloadHolder.is_randomize_questions_enabled = 0;
              payloadHolder.is_randomize_choices_enabled = 0;
              payloadHolder.is_show_correct_answers_enabled = 1;
              payloadHolder.is_show_score = 1;
              await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
            }
            context.commit('SET_MODAL_DATA', payloadHolder);
            if (context.state.journeyId) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATEUPDATE_FLIPBOOK_EDITABLE: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      if (payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }
      formData.append('content_id', payload.content_id);
      if (context.state.flipsData) {
        const flips = context.state.flipsData;
        await flips.forEach((flipim, index) => {
          if (index === 0) {
            formData.append(`title[${index}]`, Vue.prototype.$sanitize(flipim.datam.bodyText.trim()));
            formData.append(`text[${index}]`, '');
          } else {
            formData.append(`title[${index}]`, '');
            formData.append(`text[${index}]`, Vue.prototype.$sanitize(flipim.datam.bodyText));
          }

          formData.append(`images[${index}][0]`, flipim.datam.imageId);
          formData.append(`type[${index}]`, flipim.type);
          formData.append(`background_top[${index}]`, '272F70');
          formData.append(`background_bottom[${index}]`, '1273BA');

          if (flip.type === 'full_image') {
            formData.append(`images_type[${index}][0]`, 'full_image');
          } else {
            formData.append(`images_type[${index}][0]`, 'image_top');
          }
        });
      }

      await Vue.prototype.$API.post(API_FLIPBOOK_EDITABLE,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId && !payloadHolder.isUpdating) {
            console.log('CREATEUPDATE_FLIPBOOK_EDITABLE CREATING JOURNEY ITEM', response.data.id);

            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          } else if (context.state.journeyId && payloadHolder.isUpdating && !payloadHolder.isAddingQuestion) {
            await context.dispatch('UPDATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_SCORM_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.indexFilePath) {
        formData.append('index_file_path', Vue.prototype.$sanitize(payload.indexFilePath));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 47);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('package', payload.package_zip);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_SCORM,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[47];

          // if (payloadHolder.isAddingQuestion) {
          //   payloadHolder.parent_content_id = contentId;
          //   payloadHolder.quizType = 'content';
          //   payloadHolder.is_retake_enabled = 0;
          //   payloadHolder.is_randomize_questions_enabled = 0;
          //   payloadHolder.is_randomize_choices_enabled = 0;
          //   payloadHolder.is_show_correct_answers_enabled = 1;
          //   await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
          // }
          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId) {
            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_INFOGRAPHICS_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 1);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.thumbnail) {
        const thefilef = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefilef);
      }
      if (payload.file) {
        const thefile = await sanitizeSVG(payload.file);
        formData.append('file', thefile);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_INFOGRAPHICS,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[1];

          if (payloadHolder.isAddingQuestion) {
            payloadHolder.parent_content_id = contentId;
            payloadHolder.quizType = 'content';
            payloadHolder.is_retake_enabled = 0;
            payloadHolder.is_randomize_questions_enabled = 0;
            payloadHolder.is_randomize_choices_enabled = 0;
            payloadHolder.is_show_correct_answers_enabled = 1;
            payloadHolder.is_show_score = 1;
            await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
          }
          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId) {
            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_CHAPTER_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('journey_id', context.state.journeyId);
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      }
      formData.append('chapter_index', Vue.prototype.$sanitize(payload.chapterIndex));
      await Vue.prototype.$API.post(API_CONTENT_CHAPTER,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          console.log('response:', response);
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_CHAPTER_CONTENT: async (context, payload) => {
      const jpayload = {
        chapter_id: payload.chapterId,
        name: Vue.prototype.$sanitize(payload.name),
        desc: Vue.prototype.$sanitize(payload.desc),
        chapter_index: Vue.prototype.$sanitize(payload.chapterIndex),
      };

      await Vue.prototype.$API.put(API_CONTENT_CHAPTER,
        jpayload,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          console.log('response:', response);
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
    },

    DELETE_CHAPTER_CONTENT: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_CONTENT_CHAPTER}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },
    DELETE_CONTENT_SUB_QUIZ: async (context, payload) => new Promise((resolve, reject) => {
      Vue.prototype.$API.delete(`${API_CONTENT_QUIZ_DELETE}${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
            resolve();
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
          reject();
        });
    }),

    GET_CONTENT_DETAIL: async (_context, payload) => new Promise((resolve, reject) => {
      Vue.prototype.$API.get(`${API_GET_CONTENT_INFO}/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }),

    CREATE_ARTICLE_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 5);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      if (payload.file) {
        const thefilef = await sanitizeSVG(payload.file);
        formData.append('file', thefilef);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_ARTICLE,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[5];

          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId) {
            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_EXTERNAL_LINK_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      let returnedJourneyItem = null;
      const formData = new FormData();
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 2);
      formData.append('tags', payload.tagsTemp.map(m => m.text).join(','));
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('url', payload.externalUrl);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_EXTERNAL_LINK,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[36];

          if (payloadHolder.isAddingQuestion) {
            payloadHolder.parent_content_id = contentId;
            payloadHolder.quizType = 'content';
            payloadHolder.is_retake_enabled = 0;
            payloadHolder.is_randomize_questions_enabled = 0;
            payloadHolder.is_randomize_choices_enabled = 0;
            payloadHolder.is_show_correct_answers_enabled = 1;
            payloadHolder.is_show_score = 1;
            await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
          }
          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId) {
            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_PDFUPLOAD_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      let returnedJourneyItem = null;
      const formData = new FormData();
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 2);
      formData.append('tags', payload.tagsTemp.map(m => m.text).join(','));
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('file', payload.pdfFile);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_EXTERNAL_PDF_LINK,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[36];

          if (payloadHolder.isAddingQuestion) {
            payloadHolder.parent_content_id = contentId;
            payloadHolder.quizType = 'content';
            payloadHolder.is_retake_enabled = 0;
            payloadHolder.is_randomize_questions_enabled = 0;
            payloadHolder.is_randomize_choices_enabled = 0;
            payloadHolder.is_show_correct_answers_enabled = 1;
            payloadHolder.is_show_score = 1;
            await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
          }
          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId) {
            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_EXTERNAL_YOUTBE_LINK_CONTENT: async (context, payload) => {
      const formData = new FormData();
      console.log('api request started');
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 2);
      formData.append('tags', payload.tags.join(','));
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('url', payload.externalUrl);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_EXTERNAL_LINK,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async () => {
          console.log('api request returned');
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      console.log('api request finished');
    },

    CREATE_QUIZ_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.isAddingQuestion) {
        formData.append('parent_content_id', payload.parent_content_id);
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      if (payload.quizType) {
        formData.append('quiz_type', payload.quizType);
      }
      formData.append('repetitive_max_question_per_day', payload.repetitiveMaxQuestionPerDay);
      formData.append('repetitive_correct_answer_count', payload.repetitiveCorrectAnswerCount);
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 10);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_show_correct_answers_enabled', payload.is_show_correct_answers_enabled);
      formData.append('is_show_score', payload.is_show_score);
      formData.append('is_randomize_questions_enabled', payload.is_randomize_questions_enabled);
      formData.append('is_randomize_choices_enabled', payload.is_randomize_choices_enabled);
      formData.append('max_questions_count', payload.max_questions_count);
      formData.append('is_retake_enabled', payload.is_retake_enabled);

      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      context.commit('setMultiProcessProgressBarValue', true);

      await Vue.prototype.$API.post(API_CONTENT_QUIZ, formData)
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[10];

          context.commit('SET_MODAL_DATA', payloadHolder);
          await context.dispatch('CREATE_QUIZ_QUESTION', payloadHolder).then(async () => {
            if (context.state.journeyId && !payload.isAddingQuestion) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          });
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('toggleModal', false);
      context.commit('setMultiProcessProgressBarValue', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_QUIZ_QUESTION: async (context, payload) => {
      const questionData = payload.questions;
      // init your manager.
      const manager = ConcurrencyManager(Vue.prototype.$API, MAX_CONCURRENT_REQUESTS);

      if (payload.content_id) { // eslint-disable-next-line
        await Promise.all(questionData.map(async (i) => {
          const newPayload = {};
          const questionForm = new FormData();
          questionForm.append('content_id', payload.content_id);
          questionForm.append('question', Vue.prototype.$sanitize(i.question));
          questionForm.append('type', i.type);
          newPayload.qtype = i.type;
          newPayload.choices = i.choices;
          await Vue.prototype.$API.post(API_CONTENT_QUIZ_QUESTION, questionForm)
            .then(async (response) => {
              if (response.status === 200) {
                const questionId = response.data.id;
                newPayload.questionId = questionId;
                await context.dispatch('CREATE_QUIZ_ANSWER', newPayload);
              }
            })
            .catch((error) => {
              context.commit('ERROR_MODAL_DATA', true);
              context.dispatch('DISPLAY_ERROR', error);
            });
        })).then(() => {
          manager.detach();
        });
      }
    },

    CREATE_QUIZ_ANSWER: async (context, payload) => {
      if (payload.questionId) {
        const { questionId } = payload;
        const valueData = payload.choices;
        const type = payload.qtype;
        // init your manager.
        const manager = ConcurrencyManager(Vue.prototype.$API, MAX_CONCURRENT_REQUESTS);

        if (type === 'standard') {
          // eslint-disable-next-line
          await Promise.all(valueData.map(async (answerValue) => {
            let choiceText = null;
            let choiceImage = null;

            if (answerValue.choice_text) {
              choiceText = Vue.prototype.$sanitize(answerValue.choice_text);
            }
            if (answerValue.image) {
              choiceImage = answerValue.image;
            }
            const isCorrect = answerValue.is_correct; // eslint-disable-line no-bitwise

            if (choiceText || choiceImage) {
              const answerForm = new FormData();
              answerForm.append('question_id', questionId);
              if (choiceText) {
                answerForm.append('choice_text', choiceText);
              }
              if (choiceImage) {
                answerForm.append('image', choiceImage);
              }

              answerForm.append('is_correct', isCorrect);

              await Vue.prototype.$API.post(API_CONTENT_QUIZ_QUESTION_CHOICE, answerForm)
                .then((response) => {
                  if (response.status === 200) {
                    console.log('Ok.. good to go.. no need any action!');
                  }
                })
                .catch((error) => {
                  context.commit('ERROR_MODAL_DATA', true);
                  context.dispatch('DISPLAY_ERROR', error);
                });
            }
          })).then(() => {
            manager.detach();
          });
        } else if (type === 'pairs') {
          // split choices into pairs
          const pairValueData = valueData.reduce((result, _value, index, array) => {
            if (index % 2 === 0) {
              result.push(array.slice(index, index + 2));
            }
            return result;
          }, []);

          // eslint-disable-next-line
          await Promise.all(pairValueData.map(async (answerValue) => {

            const choiceTextFirst = Vue.prototype.$sanitize(answerValue[0].choice_text);
            const isCorrectFirst = answerValue[0].is_correct;
            const choiceTextSecond = Vue.prototype.$sanitize(answerValue[1].choice_text);
            const isCorrectSecond = answerValue[1].is_correct;

            if (choiceTextFirst && choiceTextSecond) {
              const answerForm = new FormData();
              answerForm.append('question_id', questionId);
              answerForm.append('choice_text', choiceTextFirst);
              answerForm.append('is_correct', isCorrectFirst);

              await Vue.prototype.$API.post(API_CONTENT_QUIZ_QUESTION_CHOICE, answerForm)
                .then(async (response) => {
                  if (response.status === 200) {
                    const pairId = response.data.id;
                    const pairAnswerForm = new FormData();

                    pairAnswerForm.append('question_id', questionId);
                    pairAnswerForm.append('choice_text', choiceTextSecond);
                    pairAnswerForm.append('is_correct', isCorrectSecond);
                    pairAnswerForm.append('choice_pair_id', pairId);

                    await Vue.prototype.$API.post(API_CONTENT_QUIZ_QUESTION_CHOICE, pairAnswerForm)
                      .then((subresponse) => {
                        if (subresponse.status === 200) {
                          console.log('Ok.. good to go.. no need any action!');
                        }
                      })
                      .catch((error) => {
                        context.commit('ERROR_MODAL_DATA', true);
                        context.dispatch('DISPLAY_ERROR', error);
                      });
                  }
                })
                .catch((error) => {
                  context.commit('ERROR_MODAL_DATA', true);
                  context.dispatch('DISPLAY_ERROR', error);
                });
            }
          })).then(() => {
            manager.detach();
          });
        }
      }
    },

    UPDATE_QUIZ_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      formData.append('info', Vue.prototype.$sanitize(payload.info));
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('quiz_type', payload.quizType);

      if (payload.parent_content_id) {
        formData.append('parent_content_id', payload.parent_content_id);
      }
      formData.append('repetitive_max_question_per_day', payload.repetitiveMaxQuestionPerDay);
      formData.append('repetitive_correct_answer_count', payload.repetitiveCorrectAnswerCount);
      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      formData.append('subtype_id', 10);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_show_correct_answers_enabled', payload.is_show_correct_answers_enabled);
      formData.append('is_show_score', payload.is_show_score);
      formData.append('is_randomize_questions_enabled', payload.is_randomize_questions_enabled);
      formData.append('is_randomize_choices_enabled', payload.is_randomize_choices_enabled);
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('max_questions_count', payload.max_questions_count);
      formData.append('is_retake_enabled', payload.is_retake_enabled);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      const isPublic = payload.is_public ? 1 : 0;
      context.commit('setMultiProcessProgressBarValue', true);

      if (context.state.journeyId && context.state.journeyItemId && !payload.isAddingQuestion) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }
      if (!isPublic || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_QUIZ, formData)
          .then(async (response) => {
            if (response.status === 200 && payload.dirtyList && payload.dirtyList.length) {
              await context.dispatch('UPDATE_QUIZ_QUESTION', payload);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setMultiProcessProgressBarValue', false);
      context.commit('toggleModal', false);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_QUIZ_QUESTION: async (context, payload) => {
      const questionData = payload.questions;

      await Promise.all(questionData.map(async (i) => {
        if (i.id) { // check if exist
          const newPayload = {};
          const questionForm = new URLSearchParams();
          questionForm.append('question_id', i.id);
          questionForm.append('type', i.type);
          questionForm.append('question', Vue.prototype.$sanitize(i.question));
          newPayload.qtype = i.type;
          newPayload.choices = i.choices;
          newPayload.question_id = i.id;

          await Vue.prototype.$API.put(API_CONTENT_QUIZ_QUESTION, questionForm)
            .then(async (response) => {
              if (response.status === 200) {
                await context.dispatch('UPDATE_QUIZ_ANSWER', newPayload);
              }
            })
            .catch((error) => {
              context.commit('ERROR_MODAL_DATA', true);
              context.dispatch('DISPLAY_ERROR', error);
            });
        } else {
          // then create new question
          const newPayload = {
            content_id: payload.content_id,
            questions: [{ question: Vue.prototype.$sanitize(i.question), type: i.type, choices: i.choices }],
          };
          await context.dispatch('CREATE_QUIZ_QUESTION', newPayload);
        }
      })).then(() => {
        console.log('UPDATE_QUIZ_QUESTION finished.');
      });
    },

    UPDATE_QUIZ_ANSWER: async (context, payload) => {
      const valueData = payload.choices;
      const type = payload.qtype;
      if (type === 'standard') {
        await Promise.all(valueData.map(async (answerValue) => {
          let choiceText = null;
          let choiceImage = null;

          if (answerValue.choice_text) {
            choiceText = Vue.prototype.$sanitize(answerValue.choice_text);
          }
          if (answerValue.image) {
            choiceImage = answerValue.image;
          }

          const choiceId = answerValue.id;
          const isCorrect = answerValue.is_correct;

          if (choiceId) { // update if exist
            const answerForm = new URLSearchParams();
            answerForm.append('choice_id', choiceId);
            if (choiceText && choiceText !== '-') {
              answerForm.append('choice_text', choiceText);
            }
            if (choiceImage) {
              answerForm.append('image', choiceImage);
            }
            answerForm.append('is_correct', isCorrect);

            await Vue.prototype.$API.put(API_CONTENT_QUIZ_QUESTION_CHOICE, answerForm)
              .then((response) => {
                if (response.status === 200) {
                  console.log('Ok ..!');
                }
              })
              .catch((error) => {
                context.commit('ERROR_MODAL_DATA', true);
                context.dispatch('DISPLAY_ERROR', error);
              });
          } else {
            let tempChoices = [{ choice_text: choiceText, is_correct: isCorrect }];
            if (choiceImage) {
              tempChoices = [{ choice_text: choiceText, is_correct: isCorrect, image: choiceImage }];
            }
            // then create new answer
            const newPayload = {
              questionId: payload.question_id,
              qtype: type,
              choices: tempChoices,
            };

            await context.dispatch('CREATE_QUIZ_ANSWER', newPayload);
          }
        })).then(() => {
          console.log('UPDATE_QUIZ_ANSWER finished');
        });
      } else if (type === 'pairs') {
        // split choices into pairs
        const pairValueData = valueData.reduce((result, _value, index, array) => {
          if (index % 2 === 0) {
            result.push(array.slice(index, index + 2));
          }
          return result;
        }, []);

        await Promise.all(pairValueData.map(async (answerValue) => {
          const firstChoiceId = answerValue[0].id;
          const choiceTextFirst = Vue.prototype.$sanitize(answerValue[0].choice_text);
          const isCorrectFirst = answerValue[0].is_correct;
          const firstPairId = answerValue[0].choice_pair_id;

          const secondChoiceId = answerValue[1].id;
          const choiceTextSecond = Vue.prototype.$sanitize(answerValue[1].choice_text);
          const isCorrectSecond = answerValue[1].is_correct;
          const secondPairId = answerValue[1].choice_pair_id;

          if (firstChoiceId && secondChoiceId) { // update if exist
            const answerForm = new URLSearchParams();
            answerForm.append('choice_id', firstChoiceId);
            answerForm.append('choice_text', choiceTextFirst);
            answerForm.append('is_correct', isCorrectFirst);
            answerForm.append('choice_pair_id', firstPairId);

            await Vue.prototype.$API.put(API_CONTENT_QUIZ_QUESTION_CHOICE, answerForm)
              .then(async (response) => {
                if (response.status === 200) {
                  const answerForm2 = new URLSearchParams();
                  answerForm2.append('choice_id', secondChoiceId);
                  answerForm2.append('choice_text', choiceTextSecond);
                  answerForm2.append('is_correct', isCorrectSecond);
                  answerForm2.append('choice_pair_id', secondPairId);

                  await Vue.prototype.$API.put(API_CONTENT_QUIZ_QUESTION_CHOICE, answerForm2)
                    .then((subresponse) => {
                      if (subresponse.status === 200) {
                        console.log('Ok ..!');
                      }
                    })
                    .catch((error) => {
                      context.commit('ERROR_MODAL_DATA', true);
                      context.dispatch('DISPLAY_ERROR', error);
                    });
                }
              })
              .catch((error) => {
                context.commit('ERROR_MODAL_DATA', true);
                context.dispatch('DISPLAY_ERROR', error);
              });
          } else {
            // then create new pair answer
            const newPayload = {
              questionId: payload.question_id,
              qtype: type,
              choices: [
                { choice_text: choiceTextFirst, is_correct: isCorrectFirst, choice_pair_id: firstPairId },
                { choice_text: choiceTextSecond, is_correct: isCorrectSecond, choice_pair_id: secondPairId },
              ],
            };

            await context.dispatch('CREATE_QUIZ_ANSWER', newPayload);
          }
        })).then(() => {
          console.log('UPDATE_QUIZ_ANSWER finished');
        });
      }
    },

    DELETE_CONTENT_QUIZ: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_DELETE_CONTENT_QUIZ}?quiz_id=${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    DELETE_QUIZ_QUESTION: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_CONTENT_QUIZ_QUESTION}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    DELETE_QUIZ_ANSWER: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_CONTENT_QUIZ_QUESTION_CHOICE}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    UPDATE_VIDEO_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      formData.append('seekbar_enabled', payload.seekbar_enabled || 0);
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('subtype_id', 3);
      let VIDEOAPIEND = API_UPDATE_CONTENT_VIDEO;
      if (payload.file && payload.videoType === 'videoFile') {
        const thefilef = await sanitizeSVG(payload.file);
        formData.append('file', thefilef);
      }
      if (payload.videoType === 'videoUrl') {
        VIDEOAPIEND = API_UPDATE_CONTENT_VIDEO_CDN;
        formData.append('file_url', payload.videoPreview);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
      } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.parent_content_id = payload.content_id;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
      }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(VIDEOAPIEND,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_PODCAST_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('subtype_id', 37);
      if (payload.file) {
        const thefilef = await sanitizeSVG(payload.file);
        formData.append('file', thefilef);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
      } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.parent_content_id = payload.content_id;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
      }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_VIDEO,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_ARTICLE_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('subtype_id', 5);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.file) {
        const thefilef = await sanitizeSVG(payload.file);
        formData.append('file', thefilef);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_ARTICLE,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_EXTERNAL_LINK_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.sub_type === 'LINK') {
        formData.append('subtype_id', 36);
      } else {
        formData.append('subtype_id', payload.subtype_id);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('url', payload.externalUrl);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
      } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.parent_content_id = payload.content_id;
        payloadHolder.quizType = 'content';
        await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
      }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_EXTERNAL_LINK,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_PDFUPLOAD_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.sub_type === 'LINK') {
        formData.append('subtype_id', 36);
      } else {
        formData.append('subtype_id', payload.subtype_id);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      if (payload.pdfFile) {
        formData.append('file', payload.pdfFile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
      } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.parent_content_id = payload.content_id;
        payloadHolder.quizType = 'content';
        await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
      }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_EXTERNAL_PDF_LINK,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_SCORM_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.indexFilePath) {
        formData.append('index_file_path', Vue.prototype.$sanitize(payload.indexFilePath));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', payload.subtype_id);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.package_zip) {
        formData.append('package', payload.package_zip);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      // if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
      //   const payloadHolder = payload;
      //   payloadHolder.quizType = 'content';
      //   payloadHolder.is_retake_enabled = 0;
      //   payloadHolder.is_randomize_questions_enabled = 0;
      //   payloadHolder.is_randomize_choices_enabled = 0;
      //   payloadHolder.is_show_correct_answers_enabled = 1;
      //   await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
      // } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
      //   const payloadHolder = payload;
      //   payloadHolder.parent_content_id = payload.content_id;
      //   payloadHolder.quizType = 'content';
      //   payloadHolder.is_retake_enabled = 0;
      //   payloadHolder.is_randomize_questions_enabled = 0;
      //   payloadHolder.is_randomize_choices_enabled = 0;
      //   payloadHolder.is_show_correct_answers_enabled = 1;
      //   await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
      // }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(`${API_CONTENT_SCORM}/update`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_INFOGRAPHICS_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', payload.subtype_id);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.file) {
        const thefilef = await sanitizeSVG(payload.file);
        formData.append('file', thefilef);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
      } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
        const payloadHolder = payload;
        payloadHolder.parent_content_id = payload.content_id;
        payloadHolder.quizType = 'content';
        payloadHolder.is_retake_enabled = 0;
        payloadHolder.is_randomize_questions_enabled = 0;
        payloadHolder.is_randomize_choices_enabled = 0;
        payloadHolder.is_show_correct_answers_enabled = 1;
        payloadHolder.is_show_score = 1;
        await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
      }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_INFOGRAPHICS,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_FLIPBOOK_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      if (payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.isEditable) {
        formData.append('name', Vue.prototype.$sanitize(context.state.flipsData[0].datam.bodyText.trim()));
        payloadHolder.name = Vue.prototype.$sanitize(context.state.flipsData[0].datam.bodyText.trim());
      } else {
        formData.append('name', Vue.prototype.$sanitize(payload.name));
      }
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('subtype_id', 9);
      if (payload.flipbookPreviewIds) {
        formData.append('flipboard_items', payload.flipbookPreviewIds);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      if (payload.is_notifications_enabled) {
        formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      } else {
        formData.append('is_notifications_enabled', 0); // need for old flip updates
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      if (payload.is_email_enabled) {
        formData.append('is_email_enabled', payload.is_email_enabled);
      } else {
        formData.append('is_email_enabled', 0); // need for old flip updates
      }
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      if (payload.is_reminder_enabled) {
        formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      } else {
        formData.append('is_reminder_enabled', 0); // need for old flip updates
      }

      if (payload.isEditable) {
        await context.dispatch('CREATEUPDATE_FLIPBOOK_EDITABLE', payloadHolder);
        if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
          payloadHolder.quizType = 'content';
          payloadHolder.is_retake_enabled = 0;
          payloadHolder.is_randomize_questions_enabled = 0;
          payloadHolder.is_randomize_choices_enabled = 0;
          payloadHolder.is_show_correct_answers_enabled = 1;
          payloadHolder.is_show_score = 1;
          await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
        } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
          payloadHolder.parent_content_id = payload.content_id;
          payloadHolder.quizType = 'content';
          payloadHolder.is_retake_enabled = 0;
          payloadHolder.is_randomize_questions_enabled = 0;
          payloadHolder.is_randomize_choices_enabled = 0;
          payloadHolder.is_show_correct_answers_enabled = 1;
          payloadHolder.is_show_score = 1;
          await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
        }
      } else if (!payload.isEditable && context.state.journeyId && context.state.journeyItemId) {
        if (payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
          payloadHolder.quizType = 'content';
          payloadHolder.is_retake_enabled = 0;
          payloadHolder.is_randomize_questions_enabled = 0;
          payloadHolder.is_randomize_choices_enabled = 0;
          payloadHolder.is_show_correct_answers_enabled = 1;
          payloadHolder.is_show_score = 1;
          await context.dispatch('UPDATE_QUIZ_CONTENT', payloadHolder);
        } else if (!payload.hasPreDefinedSubQuiz && payload.isAddingQuestion && payload.questions && payload.questions.length > 0) {
          payloadHolder.parent_content_id = payload.content_id;
          payloadHolder.quizType = 'content';
          await context.dispatch('CREATE_QUIZ_CONTENT', payloadHolder);
        }
        await context.dispatch('UPDATE_JOURNEY_ITEM', payloadHolder);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_FLIPBOARD,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              if (!payload.isAfterDeleteItem) {
                context.commit('toggleModal', false);
              }
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      if (!payload.isAfterDeleteItem) {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_USER: async (context, payload) => {
      const formData = new FormData();
      formData.append('first_name', Vue.prototype.$sanitize(payload.userName));
      formData.append('last_name', Vue.prototype.$sanitize(payload.userSurname));
      formData.append('email', Vue.prototype.$sanitize(payload.userEmail));
      formData.append('division', Vue.prototype.$sanitize(payload.division));
      formData.append('unit', Vue.prototype.$sanitize(payload.unit) || '');
      formData.append('title', Vue.prototype.$sanitize(payload.title) || '');
      formData.append('phone_number', Vue.prototype.$sanitize(payload.phone_number) || '');
      formData.append('team', Vue.prototype.$sanitize(payload.team));
      formData.append('personnel_group', Vue.prototype.$sanitize(payload.personnel_group));
      formData.append('company_name', Vue.prototype.$sanitize(payload.company_name));
      formData.append('business_name', Vue.prototype.$sanitize(payload.business_name));
      formData.append('business_group', Vue.prototype.$sanitize(payload.business_group));
      formData.append('position', Vue.prototype.$sanitize(payload.position));
      formData.append('operation', Vue.prototype.$sanitize(payload.operation));
      formData.append('work_station', Vue.prototype.$sanitize(payload.work_station));
      formData.append('personal_email', Vue.prototype.$sanitize(payload.personal_email));
      formData.append('employment_start_date', Vue.prototype.$sanitize(payload.employment_start_date));
      if (payload.selectCustomer) {
        formData.append('customer_id', payload.selectCustomer.id);
      }
      if (payload.managerSelected) {
        formData.append('manager_id', payload.managerSelected.id);
      }
      if (payload.relatedUsersSelected && payload.relatedUserIsDirty) {
        formData.append('relationships', JSON.stringify(payload.relatedUsersSelected));
      }
      formData.append('timezone', payload.selectedTimezone);
      if (payload.sendWelcomeEmail === 1) {
        formData.append('is_welcome_email_enabled', 1);
      } else {
        formData.append('is_welcome_email_enabled', 0);
      }
      formData.append('is_active', payload.activeUser);
      formData.append('role', payload.selectRoles.name);
      formData.append('lang', payload.selectLanguages.name);
      if (payload.userEmployeeNo) {
        formData.append('employee_no', payload.userEmployeeNo);
      }

      await Vue.prototype.$API.post(API_USER, formData)
        .then((response) => {
          if (response.status === 201) {
            context.commit('SET_MODAL_DATA', response.data);
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    GET_USER: async (context) => {
      const ctx = context;
      ctx.modalData = null;
      await Vue.prototype.$API.get(`${API_USER}/${context.state.userId}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
            setTimeout(() => {
              ctx.state.userId = null;
            }, 100);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    UPDATE_USER: async (context, payload) => {
      const params = new URLSearchParams();
      if (payload.selectCustomer && typeof payload.selectCustomer === 'object') {
        params.append('customer_id', payload.selectCustomer.id);
      } else {
        params.append('customer_id', context.rootState.auth.customerId);
      }
      if (payload.managerSelected) {
        params.append('manager_id', payload.managerSelected.id);
      }
      if (payload.relatedUsersSelected && payload.relatedUserIsDirty) {
        params.append('relationships', JSON.stringify(payload.relatedUsersSelected));
      }
      params.append('id', payload.user_id);
      params.append('first_name', Vue.prototype.$sanitize(payload.userName));
      params.append('last_name', Vue.prototype.$sanitize(payload.userSurname));
      params.append('email', Vue.prototype.$sanitize(payload.userEmail));
      params.append('division', Vue.prototype.$sanitize(payload.division) || '');
      params.append('unit', Vue.prototype.$sanitize(payload.unit) || '');
      params.append('title', Vue.prototype.$sanitize(payload.title) || '');
      params.append('phone_number', Vue.prototype.$sanitize(payload.phone_number) || '');
      params.append('timezone', payload.selectedTimezone);
      params.append('is_welcome_email_enabled', payload.sendWelcomeEmail);
      params.append('is_active', payload.activeUser);
      params.append('role', payload.selectRoles.name);
      params.append('team', Vue.prototype.$sanitize(payload.team));
      params.append('personnel_group', Vue.prototype.$sanitize(payload.personnel_group));
      params.append('company_name', Vue.prototype.$sanitize(payload.company_name));
      params.append('business_name', Vue.prototype.$sanitize(payload.business_name));
      params.append('business_group', Vue.prototype.$sanitize(payload.business_group));
      params.append('position', Vue.prototype.$sanitize(payload.position));
      params.append('operation', Vue.prototype.$sanitize(payload.operation));
      params.append('work_station', Vue.prototype.$sanitize(payload.work_station));
      params.append('personal_email', Vue.prototype.$sanitize(payload.personal_email));
      params.append('employment_start_date', Vue.prototype.$sanitize(payload.employment_start_date));
      if (Array.isArray(payload.selectLanguages)) {
        params.append('lang', payload.selectLanguages[0].name);
      } else {
        params.append('lang', payload.selectLanguages.name);
      }
      if (payload.userEmployeeNo) {
        params.append('employee_no', payload.userEmployeeNo);
      }

      await Vue.prototype.$API.put(API_USER, params)
        .then((response) => {
          if (response.status === 201) {
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    CREATE_GROUP: async (context, payload) => {
      const formData = new FormData();
      formData.append('name', Vue.prototype.$sanitize(payload.groupName));
      formData.append('customer_id', context.rootState.auth.customerId);

      await Vue.prototype.$API.post(API_USER_GROUP, formData)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
            const groupId = response.data.id;
            payload.users.forEach((user) => {
              context.dispatch('ADD_USER_TO_GROUP', { group_id: groupId, user_id: user.id });
            });
            context.commit('toggleModal', false);
          }
        }).catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_GROUP: async (context, payload) => {
      const formData = new URLSearchParams();
      formData.append('group_id', payload.group_id);
      formData.append('name', Vue.prototype.$sanitize(payload.groupName));


      await Vue.prototype.$API.put(API_USER_GROUP, formData)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
            payload.users.forEach((user) => {
              context.dispatch('ADD_USER_TO_GROUP', { group_id: payload.group_id, user_id: user.id });
            });
            context.commit('toggleModal', false);
          }
        }).catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    ADD_USER_TO_GROUP: async (context, payload) => {
      const formData = new FormData();
      formData.append('group_id', payload.group_id);
      formData.append('user_id', payload.user_id);

      await Vue.prototype.$API.post(API_USER_GROUP_USER, formData)
        .then((response) => {
          if (response.status === 200) {
            console.log('user added to group');
          }
        }).catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      // no need to refresh already processed on parent funct.
    },

    GET_GROUP: async (context) => {
      const ctx = context;
      await Vue.prototype.$API.get(`${API_USER_GROUP}/${context.state.groupId}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
            context.dispatch('GET_GROUP_USERS');
            setTimeout(() => {
              ctx.state.groupId = null;
            }, 100);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    GET_GROUP_USERS: async (context) => {
      // context.modalData = null;
      await Vue.prototype.$API.get(`${API_GET_GROUP_USERS}/${context.state.groupId}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_GROUP_USERS', response.data.data);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    CREATE_CUSTOMER: async (context, payload) => {
      const formData = new FormData();
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      formData.append('contact_name', Vue.prototype.$sanitize(payload.contactName));
      formData.append('email', Vue.prototype.$sanitize(payload.customerEmail));
      formData.append('domain_name', Vue.prototype.$sanitize(payload.domainNames));
      formData.append('timezone', payload.selectedTimezone);
      formData.append('is_slack_enabled', payload.is_slack_enabled);
      formData.append('langs', payload.selectedLanguages);
      if (!payload.disableKolayIk) {
        formData.append('kolayik_token', payload.kolayik_token || '');
      }
      if (payload.auth_token_ttl_minutes) {
        formData.append('auth_token_ttl_minutes', payload.auth_token_ttl_minutes);
      }
      if (payload.splashImage) {
        formData.append('splash_image', payload.splashImage);
      }
      if (payload.services && payload.services.length) {
        const newArr = payload.services.filter(a => a);
        formData.append('services', newArr.join(','));
      } else {
        formData.append('services', '');
      }
      if (payload.logoImage) {
        formData.append('logo', payload.logoImage);
      }
      if (payload.welcomeImage) {
        formData.append('welcome_email_img', payload.welcomeImage);
      }
      if (payload.sso_uri) {
        formData.append('sso_uri', Vue.prototype.$sanitize(payload.sso_uri));
      }
      if (payload.welcome_email_content) {
        formData.append('welcome_email_content', Vue.prototype.$sanitize(payload.welcome_email_content));
      }
      if (payload.welcome_email_subject) {
        formData.append('welcome_email_subject', Vue.prototype.$sanitize(payload.welcome_email_subject));
      }
      formData.append('email_sender_name', Vue.prototype.$sanitize(payload.email_sender_name));
      formData.append('is_privacy_policy_needed', payload.is_privacy_policy_needed);
      formData.append('is_weekly_reports_enabled', payload.is_weekly_reports_enabled);
      formData.append('create_user_account_from_sso', payload.create_user_account_from_sso);

      if (payload.is_pushnotification_enabled) {
        formData.append('push_server_type', '1');
        formData.append('push_endpoint', Vue.prototype.$sanitize(payload.pushServerEndpoint));
        formData.append('push_username', Vue.prototype.$sanitize(payload.pushServerUsername));
        formData.append('push_password', Vue.prototype.$sanitize(payload.pushServerPassword));
      }
      await Vue.prototype.$API.post(API_CUSTOMER, formData)
        .then((response) => {
          if (response.status === 200) {
            context.commit('auth/add_new_customer', { id: response.data.id, name: payload.name }, { root: true });
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    GET_CUSTOMER: async (context) => {
      const ctx = context;
      ctx.modalData = null;
      await Vue.prototype.$API.get(`${API_CUSTOMER}/${context.state.customerIdToEdit}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    UPDATE_CUSTOMER: async (context, payload) => {
      const params = new FormData();
      params.append('customer_id', payload.customer_id);
      params.append('name', Vue.prototype.$sanitize(payload.name));
      params.append('contact_name', Vue.prototype.$sanitize(payload.contactName));
      params.append('email', Vue.prototype.$sanitize(payload.customerEmail));
      params.append('domain_name', Vue.prototype.$sanitize(payload.domainNames));
      params.append('timezone', payload.selectedTimezone);
      params.append('is_slack_enabled', payload.is_slack_enabled);
      params.append('langs', payload.selectedLanguages);
      if (!payload.disableKolayIk) {
        params.append('kolayik_token', payload.kolayik_token || '');
      }
      if (payload.auth_token_ttl_minutes) {
        params.append('auth_token_ttl_minutes', payload.auth_token_ttl_minutes);
      }
      if (payload.services && payload.services.length) {
        const newArr = payload.services.filter(a => a);
        params.append('services', newArr.join(','));
      } else {
        params.append('services', '');
      }
      if (payload.splashImage) {
        params.append('splash_image', payload.splashImage);
      }
      if (payload.logoImage) {
        params.append('logo', payload.logoImage);
      }
      if (payload.welcomeImage) {
        params.append('welcome_email_img', payload.welcomeImage);
      }
      if (payload.welcome_email_subject) {
        params.append('welcome_email_subject', Vue.prototype.$sanitize(payload.welcome_email_subject));
      }
      if (payload.sso_uri) {
        params.append('sso_uri', Vue.prototype.$sanitize(payload.sso_uri));
      }
      if (payload.welcome_email_content) {
        params.append('welcome_email_content', Vue.prototype.$sanitize(payload.welcome_email_content));
      }
      params.append('email_sender_name', Vue.prototype.$sanitize(payload.email_sender_name));
      params.append('is_privacy_policy_needed', Vue.prototype.$sanitize(payload.is_privacy_policy_needed));
      params.append('is_weekly_reports_enabled', Vue.prototype.$sanitize(payload.is_weekly_reports_enabled));
      params.append('create_user_account_from_sso', Vue.prototype.$sanitize(payload.create_user_account_from_sso));

      params.append('push_server_type', payload.is_pushnotification_enabled);

      if (payload.is_pushnotification_enabled) {
        params.append('push_endpoint', Vue.prototype.$sanitize(payload.pushServerEndpoint));
        params.append('push_username', Vue.prototype.$sanitize(payload.pushServerUsername));
        params.append('push_password', Vue.prototype.$sanitize(payload.pushServerPassword));
      }

      await Vue.prototype.$API.post(API_CUSTOMER_UPDATE, params)
        .then((response) => {
          if (response.status === 201) {
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    CREATE_COMMUNICATION: async (context, payload) => {
      const formData = new FormData();
      formData.append('title', Vue.prototype.$sanitize(payload.title));
      formData.append('body', payload.body);
      formData.append('mail_body', Vue.prototype.$sanitize(payload.mail_body));
      formData.append('push_body', Vue.prototype.$sanitize(payload.push_body));
      formData.append('is_active', payload.is_active);
      formData.append('customer_id', payload.customer_id);
      if (payload.expire_date) {
        const edate = moment(payload.expire_date).format(API_SERVER_TIME_FORMAT);
        formData.append('expire_date', edate);
      }
      if (payload.groups && payload.groups.length) {
        const groups = payload.groups.map(g => g.id);
        formData.append('user_group_recipients', groups.join(','));
      }
      if (payload.users && payload.users.length) {
        const users = payload.users.map(u => u.id);
        formData.append('user_recipients', users.join(','));
      }
      if (payload.files) {
        // eslint-disable-next-line array-callback-return
        payload.files.map((f) => {
          formData.append('files[]', f);
        });
      }

      await Vue.prototype.$API.post(`${API_COMMUNICATION}/new`, formData)
        .then((response) => {
          if (response.status === 201) {
            context.commit('SET_MODAL_DATA', response.data);
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_COMMUNICATION: async (context, payload) => {
      const formData = new FormData();
      formData.append('id', payload.communicationIdToEdit);
      formData.append('title', Vue.prototype.$sanitize(payload.title));
      formData.append('body', payload.body);
      formData.append('mail_body', Vue.prototype.$sanitize(payload.mail_body));
      formData.append('push_body', Vue.prototype.$sanitize(payload.push_body));
      formData.append('is_active', payload.is_active);
      formData.append('customer_id', payload.customer_id);
      if (payload.expire_date) {
        const edate = moment(payload.expire_date, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
        formData.append('expire_date', edate);
      }
      if (payload.groups && payload.groups.length) {
        const groups = payload.groups.map(g => g.id);
        formData.append('user_group_recipients', groups.join(','));
      }
      if (payload.users && payload.users.length) {
        const users = payload.users.map(u => u.id);
        formData.append('user_recipients', users.join(','));
      }

      if (payload.files) {
        // eslint-disable-next-line array-callback-return
        payload.files.map((f) => {
          formData.append('files[]', f);
        });
      }

      if (payload.remove_files) {
        const removed = payload.remove_files.map(r => r.name);
        formData.append('remove_files', removed.join(','));
      }

      await Vue.prototype.$API.post(`${API_COMMUNICATION}/update`, formData)
        .then((response) => {
          if (response.status === 201) {
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    GET_COMMUNICATION: async (context) => {
      const ctx = context;
      ctx.modalData = null;
      await Vue.prototype.$API.get(`${API_COMMUNICATION}?id=${context.state.communicationIdToEdit}&customer_id=${context.state.customerIdToEdit}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_MODAL_DATA', response.data.cm);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },
    DELETE_COMMUNICATION: async (context, payload) => {
      const params = new URLSearchParams(payload);
      await Vue.prototype.$API.delete(`${API_COMMUNICATION}?${params}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },
    DELETE_COMMUNICATION_USER: async (context, payload) => {
      await Vue.prototype.$API.post(`${API_COMMUNICATION}/users/remove`, payload)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },
    SEND_NOTIFICATION: async (context, payload) => {
      const formData = new FormData();
      const { notificationType } = context.state;
      if (notificationType === 'journey') {
        formData.append('journey_id', context.state.journeyId);
        formData.append('title', Vue.prototype.$sanitize(payload.notificationHeading));
        formData.append('body', Vue.prototype.$sanitize(payload.notificationBody));
        await Vue.prototype.$API.post(API_JOURNEY_NOTIFICATION, formData)
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', response.data);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      } else if (notificationType === 'journey_item') {
        formData.append('journey_item_id', context.state.journeyItemId);
        formData.append('title', Vue.prototype.$sanitize(payload.notificationHeading));
        formData.append('body', Vue.prototype.$sanitize(payload.notificationBody));
        await Vue.prototype.$API.post(API_JOURNEY_ITEM_NOTIFICATION, formData)
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', response.data);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      } else if (notificationType === 'group') {
        formData.append('group_id', context.state.groupId);
        formData.append('title', Vue.prototype.$sanitize(payload.notificationHeading));
        formData.append('body', Vue.prototype.$sanitize(payload.notificationBody));
        await Vue.prototype.$API.post(API_GROUP_NOTIFICATION, formData)
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', response.data);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      } else if (notificationType === 'user') {
        formData.append('user_id', context.state.userId);
        formData.append('title', Vue.prototype.$sanitize(payload.notificationHeading));
        formData.append('body', Vue.prototype.$sanitize(payload.notificationBody));
        await Vue.prototype.$API.post(API_USER_NOTIFICATION, formData)
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', response.data);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
    },

    CREATE_FILEDOWNLOAD_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }

      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      context.commit('setMultiProcessProgressBarValue', true);

      await Vue.prototype.$API.post(API_FILE_DOWNLOAD_ADD, formData)
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[43];

          context.commit('SET_MODAL_DATA', payloadHolder);
          await context.dispatch('CREATE_FILEDOWNLOAD_ITEM', payloadHolder).then(async () => {
            if (context.state.journeyId) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          });
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('toggleModal', false);
      context.commit('setMultiProcessProgressBarValue', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_FILEDOWNLOAD_ITEM: async (context, payload) => {
      const { fileDownloadItems } = payload;
      if (fileDownloadItems) {
        // init your manager.
        const manager = ConcurrencyManager(Vue.prototype.$API, MAX_CONCURRENT_REQUESTS);
        await Promise.all(fileDownloadItems.map(async (item) => {
          const formData = new FormData();
          formData.append('content_id', payload.content_id);
          formData.append('title', Vue.prototype.$sanitize(item.title));
          formData.append('desc', Vue.prototype.$sanitize(item.desc));
          if (item.file) {
            const thefile = await sanitizeSVG(item.file);
            formData.append('file', thefile);
          }

          await Vue.prototype.$API.post(API_FILE_DOWNLOAD_ADD_FILE, formData)
            .then((response) => {
              if (response.status === 200) {
                console.log('filedownload item added!');
              }
            })
            .catch((error) => {
              context.commit('ERROR_MODAL_DATA', true);
              context.dispatch('DISPLAY_ERROR', error);
            });
        })).then(() => {
          manager.detach();
        });
      }
    },

    UPDATE_FILEDOWNLOAD_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }

      formData.append('lang', payload.contentLanguage.name);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      context.commit('setMultiProcessProgressBarValue', true);
      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_FILE_DOWNLOAD_UPDATE, formData)
          .then(async (response) => {
            console.log('respomse from update:', response.status, response);
            if (response.status === 200) {
              await context.dispatch('UPDATE_FILEDOWNLOAD_ITEM', payload);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setMultiProcessProgressBarValue', false);
      context.commit('toggleModal', false);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_FILEDOWNLOAD_ITEM: async (context, payload) => {
      const { fileDownloadItems } = payload;
      console.log('fileDownloadItems:', fileDownloadItems);
      if (fileDownloadItems) {
        await Promise.all(fileDownloadItems.map(async (item) => {
          if (item.id && (item.file || item.filename)) {
            const formData = new FormData();
            formData.append('file_id', item.id);
            formData.append('title', Vue.prototype.$sanitize(item.title));
            formData.append('desc', Vue.prototype.$sanitize(item.desc));
            if (item.file) {
              const thefile = await sanitizeSVG(item.file);
              formData.append('file', thefile);
            }

            await Vue.prototype.$API.post(API_FILE_DOWNLOAD_FILE_UPDATE, formData)
              .then((response) => {
                if (response.status === 200) {
                  console.log('filedownload file updated!');
                }
              })
              .catch((error) => {
                context.commit('ERROR_MODAL_DATA', true);
                context.dispatch('DISPLAY_ERROR', error);
              });
          }
          if (typeof item.id === 'undefined') {
            const newPayload = {
              content_id: payload.content_id,
              fileDownloadItems: [{ title: Vue.prototype.$sanitize(item.title), desc: Vue.prototype.$sanitize(item.desc), file: item.file }],
            };

            await context.dispatch('CREATE_FILEDOWNLOAD_ITEM', newPayload);
          }
        })).then(() => {
          console.log('UPDATE_FILEDOWNLOAD_ITEM finished.');
        });
      }
    },

    DELETE_FILEDOWNLOAD_ITEM: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_FILE_DOWNLOAD_FILE_DELETE}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    CREATE_CHECKLIST_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, payload.name));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      context.commit('setMultiProcessProgressBarValue', true);

      await Vue.prototype.$API.post(API_CONTENT_CHECKLIST, formData)
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[12];

          context.commit('SET_MODAL_DATA', payloadHolder);
          await context.dispatch('CREATE_CHECKLIST_ITEM', payloadHolder).then(async () => {
            if (context.state.journeyId) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          });
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('toggleModal', false);
      context.commit('setMultiProcessProgressBarValue', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_CHECKLIST_ITEM: async (context, payload) => {
      const checkListData = payload.checklistItems;
      if (checkListData) {
        // init your manager.
        const manager = ConcurrencyManager(Vue.prototype.$API, MAX_CONCURRENT_REQUESTS);
        await Promise.all(checkListData.map(async (item) => {
          const formData = new FormData();
          formData.append('content_id', payload.content_id);
          formData.append('item_text', Vue.prototype.$sanitize(item.item_text));

          await Vue.prototype.$API.post(API_CONTENT_CHECKLIST_ITEM, formData)
            .then((response) => {
              if (response.status === 200) {
                console.log('checklist added!');
              }
            })
            .catch((error) => {
              context.commit('ERROR_MODAL_DATA', true);
              context.dispatch('DISPLAY_ERROR', error);
            });
        })).then(() => {
          manager.detach();
        });
      }
    },

    UPDATE_CHECKLIST_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('lang', payload.contentLanguage.name);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      context.commit('setMultiProcessProgressBarValue', true);
      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_CHECKLIST, formData)
          .then(async (response) => {
            if (response.status === 200) {
              await context.dispatch('UPDATE_CHECKLIST_ITEM', payload);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setMultiProcessProgressBarValue', false);
      context.commit('toggleModal', false);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_CHECKLIST_ITEM: async (context, payload) => {
      const checkListData = payload.checklistItems;
      if (checkListData) {
        await Promise.all(checkListData.map(async (item) => {
          if (item.id) {
            const formData = new URLSearchParams();
            formData.append('item_id', item.id);
            formData.append('item_text', Vue.prototype.$sanitize(item.item_text));

            await Vue.prototype.$API.put(API_CONTENT_CHECKLIST_ITEM, formData)
              .then((response) => {
                if (response.status === 200) {
                  console.log('checklist updated!');
                }
              })
              .catch((error) => {
                context.commit('ERROR_MODAL_DATA', true);
                context.dispatch('DISPLAY_ERROR', error);
              });
          } else {
            const newPayload = {
              content_id: payload.content_id,
              checklistItems: [{ item_text: Vue.prototype.$sanitize(item.item_text) }],
            };

            await context.dispatch('CREATE_CHECKLIST_ITEM', newPayload);
          }
        })).then(() => {
          console.log('UPDATE_CHECKLIST_ITEM finished.');
        });
      }
    },

    DELETE_CHECKLIST_ITEM: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_CONTENT_CHECKLIST_ITEM}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    CREATE_SURVEY_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }
      if (payload.surveyContentType === 'nps') {
        formData.append('is_nps', 1);
      } else {
        formData.append('is_nps', 0);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 11);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_show_statistics_enabled', 1);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, payload.name));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      context.commit('setMultiProcessProgressBarValue', true);

      await Vue.prototype.$API.post(API_CONTENT_SURVEY, formData)
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[11];

          context.commit('SET_MODAL_DATA', payload);
          await context.dispatch('CREATE_SURVEY_QUESTION', payloadHolder).then(async () => {
            if (context.state.journeyId) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          });
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('toggleModal', false);
      context.commit('setMultiProcessProgressBarValue', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_SURVEY_QUESTION: async (context, payload) => {
      const questionData = payload.surveyQuestions;
      // init your manager.
      const manager = ConcurrencyManager(Vue.prototype.$API, MAX_CONCURRENT_REQUESTS);

      if (payload.content_id && questionData) { // eslint-disable-next-line
        await Promise.all(questionData.map(async (i) => {
          const newPayload = {};
          const questionForm = new FormData();
          questionForm.append('content_id', payload.content_id);
          questionForm.append('question', Vue.prototype.$sanitize(i.question));
          questionForm.append('type', i.type);
          questionForm.append('options', JSON.stringify(i.options));
          questionForm.append('sub_text', i.sub_text ? Vue.prototype.$sanitize(i.sub_text) : '');

          newPayload.choices = i.choices;
          await Vue.prototype.$API.post(API_CONTENT_SURVEY_QUESTION, questionForm)
            .then(async (response) => {
              if (response.status === 200) {
                const questionId = response.data.id;
                newPayload.questionId = questionId;
                await context.dispatch('CREATE_SURVEY_ANSWER', newPayload);
              }
            })
            .catch((error) => {
              context.commit('ERROR_MODAL_DATA', true);
              context.dispatch('DISPLAY_ERROR', error);
            });
        })).then(() => {
          manager.detach();
        });
      }
    },

    CREATE_SURVEY_ANSWER: async (context, payload) => {
      const { questionId } = payload;
      const valueData = payload.choices;
      if (questionId && valueData) {
        // init your manager.
        const manager = ConcurrencyManager(Vue.prototype.$API, MAX_CONCURRENT_REQUESTS);

        // eslint-disable-next-line
        await Promise.all(valueData.map(async (answerValue) => {

          const choiceText = Vue.prototype.$sanitize(answerValue.choice_text);
          const choiceImage = answerValue.image;

          if (choiceText || choiceImage) {
            const answerForm = new FormData();
            answerForm.append('question_id', questionId);
            if (choiceText) {
              answerForm.append('choice_text', Vue.prototype.$sanitize(choiceText));
            }
            if (choiceImage) {
              answerForm.append('image', choiceImage);
            }
            await Vue.prototype.$API.post(API_CONTENT_SURVEY_QUESTION_CHOICE, answerForm)
              .then((response) => {
                if (response.status === 200) {
                  console.log('Ok .. good to go.. no need any action!');
                }
              })
              .catch((error) => {
                context.commit('ERROR_MODAL_DATA', true);
                context.dispatch('DISPLAY_ERROR', error);
              });
          }
        })).then(() => {
          manager.detach();
        });
      }
    },

    UPDATE_SURVEY_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }
      if (payload.surveyContentType === 'nps') {
        formData.append('is_nps', 1);
      } else {
        formData.append('is_nps', 0);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 11);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_show_statistics_enabled', 1);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      context.commit('setMultiProcessProgressBarValue', true);
      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_SURVEY, formData)
          .then(async (response) => {
            if (response.status === 200 && payload.dirtyList && payload.dirtyList.length) {
              await context.dispatch('UPDATE_SURVEY_QUESTION', payload);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setMultiProcessProgressBarValue', false);
      context.commit('toggleModal', false);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_SURVEY_QUESTION: async (context, payload) => {
      const questionData = payload.surveyQuestions;
      if (questionData) {
        await Promise.all(questionData.map(async (i) => {
          if (i && i.id) { // check if exist
            const newPayload = {};
            const questionForm = new URLSearchParams();
            questionForm.append('question_id', i.id);
            questionForm.append('question', Vue.prototype.$sanitize(i.question));
            questionForm.append('type', i.type);
            questionForm.append('options', JSON.stringify(i.options));
            questionForm.append('sub_text', i.sub_text ? Vue.prototype.$sanitize(i.sub_text) : '');
            newPayload.choices = i.choices;
            newPayload.question_id = i.id;

            await Vue.prototype.$API.put(API_CONTENT_SURVEY_QUESTION, questionForm)
              .then(async (response) => {
                if (response.status === 200 && i.type !== 'text') {
                  await context.dispatch('UPDATE_SURVEY_ANSWER', newPayload);
                }
              })
              .catch((error) => {
                context.commit('ERROR_MODAL_DATA', true);
                context.dispatch('DISPLAY_ERROR', error);
              });
          } else {
            // then create new question
            const newPayload = {
              content_id: payload.content_id,
              surveyQuestions: [{
                question: Vue.prototype.$sanitize(i.question), type: i.type, choices: i.choices, sub_text: Vue.prototype.$sanitize(i.sub_text), options: i.options,
              }],
            };

            await context.dispatch('CREATE_SURVEY_QUESTION', newPayload);
          }
          // eslint-disable-next-line consistent-return
        })).then(() => {
          console.log('UPDATE_SURVEY_QUESTION finished.');
        });
      }
    },

    UPDATE_SURVEY_ANSWER: async (context, payload) => {
      const valueData = payload.choices;
      if (valueData) {
        await Promise.all(valueData.map(async (answerValue) => {
          if (answerValue) {
            const choiceId = answerValue.id;
            const choiceText = Vue.prototype.$sanitize(answerValue.choice_text);
            const choiceImage = answerValue.image;

            if (choiceId) { // update if exist
              const answerForm = new URLSearchParams();
              answerForm.append('choice_id', choiceId);
              if (choiceText) {
                answerForm.append('choice_text', choiceText);
              }
              if (choiceImage) {
                answerForm.append('image', choiceImage);
              }
              await Vue.prototype.$API.put(API_CONTENT_SURVEY_QUESTION_CHOICE, answerForm)
                .then((response) => {
                  if (response.status === 200) {
                    console.log('Ok .. good to go.. no need any action!');
                  }
                })
                .catch((error) => {
                  context.commit('ERROR_MODAL_DATA', true);
                  context.dispatch('DISPLAY_ERROR', error);
                });
            } else {
              let tempChoices = [{ choice_text: Vue.prototype.$sanitize(choiceText) }];
              if (choiceImage) {
                tempChoices = [{ choice_text: Vue.prototype.$sanitize(choiceText), image: choiceImage }];
              }
              // then create new answer
              const newPayload = {
                questionId: payload.question_id,
                choices: tempChoices,
              };

              await context.dispatch('CREATE_SURVEY_ANSWER', newPayload);
            }
          }
        })).then(() => {
          console.log('UPDATE_SURVEY_ANSWER finished');
        });
      }
    },

    DELETE_SURVEY_QUESTION: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_CONTENT_SURVEY_QUESTION}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    DELETE_SURVEY_ANSWER: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_CONTENT_SURVEY_QUESTION_CHOICE}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    UPDATE_ORDER_SURVEY: async (context, payload) => {
      await Vue.prototype.$API.post(API_SURVEY_ORDER_UPDATE, payload)
        .then((response) => {
          if (response.status === 200) {
            console.log('order saved!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    CREATE_REQUEST_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 41);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_file_upload_required', payload.is_file_upload_required);
      formData.append('is_show_statistics_enabled', 1);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, payload.name));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      context.commit('setMultiProcessProgressBarValue', true);

      await Vue.prototype.$API.post(API_CONTENT_REQUEST, formData)
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[41];

          context.commit('SET_MODAL_DATA', payload);
          await context.dispatch('CREATE_REQUEST_QUESTION', payloadHolder).then(async () => {
            if (context.state.journeyId) {
              returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
            }
          });
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('toggleModal', false);
      context.commit('setMultiProcessProgressBarValue', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    CREATE_REQUEST_QUESTION: async (context, payload) => {
      const questionData = payload.requestQuestions;
      // init your manager.
      const manager = ConcurrencyManager(Vue.prototype.$API, MAX_CONCURRENT_REQUESTS);

      if (payload.content_id && questionData) { // eslint-disable-next-line
        await Promise.all(questionData.map(async (i) => {
          const newPayload = {};
          const questionForm = new FormData();
          questionForm.append('content_id', payload.content_id);
          questionForm.append('question', Vue.prototype.$sanitize(i.question));
          questionForm.append('type', i.type);
          if (i.type === 'get_info') {
            questionForm.append('options', JSON.stringify(i.options));
          } else {
            questionForm.append('options', JSON.stringify({}));
          }
          questionForm.append('sub_text', Vue.prototype.$sanitize(i.sub_text));

          newPayload.choices = i.choices;
          await Vue.prototype.$API.post(API_CONTENT_REQUEST_QUESTION, questionForm)
            .then(async (response) => {
              if (response.status === 200) {
                console.log('Question created:', response.data.id);
              }
            })
            .catch((error) => {
              context.commit('ERROR_MODAL_DATA', true);
              context.dispatch('DISPLAY_ERROR', error);
            });
        })).then(() => {
          manager.detach();
        });
      }
    },

    UPDATE_REQUEST_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 11);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_file_upload_required', payload.is_file_upload_required);
      formData.append('is_show_statistics_enabled', 1);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      context.commit('setMultiProcessProgressBarValue', true);
      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_CONTENT_REQUEST, formData)
          .then(async (response) => {
            if (response.status === 200 && payload.dirtyList && payload.dirtyList.length) {
              await context.dispatch('UPDATE_REQUEST_QUESTION', payload);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setMultiProcessProgressBarValue', false);
      context.commit('toggleModal', false);
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_REQUEST_QUESTION: async (context, payload) => {
      const questionData = payload.requestQuestions;
      if (questionData) {
        await Promise.all(questionData.map(async (i) => {
          if (i && i.id) { // check if exist
            const questionForm = new URLSearchParams();
            questionForm.append('question_id', i.id);
            questionForm.append('question', Vue.prototype.$sanitize(i.question));
            questionForm.append('type', i.type);
            if (i.type === 'get_info') {
              questionForm.append('options', JSON.stringify(i.options));
            } else {
              questionForm.append('options', JSON.stringify({}));
            }
            questionForm.append('sub_text', Vue.prototype.$sanitize(i.sub_text));

            await Vue.prototype.$API.put(API_CONTENT_REQUEST_QUESTION, questionForm)
              .then(async (response) => {
                if (response.status === 200) {
                  console.log('success');
                }
              })
              .catch((error) => {
                context.commit('ERROR_MODAL_DATA', true);
                context.dispatch('DISPLAY_ERROR', error);
              });
          } else {
            // then create new question
            const newPayload = {
              content_id: payload.content_id,
              requestQuestions: [{
                question: Vue.prototype.$sanitize(i.question), type: i.type, sub_text: Vue.prototype.$sanitize(i.sub_text), options: i.options,
              }],
            };

            await context.dispatch('CREATE_REQUEST_QUESTION', newPayload);
          }
          // eslint-disable-next-line consistent-return
        })).then(() => {
          console.log('UPDATE_REQUEST_QUESTION finished.');
        });
      }
    },

    DELETE_REQUEST_QUESTION: async (context, payload) => {
      await Vue.prototype.$API.delete(`${API_CONTENT_REQUEST_QUESTION}/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Deleted!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    UPDATE_ORDER_REQUEST: async (context, payload) => {
      await Vue.prototype.$API.post(API_REQUEST_ORDER_UPDATE, payload)
        .then((response) => {
          if (response.status === 200) {
            console.log('order saved!');
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
    },

    CREATE_SCHEDULED_NOTIFICATION_CONTENT: async (context, payload) => {
      let time = null;
      if (context.state.journeyDetail && context.state.journeyDetail.mobile_device_penetration.number_of_total_users > 0) {
        time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
      } else if (payload.isWeeklyEntry) {
        time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
      } else {
        time = moment().format(API_SERVER_TIME_FORMAT);
      }

      const formData = new FormData();
      formData.append('journey_id', context.state.journeyId);
      formData.append('journey_item_id', payload.notification_journey_item_id);
      formData.append('title', Vue.prototype.$sanitize(payload.notification_title));
      formData.append('body', Vue.prototype.$sanitize(payload.notification_body));
      formData.append('notify_dt', time);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      await Vue.prototype.$API.post(API_JOURNEY_SCHEDULED_NOTIFICATION, formData)
        .then((response) => {
          if (response.status === 200) {
            context.commit('toggleModal', false);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('REFRESH_PAGE', true);
    },

    UPDATE_SCHEDULED_NOTIFICATION_CONTENT: async (context, payload) => {
      let time = null;
      time = moment(payload.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
      const formData = new URLSearchParams();
      formData.append('notification_id', payload.noficationId);
      formData.append('journey_id', payload.notification_journeyId);
      formData.append('journey_item_id', payload.notification_journey_item_id);
      formData.append('title', Vue.prototype.$sanitize(payload.notification_title));
      formData.append('body', Vue.prototype.$sanitize(payload.notification_body));
      formData.append('notify_dt', time);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.put(API_JOURNEY_SCHEDULED_NOTIFICATION, formData)
          .then((response) => {
            if (response.status === 200) {
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('REFRESH_PAGE', true);
    },

    CREATE_LIVE_EVENT_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      console.log('liveevent payload:', payload);
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));
      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 14);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('starting_date', moment(payload.starting_date, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT));
      formData.append('streamer_user_id', payload.streamer_user_id);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      formData.append('is_reminder_enabled', payload.is_reminder_enabled);

      await Vue.prototype.$API.post(API_CONTENT_LIVE_EVENT,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[14];

          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId) {
            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    UPDATE_LIVE_EVENT_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }

      if (payload.isRescheduling && !payload.info) {
        formData.append('info', moment(payload.starting_date, API_SERVER_TIME_FORMAT).format(payload.contentLanguage.name === 'en' ? 'MM.DD.YYYY / HH:mm' : 'DD.MM.YYYY / HH:mm'));
      } else if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      if (payload.contentLanguage && payload.contentLanguage.name) {
        formData.append('lang', payload.contentLanguage.name);
      }
      formData.append('is_public', payload.is_public);

      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.tags) {
        formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      formData.append('subtype_id', 14);

      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      if (payload.starting_date) {
        let sdate = payload.starting_date;
        if (moment(sdate, API_SERVER_TIME_FORMAT).isBefore(moment())) {
          sdate = moment().add(1, 'Y').toDate();
        }
        formData.append('starting_date', moment(sdate, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT));
      }

      if (payload.streamer_user_id) {
        formData.append('streamer_user_id', payload.streamer_user_id);
      }

      if (payload.is_notifications_enabled) {
        formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      }

      if (payload.is_email_enabled) {
        formData.append('is_email_enabled', payload.is_email_enabled);
      }

      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }

      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }

      if (payload.is_reminder_enabled) {
        formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_LIVE_EVENT,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    CREATE_MEETING_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const nameHolder = Vue.prototype.$sanitize(payload.name);
      payloadHolder.isMeetingEntry = true; // to check the journey items for each class have same activation time
      payloadHolder.meetingActivationTime = await context.dispatch('GET_ITEM_LAST_ACTIVATION_TIME'); // each class has now same activation date
      let startingDateHolder = null;
      if (context.state.journeyDetail && context.state.journeyDetail.mobile_device_penetration.number_of_total_users > 0) {
        startingDateHolder = moment(payload.starting_date).format(API_SERVER_TIME_FORMAT);
      } else {
        startingDateHolder = moment().add(1, 'Y').format(API_SERVER_TIME_FORMAT);
      }
      payloadHolder.info = moment(startingDateHolder, API_SERVER_TIME_FORMAT).format(payload.contentLanguage.name === 'en' ? 'MM.DD.YYYY / HH:mm' : 'DD.MM.YYYY / HH:mm');

      if (payload.duration) {
        payloadHolder.duration = payload.duration;
      }
      if (payloadHolder.numRemoteClass > 1) {
        let i = 1;
        for (i = 1; i <= payloadHolder.numRemoteClass; i += 1) {
          payloadHolder.name = `${nameHolder}-${i}`;
          payloadHolder.starting_date = startingDateHolder;
          // eslint-disable-next-line no-await-in-loop
          await context.dispatch('CREATE_MEETING_CLASS_CONTENT', payloadHolder);
        }
      } else {
        payloadHolder.starting_date = startingDateHolder;
        await context.dispatch('CREATE_MEETING_CLASS_CONTENT', payloadHolder);
      }
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      context.commit('REFRESH_PAGE', true);
    },

    CREATE_MEETING_CLASS_CONTENT: async (context, payload) => {
      const payloadHolder = payload;
      const formData = new FormData();
      let returnedJourneyItem = null;
      formData.append('customer_id', payload.customer_id);
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      } formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      formData.append('lang', payload.contentLanguage.name);
      formData.append('subtype_id', 15);
      formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      formData.append('duration', payload.classDuration);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      formData.append('starting_date', payload.starting_date);
      formData.append('streamer_user_id', payload.streamer_user_id);
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      formData.append('is_email_enabled', payload.is_email_enabled);
      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body.replace(CONTENT_NAME, payload.name)));
      }
      if (payload.is_reminder_enabled) {
        formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      }

      await Vue.prototype.$API.post(API_CONTENT_REMOTE_CLASS,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
          },
        })
        .then(async (response) => {
          const contentId = response.data.id;
          payloadHolder.content_id = contentId;
          // eslint-disable-next-line prefer-destructuring
          payloadHolder.temp_sub_type = subtypeList[15];

          context.commit('SET_MODAL_DATA', payloadHolder);
          if (context.state.journeyId) {
            returnedJourneyItem = await context.dispatch('CREATE_JOURNEY_ITEM', payloadHolder);
          }
        })
        .catch((error) => {
          context.commit('ERROR_MODAL_DATA', true);
          context.dispatch('DISPLAY_ERROR', error);
        });
      context.commit('setProgressBarValue', -1);
      context.commit('toggleModal', false);
      if (returnedJourneyItem) {
        Vue.prototype.$bus.$root.$emit('newItemDropFromLibrary', returnedJourneyItem);
      }
      if (context.rootState.modal.pageToRefresh === 'Contents') {
        context.commit('REFRESH_PAGE', true);
      }
    },

    UPDATE_MEETING_CONTENT: async (context, payload) => {
      const formData = new FormData();
      formData.append('content_id', payload.content_id);
      formData.append('name', Vue.prototype.$sanitize(payload.name));

      if (context.rootState.auth.isVibonsAdmin && payload.customer_id) {
        formData.append('customer_id', payload.customer_id);
      }
      if (context.rootState.auth.isVibonsAdmin && payload.thumbnail_filename) {
        formData.append('thumbnail_filename', payload.thumbnail_filename);
      }

      if (context.rootState.auth.isVibonsAdmin && payload.aoptions) {
        formData.append('options', payload.aoptions);
      }

      if (payload.desc) {
        formData.append('desc', Vue.prototype.$sanitize(payload.desc));
      } else {
        formData.append('desc', ' ');
      }
      if (payload.isRescheduling && !payload.info) {
        formData.append('info', moment(payload.starting_date, context.rootState.auth.userInfo.lang === 'en'
          ? 'MM.DD.YYYY / HH:mm' : 'DD.MM.YYYY / HH:mm').format(payload.contentLanguage.name === 'en' ? 'MM.DD.YYYY / HH:mm' : 'DD.MM.YYYY / HH:mm'));
      } else if (payload.info) {
        formData.append('info', Vue.prototype.$sanitize(payload.info));
      }
      if (payload.content_provider) {
        formData.append('content_provider', Vue.prototype.$sanitize(payload.content_provider));
      }
      if (payload.duration) {
        formData.append('duration', Vue.prototype.$sanitize(payload.duration));
      }
      if (payload.contentLanguage && payload.contentLanguage.name) {
        formData.append('lang', payload.contentLanguage.name);
      }
      formData.append('is_public', payload.is_public);
      if (payload.showHideFromLxp) {
        formData.append('hide_from_lxp', payload.hide_from_lxp || 0);
      }
      if (payload.tags) {
        formData.append('tags', Vue.prototype.$sanitize(payload.tags));
      }
      if (payload.classDuration) {
        formData.append('duration', payload.classDuration);
      }

      formData.append('subtype_id', 15);
      if (payload.thumbnail) {
        const thefile = await sanitizeSVG(payload.thumbnail);
        formData.append('thumbnail', thefile);
      }
      if (payload.starting_date && payload.isRescheduling) {
        let sdate = payload.starting_date;
        if (moment(sdate, API_SERVER_TIME_FORMAT).isBefore(moment())) {
          sdate = moment().add(1, 'Y').toDate();
        }
        formData.append('starting_date', moment(sdate, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT));
      } else {
        formData.append('starting_date', moment(payload.starting_date, context.rootState.auth.userInfo.lang === 'en'
          ? 'MM.DD.YYYY / HH:mm' : 'DD.MM.YYYY / HH:mm').format(API_SERVER_TIME_FORMAT));
      }
      const isHidden = payload.is_info_labels_hidden ? 1 : 0; // sometimes it gets false/true  values instead of 0-1,
      formData.append('is_info_labels_hidden', isHidden);
      if (payload.streamer_user_id) {
        formData.append('streamer_user_id', payload.streamer_user_id);
      }
      if (payload.is_notifications_enabled) {
        formData.append('is_notifications_enabled', payload.is_notifications_enabled);
      }
      if (payload.is_email_enabled) {
        formData.append('is_email_enabled', payload.is_email_enabled);
      }

      if (payload.push_notification_title) {
        formData.append('push_notification_title', Vue.prototype.$sanitize(payload.push_notification_title));
      }
      if (payload.push_notification_body) {
        formData.append('push_notification_body', Vue.prototype.$sanitize(payload.push_notification_body).replace(CONTENT_NAME, Vue.prototype.$sanitize(payload.name)));
      }

      if (payload.is_reminder_enabled) {
        formData.append('is_reminder_enabled', payload.is_reminder_enabled);
      }

      if (context.state.journeyId && context.state.journeyItemId) {
        await context.dispatch('UPDATE_JOURNEY_ITEM', payload);
      }

      if (!payload.is_public || (context.rootState.auth.isVibonsAdmin && payload.is_public)) {
        await Vue.prototype.$API.post(API_UPDATE_REMOTE_CLASS,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              context.commit('setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
            },
          })
          .then((response) => {
            if (response.status === 200) {
              context.commit('SET_MODAL_DATA', payload);
              context.commit('toggleModal', false);
            }
          })
          .catch((error) => {
            context.commit('ERROR_MODAL_DATA', true);
            context.dispatch('DISPLAY_ERROR', error);
          });
      }
      context.commit('setProgressBarValue', -1);
      context.commit('REFRESH_PAGE', true);
    },

    GET_JOURNEY_REMINDER: async (_context, payload) => new Promise((resolve, reject) => {
      Vue.prototype.$API.get(`${API_JOURNEY_REMINDER}/${payload}`)
        .then((response) => {
          if (response.data.StatusCode === 200) {
            resolve(response.data.reminder);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }),
    POST_JOURNEY_REMINDER: async (_context, payload) => new Promise((resolve, reject) => {
      Vue.prototype.$API.post(API_JOURNEY_REMINDER, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }),
    DELETE_JOURNEY_REMINDER: async (_context, payload) => new Promise((resolve, reject) => {
      Vue.prototype.$API.delete(`${API_JOURNEY_REMINDER}?journey_id=${payload}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }),

    GET_JOURNEYV2_USERS: async (_context, payload) => new Promise((resolve, reject) => {
      Vue.prototype.$API.get(`${API_JOURNEY_V2_USERS}`, { params: payload })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  },
};
