const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const API_CLIENT_BASE_URL = BASE_URL;
export const API_PREFIX = process.env.VUE_APP_API_PREFIX;
export const WHITELIST_URLS = [
  'myenocta.com',
  'mediamarktsaturn.com',
  'alo-tech.com',
  'amazon.com',
  'amazonaws.com',
  'anadoluefes.com',
  'atlassian.net',
  'canada.ca',
  'canva.com',
  'cii-europe.com',
  'cube-net.pub',
  'decathlon.com',
  'decathlon.com.tr',
  'decathlon.net',
  'denizbank.com',
  'etohum.com',
  'exceedlms.com',
  'facebook.com',
  'github.com',
  'google.com',
  'hackerrank.com',
  'hbr.org',
  'hbrturkiye.com',
  'hrtechtr.com',
  'indeed.com',
  'inspiringdigital.net',
  'instagram.com',
  'itugirisim.org',
  'journeyapp.com',
  'kkbmeydan.com',
  'kolayik.com',
  'kordsa.com',
  'kordsaglobal.com',
  'kvkk.gov.tr',
  'learningseo.io',
  'linkedin.com',
  'loom.com',
  'mediadecathlon.com',
  'medium.com',
  'mevzuat.gov.tr',
  'muhendishane.org',
  'mural.co',
  'novascotia.ca',
  'notion.site',
  'notion.so',
  'nytimes.com',
  'pluralsight.com',
  'sabancidx.com',
  'sirketiciduyuru.com',
  'spotify.com',
  'ted.com',
  'trendyol.com',
  'twitter.com',
  'typeform.com',
  'udemy.com',
  'un.org',
  'vimeo.com',
  'vimeo.com',
  'webrazzi.com',
  'withgoogle.com',
  'youtube.com',
  'zoom.us',
  'softinya.com',
  'service-now.com',
  'twiser.com',
  'office.com',
  'sharepoint.com',
  'ataflex.net',
  'percipio.com',
  'workintech.com.tr',
];
export const API_BASE_URL = `${API_CLIENT_BASE_URL}/api`;
export const CORS_PROXY_URL = process.env.VUE_APP_CORS_PROXY_URL; // make sure it ends with a slash /
export const GOOGLE_API_KEY = 'AIzaSyCjM2qd1pFS3uKqZqMJws-px_pd1SoKHzg';
export const GOOGLE_RECAPTCHA_SITE_KEY = '6LeXFAAmAAAAAGuhFcdofY5a0Bw8ZL9vsxSkF5DW';
export const YOUTUBE_INFO_URL = 'https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics,contentDetails&id={VIDEO_ID}&key={GOOGLE_API_KEY}';
export const YOUTUBE_VIDEOS_LIST = 'http://127.0.0.1:5000';
export const API_SERVER_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const API_RECAPTCHA = `${API_CLIENT_BASE_URL}/recaptcha`;
// Auth
export const API_LOGIN = `${API_BASE_URL}/auth/login`;
export const API_LOGOUT = `${API_BASE_URL}/auth/logout`;
export const API_FORGOT_PASSWORD = `${API_BASE_URL}/auth/password/forgot`;
export const API_SEND_PASSWORD_RESET = `${API_CLIENT_BASE_URL}/user/password/reset`;
export const API_LOGIN_REFRESH = `${API_BASE_URL}/auth/refresh`;
export const API_GET_USER_INFO = `${API_BASE_URL}/auth/me`;
export const API_USER_PINCODE = `${API_BASE_URL}/user/login/pin`;

// Datatables
export const API_USER_DATATABLES = `${API_BASE_URL}/user/datatables`;
export const API_USER_GROUP_DATATABLES = `${API_BASE_URL}/usergroup/datatables`;
export const API_CONTENT_DATATABLES = `${API_BASE_URL}/content/datatables`;
export const API_GET_CUSTOMER_LIST = `${API_BASE_URL}/customer/list`;
export const API_GET_CUSTOMER_DATATABLES = `${API_BASE_URL}/customer/datatables`;
export const API_GET_CUSTOMER_LIST_LIGHT = `${API_BASE_URL}/customer/all`;
export const API_GET_DASHBOARD_CUSTOMERS = `${API_BASE_URL}/customer/stats/monthly/request`;
export const API_USER_LIST = `${API_BASE_URL}/user/datatables`;
export const API_SURVEY_QUIZ_DATATABLES = `${API_BASE_URL}/survey_quiz/datatables`;

// Create Content
export const API_GET_CONTENT_INFO = `${API_BASE_URL}/content`;
export const API_GET_CONTENT_TYPES = `${API_BASE_URL}/content/types`;
export const API_GET_CONTENT_SUBTYPES = `${API_BASE_URL}/content/subtypes`;
export const API_UPDATE_CONTENT_IMAGE = `${API_BASE_URL}/content/image/update`;

// Create Sub Content
export const API_CONTENT_VIDEO = `${API_BASE_URL}/content/video`;
export const API_CONTENT_VIDEO_CDN = `${API_BASE_URL}/content/externalcdnvideo`;
export const API_CONTENT_INFOGRAPHICS = `${API_BASE_URL}/content/image`;
export const API_CONTENT_FLIPBOARD = `${API_BASE_URL}/content/flipboard`;
export const API_CONTENT_ARTICLE = `${API_BASE_URL}/content/image`;
export const API_CONTENT_EXTERNAL_LINK = `${API_BASE_URL}/content/externalurl`;
export const API_CONTENT_EXTERNAL_PDF_LINK = `${API_BASE_URL}/content/externalurl/pdf`;
export const API_CONTENT_QUIZ = `${API_BASE_URL}/content/quiz`;
export const API_CONTENT_QUIZ_QUESTION = `${API_BASE_URL}/content/quiz/question`;
export const API_CONTENT_QUIZ_QUESTION_CHOICE = `${API_BASE_URL}/content/quiz/question/choice`;
export const API_CONTENT_CHECKLIST = `${API_BASE_URL}/content/checklist`;
export const API_CONTENT_CHECKLIST_ITEM = `${API_BASE_URL}/content/checklist/item`;
export const API_CONTENT_SURVEY = `${API_BASE_URL}/content/survey`;
export const API_CONTENT_SURVEY_QUESTION = `${API_BASE_URL}/content/survey/question`;
export const API_CONTENT_SURVEY_QUESTION_CHOICE = `${API_BASE_URL}/content/survey/question/choice`;
export const API_CONTENT_SURVEY_BULK_IMPORT = `${API_BASE_URL}/content/survey/import`;
export const API_CONTENT_QUIZ_BULK_IMPORT = `${API_BASE_URL}/content/quiz/import`;
export const API_CONTENT_LIVE_EVENT = `${API_BASE_URL}/content/liveEvent`;
export const API_CONTENT_REMOTE_CLASS = `${API_BASE_URL}/content/meeting`;
export const API_CONTENT_CHAPTER = `${API_BASE_URL}/journey/chapter`;
export const API_CONTENT_BADI_BULK_IMPORT = `${API_BASE_URL}/journey/import_buddy_trigger_users`;

// Content update
export const API_UPDATE_CONTENT_VIDEO = `${API_BASE_URL}/content/video/update`;
export const API_UPDATE_CONTENT_VIDEO_CDN = `${API_BASE_URL}/content/externalcdnvideo/update`;
export const API_UPDATE_CONTENT_EXTERNAL_LINK = `${API_BASE_URL}/content/externalurl/update`;
export const API_UPDATE_CONTENT_EXTERNAL_PDF_LINK = `${API_BASE_URL}/content/externalurl/pdf/update`;
export const API_UPDATE_CONTENT_INFOGRAPHICS = `${API_BASE_URL}/content/image/update`;
export const API_UPDATE_CONTENT_ARTICLE = `${API_BASE_URL}/content/image/update`;
export const API_UPDATE_CONTENT_FLIPBOARD = `${API_BASE_URL}/content/flipboard/update`;
export const API_UPDATE_CONTENT_QUIZ = `${API_BASE_URL}/content/quiz/update`;
export const API_DELETE_CONTENT_QUIZ = `${API_BASE_URL}/content/quiz`;
export const API_UPDATE_CONTENT_SURVEY = `${API_BASE_URL}/content/survey/update`;
export const API_UPDATE_CONTENT_CHECKLIST = `${API_BASE_URL}/content/checklist/update`;
export const API_UPDATE_LIVE_EVENT = `${API_BASE_URL}/content/liveEvent/update`;
export const API_UPDATE_REMOTE_CLASS = `${API_BASE_URL}/content/meeting/update`;

// Journey
export const API_POST_JOURNEY = `${API_BASE_URL}/journey`;
export const API_POST_JOURNEY_V2_LIST = `${API_BASE_URL}/journey2/list`;
export const API_POST_JOURNEY_V2_CREATE = `${API_BASE_URL}/journey2/create`;
export const API_POST_JOURNEY_V2_UPDATE = `${API_BASE_URL}/journey2/update`;
export const API_POST_JOURNEY_V2_DELETE = `${API_BASE_URL}/journey2/delete`;
export const API_POST_JOURNEY_V2_DUPLICATE = `${API_BASE_URL}/journey2/clone`;
export const API_POST_JOURNEY_V2_CONTENT_DELETE = `${API_BASE_URL}/journey2/contents/remove`;
export const API_POST_JOURNEY_V2_CONTENT_ADD = `${API_BASE_URL}/journey2/contents/add`;
export const API_POST_JOURNEY_V2_CONTENT_UPDATE = `${API_BASE_URL}/journey2/contents/update`;


export const API_JOURNEY_ITEM = `${API_BASE_URL}/journey/item`;
export const API_JOURNEY_ITEM_ORDER = `${API_BASE_URL}/journey/item/order`;
export const API_JOURNEY_ITEMS_ALL = `${API_BASE_URL}/journey/item/all`;
export const API_JOURNEY_V2_ITEMS = `${API_BASE_URL}/journey2/contents/list`;
export const API_JOURNEY_V2_USERS = `${API_BASE_URL}/journey2/users/list`;
export const API_JOURNEY_V2_USERS_REMOVE = `${API_BASE_URL}/journey2/users/remove`;
export const API_JOURNEY_V2_USER_ASSIGMENT = `${API_BASE_URL}/journey2/assignment/calendar/list`;
export const API_JOURNEY_V2_USER_ASSIGMENT_ADD = `${API_BASE_URL}/journey2/assignment/calendar/add`;
export const API_JOURNEY_V2_USER_ASSIGMENT_DELETE = `${API_BASE_URL}/journey2/assignment/calendar/delete`;
export const API_JOURNEY_ITEMS_ALL_UPDATE = `${API_BASE_URL}/journey/item/all/update`;
export const API_JOURNEY_REMINDER = `${API_BASE_URL}/journey/reminder`;

export const API_JOURNEY_USER = `${API_BASE_URL}/journey/user`;
export const API_JOURNEY_USER_DATATABLES = `${API_BASE_URL}/journey/user/datatables`;
export const API_JOURNEY_USER_GROUPS = `${API_BASE_URL}/journey/usergroup`;
export const API_JOURNEY_DUPLICATE = `${API_BASE_URL}/journey/duplicate`;
export const API_JOURNEY_COPY = `${API_BASE_URL}/journey/copy`;
export const API_JOURNEY_UPDATE = `${API_BASE_URL}/journey/update`;
export const API_JOURNEY_GROUP = `${API_BASE_URL}/journeygroup`;
export const API_JOURNEY_GROUP_UPDATE = `${API_BASE_URL}/journeygroup/update`;
export const API_JOURNEY_GROUP_DATATABLE = `${API_BASE_URL}/journeygroup/datatables`;
export const API_JOURNEY_GROUP_JOURNEYS = `${API_BASE_URL}/journeygroup/journey`;
export const API_JOURNEY_ACTIVE = `${API_BASE_URL}/journey/datatables/active`;
export const API_JOURNEY_ALL = `${API_BASE_URL}/journey/datatables/all`;
export const API_JOURNEY_COMPLETED = `${API_BASE_URL}/journey/datatables/completed`;
export const API_JOURNEY_CATALOG = `${API_BASE_URL}/journey/datatables/catalog`;
export const API_JOURNEY_USER_ACTIVITY = `${API_BASE_URL}/reports/journey_user_activity_excel`;
export const API_CONTENT_FIND_JOURNEY = `${API_BASE_URL}/content/find/journeys`;

// Users
export const API_USER = `${API_BASE_URL}/user`;
export const API_USER_SET_PASSWORD = `${API_BASE_URL}/user/update/password`;
export const API_USER_USERGROUPS = `${API_BASE_URL}/user/usergroup`;
export const API_USER_JOURNEY_DATATABLES = `${API_BASE_URL}/user/journey/datatables`;
export const API_IMPORT_USER_FROM_EXCEL = `${API_USER}/import`;
export const API_DELETE_USER_FROM_EXCEL = `${API_USER}/excel/delete`;
export const API_USER_RELATIONSHIP = `${API_BASE_URL}/user/relationships`;
// Groups
export const API_USER_GROUP = `${API_BASE_URL}/usergroup`;
export const API_USER_GROUP_USER = `${API_BASE_URL}/usergroup/user`;
export const API_GET_GROUP_USERS = `${API_BASE_URL}/usergroup/user/datatables`;
export const API_USER_GROUP_JOURNEYS = `${API_BASE_URL}/usergroup/journey`;
export const API_USER_GROUP_JOURNEY_REPORT = `${API_BASE_URL}/reports/job/export/customer_user_journey_summary`;

// Customers
export const API_CUSTOMER = `${API_BASE_URL}/customer`;
export const API_CUSTOMER_UPDATE = `${API_BASE_URL}/customer/update`;
export const API_CUSTOMER_PREASSIGN_JOURNEY = `${API_BASE_URL}/customer/assign/journey`;
export const API_CUSTOMER_GET_PREASSIGN_JOURNEYS = `${API_BASE_URL}/customer/assign/journeys`;

// Notifications
export const API_JOURNEY_NOTIFICATION = `${API_BASE_URL}/journey/notification`;
export const API_JOURNEY_ITEM_NOTIFICATION = `${API_BASE_URL}/journey/journey_item/notification`;
export const API_USER_NOTIFICATION = `${API_BASE_URL}/user/notification`;
export const API_GROUP_NOTIFICATION = `${API_BASE_URL}/usergroup/notification`;

// Extensions
export const API_GET_TIMEZONES = `${API_BASE_URL}/timezones`;
export const API_GET_ROLES = `${API_BASE_URL}/roles`;
export const API_GET_LANGUAGES = `${API_BASE_URL}/languages`;

// Scheduled Notifications
export const API_JOURNEY_SCHEDULED_NOTIFICATION = `${API_BASE_URL}/journey/notification/scheduled`;
export const API_JOURNEY_SCHEDULED_NOTIFICATION_UPDATE = `${API_BASE_URL}/journey/notification/scheduled/all/update`;

// Reports
export const API_REPORTS_WEEKLY_CUSTOMER = `${API_BASE_URL}/reports/weekly/customer`;
export const API_REPORTS_WEEKLY_USER = `${API_BASE_URL}/reports/es/user/histogram`;
export const API_REPORTS_WEEKLY_USER_DATATABLE = `${API_BASE_URL}/reports/weekly/user/datatable`;
export const API_REPORTS_JOURNEY_USER_ACTIVITY = `${API_BASE_URL}/reports/journey_user_activity`;
export const API_REPORTS_CUSTOMER_DISENGAGE = `${API_BASE_URL}/reports/users/disengaged`;
export const API_REPORTS_CUSTOMER_ACTIVITY = `${API_BASE_URL}/reports/customer_activity_excel`;

export const API_REPORTS_CUSTOMER_INFO = `${API_BASE_URL}/reports/es/customer/info`;
export const API_REPORTS_CUSTOMER_HISTOGRAM = `${API_BASE_URL}/reports/es/customer/histogram`;
export const API_REPORTS_CUSTOMER_USAGE_SUMMARY = `${API_BASE_URL}/reports/es/customer/usage/summary`;
export const API_REPORTS_CUSTOMER_USAGE_SUMMARY_ALL = `${API_BASE_URL}/reports/es/customer/usage/summary/all`;
export const API_REPORTS_CONTENT_RATINGS_DATATABLE = `${API_BASE_URL}/reports/content/ratings/datatable`;
export const API_REPORTS_USERGROUP_ANALYTICS = `${API_BASE_URL}/reports/usergroup/analytics`;
export const API_REPORTS_CUSTOMER_SURVEY_RESULT = `${API_BASE_URL}/export/customer_survey_result_excel`;

export const API_REPORTS_GROUP_HISTOGRAM = `${API_BASE_URL}/reports/weekly/group/histogram`;
export const API_REPORTS_GROUP_USAGE_SUMMARY = `${API_BASE_URL}/reports/es/group/usage/summary`;
export const API_REPORTS_GROUP_RATINGS_DATATABLE = `${API_BASE_URL}/reports/content/ratings/group/datatable`;

// Meetings
export const API_GET_MEETING_DATATABLES = `${API_BASE_URL}/content/meeting/list`;
export const API_ZOOM_SIGNATURE = `${API_BASE_URL}/zoom/signature`;

// Image librarry
export const API_IMAGE_LIBRARY = `${API_BASE_URL}/library/image`;

// Flipbook editable
export const API_FLIPBOOK_EDITABLE = `${API_BASE_URL}/content/flipbookEditable`;

export const API_REPORTS_DEMAND = `${API_BASE_URL}/reports/demands`;
export const API_REPORTS_DOWNLOAD = `${API_BASE_URL}/reports/download`;
export const API_EXPORT_CUSTOMER_OVERVIEW = `${API_BASE_URL}/reports/export/customer_overview/`;
export const API_EXPORT_CUSTOMER_ACTIVITY = `${API_BASE_URL}/reports/job/export/customer_activity_excel`;
export const API_EXPORT_CUSTOMER_JOURNEY = `${API_BASE_URL}/reports/job/export/customer_user_journey_summary`;
export const API_EXPORT_DISENGAGED_USERS = `${API_BASE_URL}/reports/job/export/disengaged_users_excel`;
export const API_EXPORT_CUSTOMER_SURVEY = `${API_BASE_URL}/reports/job/export/customer_survey_result_excel`;
export const API_EXPORT_CUSTOMER_QUIZ = `${API_BASE_URL}/reports/job/export/customer_quiz_general_result_excel`;
export const API_EXPORT_GROUP_ACTIVITY = `${API_BASE_URL}/reports/job/export/customer_activity_excel`;
export const API_EXPORT_GROUP_JOURNEY = `${API_BASE_URL}/reports/job/export/customer_user_journey_summary`;

export const API_USER_ZOOM_LOGIN = `${API_BASE_URL}/login/zoom/user`;
export const API_ADD_PACKAGE_CODE = `${API_BASE_URL}/journey/packet`;
export const API_ADD_JOURNEY_TO_PACKAGE = `${API_BASE_URL}/journey/packet/journeys`;
export const API_JOB_JOURNEY_USER_ACTIVITY = `${API_BASE_URL}/reports/job/export/journey_user_activity_excel`;
export const API_USER_MANAGER_UNLINK = `${API_BASE_URL}/user/unlink_manager`;
export const API_USER_MANAGER_LINK = `${API_BASE_URL}/manager/employee/link`;

export const API_USER_DEVICE_STATUS = `${API_BASE_URL}/user/device`;
export const API_ED_DEMO_SETUP_DEV = `${API_BASE_URL}/useCase/development_plan`;
export const API_ED_DEMO_SETUP_ONBOARD = `${API_BASE_URL}/useCase/onboarding`;

export const API_QUIZ_COMPLETION_STATUS = `${API_BASE_URL}/reports/quiz_completion_status`;
export const API_QUIZ_COMPLETION_DATATABLE = `${API_BASE_URL}/reports/quiz_completion_status/datatables`;
export const API_USER_QUIZ_RESULT = `${API_BASE_URL}/reports/quiz_result_details`;
export const API_QUIZ_GENERAL_RESULT = `${API_BASE_URL}/reports/job/export/customer_quiz_general_result_excel`;
export const API_QUIZ_DETAIL_RESULT = `${API_BASE_URL}/reports/job/export/customer_quiz_details_result_excel`;
export const API_CONTENT_QUIZ_DELETE = `${API_BASE_URL}/content/quiz/duplicate?quiz_id=`;

export const API_SURVEY_COMPLETION_STATUS = `${API_BASE_URL}/reports/survey_completion_status`;
export const API_SURVEY_COMPLETION_DATATABLE = `${API_BASE_URL}/reports/survey_completion_status/datatables`;
export const API_USER_SURVEY_RESULT = `${API_BASE_URL}/reports/survey_result_details`;
export const API_SURVEY_RESULT_EXCEL = `${API_BASE_URL}/reports/job/export/survey_result_excel`;

export const API_CHECKLIST_COMPLETION_STATUS = `${API_BASE_URL}/reports/checklist_completion_status`;
export const API_CHECKLIST_COMPLETION_DATATABLE = `${API_BASE_URL}/reports/checklist_completion_status/datatables`;
export const API_USER_CHECKLIST_RESULT = `${API_BASE_URL}/reports/checklist_result_details`;
export const API_CHECKLIST_RESULT_EXCEL = `${API_BASE_URL}/reports/job/export/checklist_result_excel`;

export const API_GET_MANAGERS = `${API_BASE_URL}/manager/datatables`;
export const API_GET_BUDDIES = `${API_BASE_URL}/customer/user/buddy/assignment/status`;
export const API_GET_MANAGER_DEV_REPORT = `${API_BASE_URL}/reports/dev_planning/manager`;
export const API_START_ONBARDING_TEAM = `${API_BASE_URL}/journey/start_onboarding_for_a_team`;
export const API_SURVEY_ORDER_UPDATE = `${API_BASE_URL}/content/survey/questions/reorder`;
export const API_ENGAGEMENT_SURVEY_RESULT = `${API_BASE_URL}/content/survey/engagement/user/result`;
export const API_ENGAGEMENT_SURVEY_EVALUATE = `${API_BASE_URL}/content/survey/engagement/evaluate`;

export const API_FILE_DOWNLOAD_ADD = `${API_BASE_URL}/content/file_download/add`;
export const API_FILE_DOWNLOAD_UPDATE = `${API_BASE_URL}/content/file_download/update`;
export const API_FILE_DOWNLOAD_ADD_FILE = `${API_BASE_URL}/content/file_download/add/file`;
export const API_FILE_DOWNLOAD_FILE_UPDATE = `${API_BASE_URL}/content/file_download/update/file`;
export const API_FILE_DOWNLOAD_FILE_DELETE = `${API_BASE_URL}/content/file_download/delete/file`;

export const API_PINS = `${API_BASE_URL}/sticky_pin`;
export const API_PINS_LIST = `${API_BASE_URL}/sticky_pin/list`;

export const API_CONTENT_REQUEST = `${API_BASE_URL}/content/request`;
export const API_UPDATE_CONTENT_REQUEST = `${API_BASE_URL}/content/request/update`;
export const API_CONTENT_REQUEST_QUESTION = `${API_BASE_URL}/content/request/question`;
export const API_REQUEST_ORDER_UPDATE = `${API_BASE_URL}/content/request/questions/reorder`;

export const API_REQUEST_COMPLETION_STATUS = `${API_BASE_URL}/reports/request_completion_status`;
export const API_REQUEST_COMPLETION_DATATABLE = `${API_BASE_URL}/reports/request_completion_status/datatables`;
export const API_USER_REQUEST_RESULT = `${API_BASE_URL}/reports/request_result_details`;
export const API_JOURNEY_REPORT = `${API_BASE_URL}/journey/export`;

export const API_REPORT_JOURNEYS = `${API_BASE_URL}/reports/v2/summary/journeys`;
export const API_REPORT_JOURNEY_USERS = `${API_BASE_URL}/reports/v2/journey/users`;
export const API_REPORT_JOURNEY_CONTENTS = `${API_BASE_URL}/reports/v2/journey/contents`;
export const API_REPORT_JOURNEY_USER_JOURNEYS = `${API_BASE_URL}/reports/v2/user/journeys`;
export const API_REPORT_JOURNEY_USER_JOURNEY_CONTENTS = `${API_BASE_URL}/reports/v2/user/journey/contents`;
export const API_REPORT_USER_LIST_DOWNLOAD = `${API_BASE_URL}/reports/job/export/customer_user_list_export`;
export const API_PDF_REPORT_SURVEY = `${API_BASE_URL}/content/survey/pdf`;
export const API_IMPORT_LXP_RECOMEND_FROM_EXCEL = `${API_BASE_URL}/lxp/recommendations/import`;

export const API_CONTENT_SCORM = `${API_BASE_URL}/content/scorm`;
export const API_RESET_KOLAYIK_TOKEN = `${API_BASE_URL}/customer/reset_kolayik_token`;

export const API_JOURNEY_PROGRESS_REPORT = `${API_BASE_URL}/reports/job/export/report_customer_user_journey_summary`;
export const API_COMMUNICATION = `${API_BASE_URL}/cm`;
export const API_JOURNEY_FILTERED = `${API_BASE_URL}/journey/datatables/filter`;
export const API_DASHBOARD_IMPRESSIONS = `${API_BASE_URL}/customer/impression/counts`;
export const API_LXP_REPORT = `${API_BASE_URL}/lxp/report/customer/users/content`;
export const API_SHOW_NPS_SCRORE = `${API_BASE_URL}/content/survey/nps/score/`;
