var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "site-header" },
    [
      _c("div", { staticClass: "site-header__primary" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-2" }, [
              _c(
                "div",
                { staticClass: "site-header__actions" },
                [
                  _c(
                    "router-link",
                    { staticClass: "logo", attrs: { to: "/" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/journey-logo.svg"),
                          alt: "Journey",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "search",
                      staticStyle: { display: "none !important" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 30,
                                height: 30,
                                name: "search",
                                color: "#fff",
                              },
                            },
                            [_c("icon-search")],
                            1
                          ),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("search_placeholder"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _c("app-navigation", { attrs: { type: "primary" } }, [
                  _c("ul", { staticClass: "navigation__list" }, [
                    _vm.isVibonsAdmin
                      ? _c(
                          "li",
                          { staticClass: "navigation__item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "navigation__link",
                                attrs: { to: "/customers" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "icon" },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          name: "cutomers",
                                          "view-box": "0 0 24 24",
                                          "is-icon-class": false,
                                        },
                                      },
                                      [_c("icon-customers")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("customers").toUpperCase()) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      { staticClass: "navigation__item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navigation__link",
                            attrs: { to: "/" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "journey",
                                      "view-box": "0 0 13 13",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-journey")],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" " + _vm._s(_vm.$t("journeys")) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.canSeeJourneyV2
                      ? _c(
                          "li",
                          { staticClass: "navigation__item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "navigation__link",
                                attrs: { to: "/journey_v2" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "icon" },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          name: "journey",
                                          "view-box": "0 0 13 13",
                                          "is-icon-class": false,
                                        },
                                      },
                                      [_c("icon-journey")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("journeys_v2")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      { staticClass: "navigation__item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navigation__link",
                            attrs: { to: "/contents" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "content",
                                      "view-box": "0 0 15 15",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-content")],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" " + _vm._s(_vm.$t("contents")) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.showCommunication
                      ? _c(
                          "li",
                          { staticClass: "navigation__item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "navigation__link",
                                attrs: { to: "/communication" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "icon" },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          name: "notification",
                                          width: 23,
                                          height: 23,
                                          "view-box": "0 0 24 24",
                                          "is-icon-class": false,
                                        },
                                      },
                                      [_c("icon-communication")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("communication")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      { staticClass: "navigation__item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navigation__link",
                            attrs: { to: "/users" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "user",
                                      "view-box": "0 0 12 14",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-user")],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" " + _vm._s(_vm.$t("users")) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "navigation__item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navigation__link",
                            attrs: { to: "/dashboard" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "content",
                                      "view-box": "0 0 18 17",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-dashboard")],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" " + _vm._s(_vm.$t("dashboard")) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "col-2" }, [
              _c(
                "ul",
                {
                  staticClass:
                    "u-list-unstyled site-header__actions justify-content-end",
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "site-header__actions-item",
                      staticStyle: { display: "none" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "site-header__actions-button",
                          attrs: { type: "button" },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 22,
                                height: 24,
                                name: "notification",
                                "view-box": "0 0 22 24",
                              },
                            },
                            [_c("icon-notification")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "site-header__actions-item dropdown",
                      on: {
                        click: function ($event) {
                          return _vm.dropdownMenu("profileDropdown")
                        },
                        mouseover: function ($event) {
                          $event.preventDefault()
                          return _vm.dropdownMenu("profileDropdown")
                        },
                        mouseleave: _vm.hideDropdown,
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "site-header__actions-button has-arrow",
                          attrs: { type: "button" },
                        },
                        [
                          _vm.userData
                            ? _c("div", { staticClass: "media-object" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "h5 media-object__text u-text-uppercase",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.userData.first_name) +
                                        " " +
                                        _vm._s(_vm.userData.last_name) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm.isDropdown === "profileDropdown"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "dropdown__menu dropdown__menu--profile",
                            },
                            [
                              _vm.isVibonsAdmin
                                ? _c(
                                    "span",
                                    { staticClass: "dropdown__button button" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("switch_customer")) +
                                          " "
                                      ),
                                      _c("switch-customer"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isVibonsAdmin
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.setupEdDemo.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("setup_ed_demo")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showLxpRecomendation
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.importLxpRecomendation.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("import_lxp_recomend")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isVibonsAdmin
                                ? _c(
                                    "span",
                                    { staticClass: "dropdown__button button" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("switch_language")) +
                                          " "
                                      ),
                                      _c("switch-language"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "dropdown__button button",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.gotoHelpCenter.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        fill: "none",
                                        width: "32",
                                        height: "32",
                                        viewBox: "0 0 32 32",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "#fff",
                                          "fill-rule": "evenodd",
                                          stroke: "#727D92",
                                          "stroke-opacity": ".2",
                                          d: "M16 31c8.2843 0 15-6.7157 15-15 0-8.28427-6.7157-15-15-15C7.71573 1 1 7.71573 1 16c0 8.2843 6.71573 15 15 15z",
                                          "clip-rule": "evenodd",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          fill: "url(#paint0_linear)",
                                          "fill-rule": "evenodd",
                                          d: "M15.9685 21.4917c-1.1582 0-2.246-.3443-3.1451-.9952-.1282-.0923-.2805-.1404-.434-.1404a.73787.73787 0 00-.249.0432l-.9039.3224.3314-.8789c.0858-.2283.0539-.485-.086-.6853-.6557-.9366-1.0023-2.0272-1.0023-3.1547 0-3.0456 2.4621-5.5233 5.4889-5.5233 3.026 0 5.4883 2.4777 5.4883 5.5233 0 3.0266-2.4623 5.4889-5.4883 5.4889zm0-12.49161c-3.8423 0-6.96852 3.14151-6.96852 7.00271 0 1.3005.36157 2.5613 1.05032 3.6709l-.76225 2.0213c-.10135.2699-.03728.5739.1648.7795.20209.2057.50568.2751.77655.1779l2.0557-.7338c1.0841.6899 2.349 1.0527 3.6834 1.0527 3.8422 0 6.9679-3.1262 6.9679-6.9685 0-3.8612-3.1257-7.00271-6.9679-7.00271z",
                                          "clip-rule": "evenodd",
                                        },
                                      }),
                                      _c(
                                        "defs",
                                        [
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id: "paint0_linear",
                                                x1: "22.9364",
                                                x2: "8.99998",
                                                y1: "9.00009",
                                                y2: "9.00009",
                                                gradientUnits: "userSpaceOnUse",
                                              },
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#F27299",
                                                },
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#254FFB",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(_vm._s(_vm.$t("help_center")) + " "),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "dropdown__button button",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.logOut.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "32",
                                        height: "32",
                                        viewBox: "0 0 32 32",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          "clip-rule": "evenodd",
                                          d: "M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z",
                                          fill: "white",
                                          stroke: "#727D92",
                                          "stroke-opacity": "0.2",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          "clip-rule": "evenodd",
                                          d: "M21.1236 16.3506C21.1297 16.3397 21.1359 16.3287 21.1414 16.3175C21.1465 16.307 21.1533 16.2975 21.1578 16.2867C21.1586 16.2848 21.1593 16.2829 21.1601 16.2812C21.1673 16.2637 21.1709 16.2452 21.1764 16.2275C21.1809 16.2131 21.1861 16.1992 21.1894 16.1845C21.1908 16.1789 21.1934 16.1735 21.1946 16.1678C21.1949 16.1664 21.195 16.165 21.1953 16.1637C21.1995 16.1429 21.1998 16.1217 21.2018 16.1005C21.2036 16.0812 21.2073 16.0621 21.2074 16.0426C21.2074 16.0184 21.2036 15.9943 21.2009 15.9702C21.1992 15.954 21.1993 15.9376 21.1963 15.9215C21.1959 15.9192 21.1954 15.917 21.1949 15.9146C21.1917 15.8993 21.186 15.8852 21.1818 15.8703C21.179 15.8604 21.1763 15.8508 21.173 15.841C21.1691 15.8288 21.167 15.816 21.162 15.8038C21.161 15.8012 21.1598 15.7986 21.1588 15.7959C21.151 15.7775 21.1403 15.7609 21.131 15.7433C21.1279 15.7373 21.1249 15.7312 21.1215 15.7252C21.1154 15.7146 21.1111 15.7032 21.1045 15.6928C21.1032 15.6907 21.1017 15.6887 21.1003 15.6867C21.0838 15.662 21.0639 15.64 21.0443 15.618C21.0372 15.61 21.0321 15.601 21.0245 15.5934C21.024 15.5929 21.0233 15.5925 21.0228 15.592C21.0222 15.5912 21.0215 15.5903 21.0207 15.5897L19.0093 13.5783C18.7606 13.3295 18.358 13.3295 18.1092 13.5783C17.861 13.8259 17.861 14.2295 18.1092 14.4773L19.0359 15.4038H15.2455C14.8943 15.4038 14.6094 15.6883 14.6094 16.0398C14.6094 16.3914 14.8943 16.6758 15.2455 16.6758H19.0191L18.1129 17.569C17.8626 17.8155 17.8596 18.2181 18.1062 18.4689C18.2305 18.5953 18.3951 18.6586 18.5593 18.6586C18.7204 18.6586 18.882 18.5974 19.0058 18.4761L21.0178 16.4935C21.0189 16.4924 21.0195 16.4912 21.0206 16.4902C21.0366 16.4742 21.0495 16.4558 21.0638 16.4382C21.0765 16.4224 21.0909 16.4078 21.1018 16.3911C21.1105 16.3782 21.116 16.3641 21.1236 16.3506Z",
                                          fill: "url(#logOut0_linear)",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          "clip-rule": "evenodd",
                                          d: "M18.8554 19.7829C18.5043 19.7829 18.2194 20.0672 18.2194 20.4189C18.2194 21.0957 17.6552 21.6472 16.9615 21.6472H13.5294C12.8364 21.6472 12.2722 21.0957 12.2722 20.4189V11.6313C12.2722 10.9543 12.8364 10.4031 13.5294 10.4031H16.9615C17.6552 10.4031 18.2194 10.9543 18.2194 11.6313C18.2194 11.983 18.5043 12.2675 18.8554 12.2675C19.2066 12.2675 19.4915 11.983 19.4915 11.6313C19.4915 10.2521 18.3565 9.13094 16.9615 9.13094H13.5294C12.1345 9.13094 11 10.2521 11 11.6313V20.4189C11 21.7979 12.1345 22.9192 13.5294 22.9192H16.9615C18.3565 22.9192 19.4915 21.7979 19.4915 20.4189C19.4915 20.0672 19.2066 19.7829 18.8554 19.7829Z",
                                          fill: "url(#logOut1_linear)",
                                        },
                                      }),
                                      _c(
                                        "defs",
                                        [
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id: "logOut0_linear",
                                                x1: "21.2074",
                                                y1: "13.3917",
                                                x2: "14.6094",
                                                y2: "13.3917",
                                                gradientUnits: "userSpaceOnUse",
                                              },
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#F27299",
                                                },
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#254FFB",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id: "logOut1_linear",
                                                x1: "19.4915",
                                                y1: "9.13094",
                                                x2: "11",
                                                y2: "9.13094",
                                                gradientUnits: "userSpaceOnUse",
                                              },
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#F27299",
                                                },
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#254FFB",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.$t("logout")) + " "),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "app-navigation",
        { staticClass: "child-header", attrs: { type: "secondary" } },
        [
          _c("div", { class: _vm.childHeaderClass }, [
            _c("div", { staticClass: "row sub-header" }, [
              _c("div", { staticClass: "sub-header__left" }, [
                _vm.childTitle !== null || _vm.isChildHeaderLeftActions
                  ? _c("div", { staticClass: "col-3 child-header__left" }, [
                      _c("div", { staticClass: "site-header__actions" }, [
                        _vm.childTitle !== null
                          ? _c("div", { staticClass: "form-group u-mB-0" }, [
                              _c(
                                "div",
                                { staticClass: "form-title text-nowrap" },
                                [_vm._v(" " + _vm._s(_vm.childTitle) + " ")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.isChildHeaderLeftActions
                          ? _c(
                              "div",
                              { staticClass: "d-flex" },
                              [_vm._t("default")],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "sub-header__center" }, [
                _vm.childHeaderNavigation.length > 0
                  ? _c("div", [
                      _c(
                        "ul",
                        { staticClass: "navigation__list" },
                        _vm._l(_vm.childHeaderNavigation, function (item) {
                          return _c(
                            "li",
                            { key: item.id, staticClass: "navigation__item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "navigation__link",
                                  class: [
                                    _vm.isCurrentPageIsActive(item.text)
                                      ? "router-link-exact-active router-link-active"
                                      : "",
                                    "navigation__link",
                                  ],
                                  attrs: {
                                    to: { path: item.url, query: item.query },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.text) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [_vm._t("center")],
                  2
                ),
              ]),
              _c("div", { staticClass: "sub-header__right" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [_vm._t("right")],
                  2
                ),
                _vm.childHeaderRightContent.length > 0 ||
                _vm.isChildHeaderSort ||
                _vm.addNewContentStatus ||
                _vm.startTheJourney ||
                _vm.assignTheJourney ||
                (_vm.totalUsers !== null && _vm.totalUsers !== undefined) ||
                _vm.addUser ||
                _vm.addBulkBadi ||
                _vm.addJourneyUser ||
                _vm.addGroup ||
                _vm.addPin ||
                _vm.addJourneygroup ||
                _vm.addCustomer
                  ? _c("div", { staticClass: "d-flex align-items-center" }, [
                      _vm.childHeaderRightContent.length > 0
                        ? _c(
                            "div",
                            { staticClass: "button-group justify-content-end" },
                            _vm._l(
                              _vm.childHeaderRightContent,
                              function (item, index) {
                                return _c(
                                  "a",
                                  {
                                    key: index,
                                    class: item.class,
                                    attrs: { href: item.href },
                                  },
                                  [_vm._v(" " + _vm._s(item.text) + " ")]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm.isChildHeaderSort
                        ? _c(
                            "div",
                            { staticClass: "form-group u-mB-0 max-width-sm" },
                            [
                              _c("multi-select", {
                                staticClass: "selectbox selectbox--secondary",
                                attrs: {
                                  "clear-on-select": "",
                                  "deselect-label": "",
                                  "select-label": "",
                                  options: _vm.selectOptions,
                                },
                                model: {
                                  value: _vm.selectValue,
                                  callback: function ($$v) {
                                    _vm.selectValue = $$v
                                  },
                                  expression: "selectValue",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.totalUsers !== null && _vm.totalUsers !== undefined
                        ? _c(
                            "div",
                            {
                              staticClass: "total-user",
                              on: { click: _vm.showJourneyItemUsers },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "32",
                                    height: "32",
                                    viewBox: "0 0 32 32",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z",
                                      fill: "white",
                                      stroke: "#727D92",
                                      "stroke-opacity": "0.2",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M16.112 10.39C17.5749 10.39 18.765 11.5801 18.765 13.043C18.765 14.5057 17.5749 15.6958 16.112 15.6958C14.6491 15.6958 13.459 14.5057 13.459 13.043C13.459 11.5801 14.6491 10.39 16.112 10.39ZM16.112 16.9562C18.2697 16.9562 20.0252 15.2005 20.0252 13.043C20.0252 10.8853 18.2697 9.12958 16.112 9.12958C13.9543 9.12958 12.1987 10.8853 12.1987 13.043C12.1987 15.2005 13.9543 16.9562 16.112 16.9562Z",
                                      fill: "url(#userAll0_linear)",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M22.1723 21.9096C21.1295 19.4884 18.7507 17.9236 16.112 17.9236C13.4726 17.9236 11.0939 19.4884 10.0517 21.9096C9.91386 22.2298 10.0612 22.6004 10.3809 22.7378C10.4622 22.7731 10.547 22.7893 10.6298 22.7893C10.8742 22.7893 11.1065 22.6468 11.209 22.4086C12.0522 20.4499 13.9771 19.184 16.112 19.184C18.2468 19.184 20.1712 20.4499 21.015 22.4086C21.1522 22.7277 21.5219 22.8786 21.843 22.7378C22.1627 22.6004 22.3101 22.2293 22.1723 21.9096Z",
                                      fill: "url(#userAll1_linear)",
                                    },
                                  }),
                                  _c(
                                    "defs",
                                    [
                                      _c(
                                        "linearGradient",
                                        {
                                          attrs: {
                                            id: "userAll0_linear",
                                            x1: "20.0252",
                                            y1: "9.12958",
                                            x2: "12.1987",
                                            y2: "9.12958",
                                            gradientUnits: "userSpaceOnUse",
                                          },
                                        },
                                        [
                                          _c("stop", {
                                            attrs: { "stop-color": "#F27299" },
                                          }),
                                          _c("stop", {
                                            attrs: {
                                              offset: "1",
                                              "stop-color": "#254FFB",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "linearGradient",
                                        {
                                          attrs: {
                                            id: "userAll1_linear",
                                            x1: "22.2239",
                                            y1: "17.9236",
                                            x2: "10",
                                            y2: "17.9236",
                                            gradientUnits: "userSpaceOnUse",
                                          },
                                        },
                                        [
                                          _c("stop", {
                                            attrs: { "stop-color": "#F27299" },
                                          }),
                                          _c("stop", {
                                            attrs: {
                                              offset: "1",
                                              "stop-color": "#254FFB",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("total_user")) +
                                    " " +
                                    _vm._s(_vm.totalUsers)
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.journeyDetail?.isJourneyTypeV2
                        ? _c("div", { staticClass: "journey-edit-icon" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "icon-setting button--shadow align-items-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.editJourney()
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "setting",
                                      width: 20,
                                      height: 20,
                                      "view-box": "0 0 12 12",
                                      color: "#727D92",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-setting")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.startTheJourney || _vm.assignTheJourney
                        ? _c("div", { staticClass: "journey-edit-icon" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "icon-setting button--shadow align-items-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.rescheduleJourney()
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "setting",
                                      width: 20,
                                      height: 20,
                                      "view-box": "0 0 12 12",
                                      color: "#727D92",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-setting")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.startTheJourney
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              class: {
                                "button--disabled": _vm.totalJourney === 0,
                              },
                              attrs: { disabled: _vm.totalJourney === 0 },
                              on: {
                                click: function ($event) {
                                  return _vm.startTheJourneyEvent()
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "playfull",
                                    "view-box": "0 0 448 512",
                                    color: "#FFF",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-playfull")],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("start_journey")) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addBulkBadi
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.bulkImport("badi")
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "upload",
                                    "view-box": "0 0 334 334",
                                    height: "24",
                                    width: "24",
                                    color: "#ffffff",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-upload")],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("add_bulk_badi")) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addNewContentStatus
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.addNewContent()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "icon-svg",
                                  attrs: {
                                    fill: "none",
                                    viewBox: "0 0 14 14",
                                    width: "16",
                                    height: "16",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "#fff",
                                      "fill-rule": "evenodd",
                                      d: "M6.845 12.48A5.59 5.59 0 011.26 6.896c0-3.063 2.506-5.555 5.585-5.555 3.08 0 5.584 2.492 5.584 5.555a5.59 5.59 0 01-5.584 5.584zm0-12.399C3.071.081 0 3.14 0 6.896a6.853 6.853 0 006.845 6.845 6.853 6.853 0 006.845-6.845c0-3.757-3.071-6.815-6.845-6.815z",
                                      "clip-rule": "evenodd",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      fill: "#fff",
                                      "fill-rule": "evenodd",
                                      d: "M9.073 6.266H7.475V4.668a.63.63 0 00-1.26 0v1.598H4.588a.63.63 0 000 1.26h1.627v1.627a.63.63 0 001.26 0V7.526h1.598a.63.63 0 000-1.26z",
                                      "clip-rule": "evenodd",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("add_new_content")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.addNewContentStatus
                        ? _c(
                            "div",
                            { staticClass: "page-settings--menu" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary dropdown-toggle",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      $event.preventDefault()
                                      _vm.showBulkImport = true
                                    },
                                    mouseleave: _vm.closeBulkImport,
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "23",
                                        height: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            fill: "#727D92",
                                            "fill-rule": "evenodd",
                                          },
                                        },
                                        [
                                          _c("circle", {
                                            attrs: { cx: "3", cy: "3", r: "3" },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cx: "11.4",
                                              cy: "3",
                                              r: "3",
                                            },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cx: "19.8",
                                              cy: "3",
                                              r: "3",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "transition",
                                { attrs: { name: "dropdown" } },
                                [
                                  _vm.showBulkImport
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "page-settings__dropdown-menu",
                                          class: { active: _vm.showBulkImport },
                                          on: {
                                            mouseleave: function ($event) {
                                              _vm.showBulkImport = false
                                            },
                                            mouseover: function ($event) {
                                              $event.preventDefault()
                                              return _vm.holdBulkMenu.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "page-settings__dropdown-menu-nav",
                                            },
                                            [
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "page-settings__dropdown-menu-item",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "page-settings__dropdown-menu-link",
                                                      attrs: {
                                                        href: "#",
                                                        title:
                                                          "Import survey in bulk from file",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.bulkImport(
                                                            "survey"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "import_survey"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "page-settings__dropdown-menu-item",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "page-settings__dropdown-menu-link",
                                                      attrs: {
                                                        href: "#",
                                                        title:
                                                          "Import quiz in bulk from file",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.bulkImport(
                                                            "quiz"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "import_quiz"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addJourneygroup
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.addJourneygroupModal()
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "plusfull",
                                    "view-box": "0 0 448 512",
                                    width: 15,
                                    height: 16,
                                    color: "#FFF",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-plusfull")],
                                1
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("add_new_journey_group")) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addCustomer
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.addCustomerModal()
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "plusfull",
                                    "view-box": "0 0 448 512",
                                    width: 15,
                                    height: 16,
                                    color: "#FFF",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-plusfull")],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("add_new_customer")) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addUser
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.addUserModal()
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "plusfull",
                                    "view-box": "0 0 448 512",
                                    width: 15,
                                    height: 16,
                                    color: "#FFF",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-plusfull")],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("add_new_user")) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addJourneyUser
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.addJourneyUserModal()
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "plusfull",
                                    "view-box": "0 0 448 512",
                                    width: 15,
                                    height: 16,
                                    color: "#FFF",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-plusfull")],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("add_new_user")) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addUser
                        ? _c(
                            "div",
                            { staticClass: "page-settings--menu" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary dropdown-toggle",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showUserSettings =
                                        !_vm.showUserSettings
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "23",
                                        height: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            fill: "#727D92",
                                            "fill-rule": "evenodd",
                                          },
                                        },
                                        [
                                          _c("circle", {
                                            attrs: { cx: "3", cy: "3", r: "3" },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cx: "11.4",
                                              cy: "3",
                                              r: "3",
                                            },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cx: "19.8",
                                              cy: "3",
                                              r: "3",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "transition",
                                { attrs: { name: "dropdown" } },
                                [
                                  _vm.showUserSettings
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "page-settings__dropdown-menu",
                                          class: {
                                            active: _vm.showUserSettings,
                                          },
                                        },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "page-settings__dropdown-menu-nav",
                                            },
                                            [
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "page-settings__dropdown-menu-item",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "page-settings__dropdown-menu-link",
                                                      attrs: {
                                                        href: "#",
                                                        title: _vm.$t(
                                                          "export_to_excel_new"
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.importUserModal(
                                                            "Add"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "export_to_excel_new"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "page-settings__dropdown-menu-item",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "page-settings__dropdown-menu-link",
                                                      attrs: {
                                                        href: "#",
                                                        title: _vm.$t(
                                                          "export_users_to_excell_new"
                                                        ),
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.downloadReport,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "export_users_to_excell_new"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "page-settings__dropdown-menu-item",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "page-settings__dropdown-menu-link",
                                                      attrs: {
                                                        href: "#",
                                                        title: _vm.$t(
                                                          "import_excell_delete_new"
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.importUserModal(
                                                            "Delete"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "import_excell_delete_new"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addGroup
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.addGroupModal()
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "plusfull",
                                    "view-box": "0 0 448 512",
                                    width: 15,
                                    height: 16,
                                    color: "#FFF",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-plusfull")],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("add_new_group")) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.addPin
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button button--secondary text-size-xs size-sm button--icon",
                              on: {
                                click: function ($event) {
                                  return _vm.addPinModal()
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "plusfull",
                                    "view-box": "0 0 448 512",
                                    width: 15,
                                    height: 16,
                                    color: "#FFF",
                                    "is-icon-class": true,
                                  },
                                },
                                [_c("icon-plusfull")],
                                1
                              ),
                              _vm._v(" " + _vm._s(_vm.$t("add_new_pin")) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }