var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.modal.modalVisible && _vm.modal.modalType === "modalNpsScore"
            ? _c("modal-nps-score")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalReportDownload"
            ? _c("modal-report-download")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalUploadImageContent"
            ? _c("modal-upload-imagecontent")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyUserJourneyInside"
            ? _c("modal-journey-user_journey_inside")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalAddJourneyToJourneyGroup"
            ? _c("modal-add-journey-to-journey-group")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalSetupEdDemo"
            ? _c("modal-ed-demo")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalPackageCode"
            ? _c("modal-package-code")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalFlipbookEditable"
            ? _c("modal-create-flipbookeditable")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalReportNotification"
            ? _c("modal-report-notificaiton")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyAddUserOverlay"
            ? _c("modal-journey-add-user")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyV2AddUserOverlay"
            ? _c("modal-journey-v2-add-user")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalCommunicationAddUserOverlay"
            ? _c("modal-communication-add-user")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalCreateContent"
            ? _c("modal-template")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalCreateContentV2"
            ? _c("modal-v2-template")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalQuizDetail"
            ? _c("modal-quiz-detail")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalRequestDetail"
            ? _c("modal-request-detail")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalChecklistDetail"
            ? _c("modal-checklist-detail")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalSurveyDetail"
            ? _c("modal-survey-detail")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalJourney"
            ? _c("modal-journey")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalJourneyV2"
            ? _c("modal-journey-v2")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalSelectJourney"
            ? _c("modal-select-journey")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalAssign"
            ? _c("modal-assign")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalOnboardingAssign"
            ? _c("modal-onboarding-assign")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalNotification"
            ? _c("modal-notification")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalAddUser"
            ? _c("modal-user-add")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalAddPin"
            ? _c("modal-pin-add")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyItemUsers"
            ? _c("modal-journeyitem-users")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyItemUsersV2"
            ? _c("modal-journeyitem-users-v2")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalCommunicaitonItemUsers"
            ? _c("modal-communicationitem-users")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalAddCustomer"
            ? _c("modal-customer-add")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalAddCommunication"
            ? _c("modal-communication-add")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalImportUser"
            ? _c("modal-user-import")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalLxpRecommend"
            ? _c("modal-lxp-recommend")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalSQBulkImport"
            ? _c("modal-surveyquiz-import")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyAddUser"
            ? _c("modal-journey-add-user")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyAddGroup"
            ? _c("modal-journey-add-group")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalAddContentToJourney"
            ? _c("modal-add-content-to-journey")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalPreviewContent"
            ? _c("modal-contentpreview")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalDateTime"
            ? _c("modal-date-time")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalDateTimeV2"
            ? _c("modal-date-time-v2")
            : _vm._e(),
        ],
        1
      ),
      _c("vue-snotify"),
      _vm.$store.state.modal.multiProcessProgressBar > 0
        ? _c("div", { staticClass: "progressbar-container" }, [
            _c("div", { staticClass: "progressbar-dialog" }, [
              _c("div", { staticClass: "progressbar-content" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("please_wait")))]),
                _vm._m(0),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.$store.state.modal.progressBarValue >= 0
        ? _c("div", { staticClass: "progressbar-container" }, [
            _c("div", { staticClass: "progressbar-dialog" }, [
              _c(
                "div",
                { staticClass: "progressbar-content" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("please_wait")))]),
                  _c("progress-bar", {
                    attrs: {
                      "bar-color": "#4a4",
                      size: "large",
                      val: _vm.$store.state.modal.progressBarValue,
                      text: _vm.$store.state.modal.progressBarValue + "%",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("transition", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "multiprocessbox" }, [
      _c("div", { staticClass: "multiprocessprogressbar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }