var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isNextPage,
                      expression: "isNextPage",
                    },
                  ],
                  staticClass: "leftbutton",
                  on: { click: _vm.goBack },
                },
                [
                  _c(
                    "icon-container",
                    {
                      staticClass: "prev",
                      attrs: {
                        width: "12",
                        height: "20",
                        "view-box": "0 0 320 512",
                        name: "cplus",
                        color: "#727d92",
                      },
                    },
                    [_c("icon-left")],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(" " + _vm._s(_vm.pageTitle) + " "),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isNextPage,
                        expression: "isNextPage",
                      },
                    ],
                    staticClass: "swicth-checkbox",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isAddingQuestion,
                          expression: "isAddingQuestion",
                        },
                      ],
                      attrs: {
                        tabindex: "-1",
                        type: "checkbox",
                        id: "isAddingQuestion",
                        "true-value": 1,
                        "false-value": 0,
                      },
                      domProps: {
                        checked: Array.isArray(_vm.isAddingQuestion)
                          ? _vm._i(_vm.isAddingQuestion, null) > -1
                          : _vm._q(_vm.isAddingQuestion, 1),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.isAddingQuestion,
                            $$el = $event.target,
                            $$c = $$el.checked ? 1 : 0
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.isAddingQuestion = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isAddingQuestion = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isAddingQuestion = $$c
                          }
                        },
                      },
                    }),
                    _c("label", {
                      staticClass: "swicth-label",
                      attrs: { for: "isAddingQuestion" },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "rightbutton" }, [
                _c("button", {
                  staticClass: "close",
                  attrs: { type: "button" },
                  domProps: { innerHTML: _vm._s("&times;") },
                  on: { click: _vm.closeModal },
                }),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "swipe", attrs: { id: "mySwipe" } }, [
                _c("div", { staticClass: "swipe-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("image-library"),
                      _c("div", { staticClass: "flipcontent" }, [
                        _c("input", {
                          ref: "uploadRef",
                          staticClass: "hidden-fileinput",
                          attrs: {
                            tabindex: "-1",
                            type: "file",
                            name: "image",
                            multiple: "",
                          },
                          on: {
                            change: function ($event) {
                              $event.stopPropagation()
                              return _vm.onUpload.apply(null, arguments)
                            },
                          },
                        }),
                        _c(
                          "main",
                          {
                            staticClass: "flip-main",
                            attrs: { id: "scrollbar-custom" },
                          },
                          [
                            _c(
                              "draggable-comp",
                              _vm._b(
                                {
                                  attrs: {
                                    draggable: ".draggable",
                                    handle: ".flipbox",
                                    delay: "5",
                                  },
                                  on: {
                                    start: _vm.startDragging,
                                    end: _vm.endDragging,
                                  },
                                  model: {
                                    value: _vm.flipsData,
                                    callback: function ($$v) {
                                      _vm.flipsData = $$v
                                    },
                                    expression: "flipsData",
                                  },
                                },
                                "draggable-comp",
                                _vm.dragOptions,
                                false
                              ),
                              [
                                _c(
                                  "transition-group",
                                  {
                                    class: "row",
                                    attrs: {
                                      appear: "",
                                      type: "transition",
                                      name: "slide-in",
                                      tag: "div",
                                      id: "flipitemslist",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.flipsData,
                                      function (flip, index) {
                                        return [
                                          flip.type === "cover"
                                            ? [
                                                _c("flip-cover", {
                                                  key: index,
                                                  attrs: {
                                                    findex: index,
                                                    total: _vm.totalPages,
                                                    datam:
                                                      _vm.flipsData[index]
                                                        .datam,
                                                    finalize: _vm.finalize,
                                                    lang: _vm.contentLanguage
                                                      .name,
                                                  },
                                                  on: {
                                                    processDone:
                                                      _vm.processDone,
                                                    syncData: _vm.syncData,
                                                  },
                                                }),
                                              ]
                                            : flip.type === "multi_image"
                                            ? [
                                                _c("flip-multiimage", {
                                                  key: index,
                                                  attrs: {
                                                    findex: index,
                                                    total: _vm.totalPages,
                                                    datam:
                                                      _vm.flipsData[index]
                                                        .datam,
                                                    finalize: _vm.finalize,
                                                    lang: _vm.contentLanguage
                                                      .name,
                                                  },
                                                  on: {
                                                    syncData: _vm.syncData,
                                                    processDone:
                                                      _vm.processDone,
                                                    deleteFlipPage:
                                                      _vm.deleteFlipPage,
                                                  },
                                                }),
                                              ]
                                            : flip.type === "full_image"
                                            ? [
                                                _c("flip-fullimage", {
                                                  key: index,
                                                  attrs: {
                                                    findex: index,
                                                    total: _vm.totalPages,
                                                    datam:
                                                      _vm.flipsData[index]
                                                        .datam,
                                                    finalize: _vm.finalize,
                                                  },
                                                  on: {
                                                    processDone:
                                                      _vm.processDone,
                                                    syncData: _vm.syncData,
                                                    deleteFlipPage:
                                                      _vm.deleteFlipPage,
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                    _c(
                                      "div",
                                      {
                                        key: "create-button",
                                        staticClass: "outer-box",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "create-flip-page" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "imgplus",
                                                on: {
                                                  click: _vm.createMultiImage,
                                                },
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      width: "64",
                                                      height: "64",
                                                      "view-box": "0 0 24 24",
                                                      name: "cplus",
                                                      color: "#727d92",
                                                    },
                                                  },
                                                  [_c("icon-circleplus")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "card__footer" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "button button--border size-sm",
                                                    attrs: { role: "button" },
                                                    on: {
                                                      click:
                                                        _vm.createFullImage,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "or_upload_image"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "footer-content" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.items.length > 0,
                                  expression: "errors.items.length > 0",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert--card alert--error",
                                },
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(_vm.errors, function (error, index) {
                                      return _c("li", { key: index }, [
                                        _vm._v(" " + _vm._s(error.msg) + " "),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-flipfooter" }, [
                            _vm.journeyTotalUser > 0 &&
                            _vm.currentViewMode === "card" &&
                            _vm.$store.state.modal.launchedFrom !== "Contents"
                              ? _c("div", { staticClass: "dategroup pl-4" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        { staticClass: "u-zIndex-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "beginDate" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("begin_date"))
                                              ),
                                            ]
                                          ),
                                          _c("date-picker", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            class: {
                                              "is-danger": _vm.errors.has(
                                                "flipeditable.beginDate"
                                              ),
                                            },
                                            attrs: {
                                              "data-vv-scope": "flipeditable",
                                              "data-vv-name": "beginDate",
                                              "data-vv-as":
                                                _vm.$t("begin_date"),
                                              "value-type": "date",
                                              "first-day-of-week":
                                                _vm.userLang === "en" ? 7 : 1,
                                              lang: _vm.userLang,
                                              format:
                                                _vm.userLang === "en"
                                                  ? "MM.DD.YYYY"
                                                  : "DD.MM.YYYY",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.adjustTime("date")
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.beginActivationDateTime,
                                              callback: function ($$v) {
                                                _vm.beginActivationDateTime =
                                                  $$v
                                              },
                                              expression:
                                                "beginActivationDateTime",
                                            },
                                          }),
                                          _vm.errors.has(
                                            "flipeditable.beginDate"
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "flipeditable.beginDate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        { staticClass: "u-zIndex-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "beginTime" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("begin_time"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "date-picker",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              class: {
                                                "is-danger": _vm.errors.has(
                                                  "flipeditable.beginTime"
                                                ),
                                              },
                                              attrs: {
                                                format:
                                                  _vm.userLang === "en"
                                                    ? "hh:mm A"
                                                    : "HH:mm",
                                                type: "time",
                                                "value-type": "date",
                                                "data-vv-scope": "flipeditable",
                                                "data-vv-name": "beginTime",
                                                "data-vv-as":
                                                  _vm.$t("begin_time"),
                                                lang: _vm.userLang,
                                                "time-picker-options": {
                                                  start: "08:00",
                                                  step: "00:15",
                                                  end: "23:45",
                                                  format:
                                                    _vm.userLang === "en"
                                                      ? "hh:mm A"
                                                      : "HH:mm",
                                                },
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.adjustTime("time")
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.beginActivationDateTime,
                                                callback: function ($$v) {
                                                  _vm.beginActivationDateTime =
                                                    $$v
                                                },
                                                expression:
                                                  "beginActivationDateTime",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "icon-calendar" },
                                                [
                                                  _c(
                                                    "icon-container",
                                                    {
                                                      attrs: {
                                                        name: "time",
                                                        "view-box":
                                                          "0 0 512 512",
                                                      },
                                                    },
                                                    [_c("icon-time")],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm.errors.has(
                                            "flipeditable.beginTime"
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "flipeditable.beginTime"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            (_vm.currentViewMode === "weekly" ||
                              _vm.currentViewMode === "daily") &&
                            _vm.$store.state.modal.launchedFrom !== "Contents"
                              ? _c("div", { staticClass: "form-group pl-4" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-4" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-group u-zIndex-3",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "beginDate" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("begin_date_list")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.day,
                                                expression: "day",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-danger":
                                                _vm.errors.has(
                                                  "flipeditable.day"
                                                ),
                                            },
                                            attrs: {
                                              type: "number",
                                              id: "dayinput",
                                              min: "1",
                                              "data-vv-scope": "flipeditable",
                                              "data-vv-name": "day",
                                              "data-vv-as":
                                                _vm.isItJourneyTypeV2
                                                  ? _vm.$t("begin_day")
                                                  : _vm.$t("begin_date_list"),
                                            },
                                            domProps: { value: _vm.day },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.day = $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "daytext" },
                                            [_vm._v(_vm._s(_vm.$t("day")))]
                                          ),
                                          _vm.errors.has("flipeditable.day")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "flipeditable.day"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    !_vm.isItJourneyTypeV2
                                      ? _c(
                                          "div",
                                          { staticClass: "col-5" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-label",
                                                attrs: { for: "beforeOrAfter" },
                                              },
                                              [_vm._v(_vm._s())]
                                            ),
                                            _c("multi-select", {
                                              staticClass:
                                                "selectbox selectbox--secondary",
                                              attrs: {
                                                "track-by": "value",
                                                name: "beforeOrAfter",
                                                label: "value",
                                                "select-label": "",
                                                "allow-empty": false,
                                                multiple: false,
                                                searchable: false,
                                                "internal-search": false,
                                                "show-labels": false,
                                                taggable: false,
                                                "clear-on-select": false,
                                                options:
                                                  _vm.beforeOrAfterOptions,
                                              },
                                              model: {
                                                value: _vm.beforeOrAfter,
                                                callback: function ($$v) {
                                                  _vm.beforeOrAfter = $$v
                                                },
                                                expression: "beforeOrAfter",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "col-4" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-group u-zIndex-3",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "beginTime" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.isItJourneyTypeV2
                                                    ? _vm.$t("begin_time")
                                                    : _vm.$t("begin_time_list")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "date-picker",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              class: {
                                                "is-danger": _vm.errors.has(
                                                  "flipeditable.beginTime"
                                                ),
                                              },
                                              attrs: {
                                                format:
                                                  _vm.userLang === "en"
                                                    ? "hh:mm A"
                                                    : "HH:mm",
                                                type: "time",
                                                "data-vv-scope": "flipeditable",
                                                "data-vv-name": "beginTime",
                                                "data-vv-as":
                                                  _vm.isItJourneyTypeV2
                                                    ? _vm.$t("begin_time")
                                                    : _vm.$t("begin_time_list"),
                                                "value-type": "date",
                                                lang: _vm.userLang,
                                                "time-picker-options": {
                                                  start: "08:00",
                                                  step: "00:15",
                                                  end: "23:45",
                                                  format:
                                                    _vm.userLang === "en"
                                                      ? "hh:mm A"
                                                      : "HH:mm",
                                                },
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.adjustTime("time")
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.beginActivationDateTime,
                                                callback: function ($$v) {
                                                  _vm.beginActivationDateTime =
                                                    $$v
                                                },
                                                expression:
                                                  "beginActivationDateTime",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "icon-calendar" },
                                                [
                                                  _c(
                                                    "icon-container",
                                                    {
                                                      attrs: {
                                                        name: "time",
                                                        "view-box":
                                                          "0 0 512 512",
                                                      },
                                                    },
                                                    [_c("icon-time")],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm.errors.has(
                                            "flipeditable.beginTime"
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "flipeditable.beginTime"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-4" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "roleType" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("roleType")))]
                                        ),
                                        _c("multi-select", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "",
                                              expression: "''",
                                            },
                                          ],
                                          staticClass:
                                            "selectbox selectbox--secondary",
                                          class: {
                                            "is-danger":
                                              _vm.errors.has("all.roleType"),
                                          },
                                          attrs: {
                                            "track-by": "value",
                                            name: "roleType",
                                            label: "label",
                                            "clear-on-select": "",
                                            "deselect-label": "",
                                            "select-label": "",
                                            "data-vv-scope": "flipeditable",
                                            "data-vv-name": "roleType",
                                            options: _vm.roleTypeOptions,
                                            "data-vv-as": _vm.$t("roleType"),
                                          },
                                          model: {
                                            value: _vm.roleType,
                                            callback: function ($$v) {
                                              _vm.roleType = $$v
                                            },
                                            expression: "roleType",
                                          },
                                        }),
                                        _vm.errors.has("flipeditable.roleType")
                                          ? _c(
                                              "span",
                                              { staticClass: "help is-danger" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "flipeditable.roleType"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "setting-text" }, [
                              _c(
                                "div",
                                {
                                  class: [
                                    "panel-title",
                                    _vm.showAdvanceSettings ? "active" : "",
                                  ],
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showAdvanceSettings =
                                            !_vm.showAdvanceSettings
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("advanced_tab")))]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "button button--primary no-wrap",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isPending,
                                },
                                on: { click: _vm.nextSlide },
                              },
                              [
                                !_vm.isPending
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.editMode
                                              ? _vm.$t("edit")
                                              : _vm.$t("add")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.isPending
                                  ? _c("div", { staticClass: "lds-ellipsis" }, [
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "settings" },
                            [
                              _c(
                                "collapse-transition",
                                {
                                  attrs: { duration: 500, dimension: "height" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showAdvanceSettings,
                                          expression: "showAdvanceSettings",
                                        },
                                      ],
                                      staticClass: "px-4 bglight",
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-4" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-2" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "form-label",
                                                      attrs: { for: "info" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "info_label_sub"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "max:30",
                                                        expression: "'max:30'",
                                                      },
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.info,
                                                        expression: "info",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-danger":
                                                        _vm.errors.has(
                                                          "flipeditable.info"
                                                        ),
                                                    },
                                                    attrs: {
                                                      tabindex: "-1",
                                                      type: "text",
                                                      id: "info",
                                                      disabled: _vm.canNotEdit,
                                                      "data-vv-scope":
                                                        "flipeditable",
                                                      "data-vv-name": "info",
                                                    },
                                                    domProps: {
                                                      value: _vm.info,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.info =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                  _vm.errors.has(
                                                    "flipeditable.info"
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "help is-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "flipeditable.info"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 selecting-box",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "form-label",
                                                      attrs: {
                                                        for: "contentLang",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "content_lang_label_sub"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("multi-select", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "selectbox selectbox--secondary",
                                                    class: {
                                                      "is-danger":
                                                        _vm.errors.has(
                                                          "flipeditable.language"
                                                        ),
                                                    },
                                                    attrs: {
                                                      "track-by": "value",
                                                      name: "contentLang",
                                                      label: "value",
                                                      disabled: _vm.canNotEdit,
                                                      "clear-on-select": "",
                                                      "deselect-label": "",
                                                      "select-label": "",
                                                      "data-vv-scope":
                                                        "flipeditable",
                                                      "data-vv-name":
                                                        "language",
                                                      options:
                                                        _vm.contentLanguageOptions,
                                                      "data-vv-as":
                                                        _vm.$t(
                                                          "content_lang_label"
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contentLanguage,
                                                      callback: function ($$v) {
                                                        _vm.contentLanguage =
                                                          $$v
                                                      },
                                                      expression:
                                                        "contentLanguage",
                                                    },
                                                  }),
                                                  _vm.errors.has(
                                                    "flipeditable.language"
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "help is-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "flipeditable.language"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-3 mt-2" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "swicth-checkboxes",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.is_notifications_enabled,
                                                            expression:
                                                              "is_notifications_enabled",
                                                          },
                                                        ],
                                                        attrs: {
                                                          tabindex: "-1",
                                                          type: "checkbox",
                                                          id: "openedDays",
                                                          checked: "",
                                                          "true-value": 1,
                                                          "false-value": 0,
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.is_notifications_enabled
                                                            )
                                                              ? _vm._i(
                                                                  _vm.is_notifications_enabled,
                                                                  null
                                                                ) > -1
                                                              : _vm._q(
                                                                  _vm.is_notifications_enabled,
                                                                  1
                                                                ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.is_notifications_enabled,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? 1
                                                                : 0
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.is_notifications_enabled =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.is_notifications_enabled =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.is_notifications_enabled =
                                                                $$c
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-label pl-2",
                                                          attrs: {
                                                            for: "openedDays",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "send_push_notif"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "swicth-checkboxes",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.is_email_enabled,
                                                            expression:
                                                              "is_email_enabled",
                                                          },
                                                        ],
                                                        attrs: {
                                                          tabindex: "-1",
                                                          type: "checkbox",
                                                          id: "sendEmail",
                                                          "true-value": 1,
                                                          "false-value": 0,
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.is_email_enabled
                                                            )
                                                              ? _vm._i(
                                                                  _vm.is_email_enabled,
                                                                  null
                                                                ) > -1
                                                              : _vm._q(
                                                                  _vm.is_email_enabled,
                                                                  1
                                                                ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.is_email_enabled,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? 1
                                                                : 0
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.is_email_enabled =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.is_email_enabled =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.is_email_enabled =
                                                                $$c
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-label pl-2",
                                                          attrs: {
                                                            for: "sendEmail",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "send_email"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.isVibonsAdmin
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "swicth-checkboxes",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.is_public,
                                                                expression:
                                                                  "is_public",
                                                              },
                                                            ],
                                                            attrs: {
                                                              tabindex: "-1",
                                                              type: "checkbox",
                                                              id: "setPublic",
                                                              disabled:
                                                                _vm.canNotEdit,
                                                              "true-value": 1,
                                                              "false-value": 0,
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.is_public
                                                                )
                                                                  ? _vm._i(
                                                                      _vm.is_public,
                                                                      null
                                                                    ) > -1
                                                                  : _vm._q(
                                                                      _vm.is_public,
                                                                      1
                                                                    ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm.is_public,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? 1
                                                                      : 0
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      (_vm.is_public =
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        ))
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      (_vm.is_public =
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          ))
                                                                  }
                                                                } else {
                                                                  _vm.is_public =
                                                                    $$c
                                                                }
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-label pl-2",
                                                              attrs: {
                                                                for: "setPublic",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "is_public_content"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.showHideFromLxp
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "swicth-checkbox",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.hide_from_lxp,
                                                                expression:
                                                                  "hide_from_lxp",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "checkbox",
                                                              id: "hideFromLxp",
                                                              "true-value": 1,
                                                              "false-value": 0,
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.hide_from_lxp
                                                                )
                                                                  ? _vm._i(
                                                                      _vm.hide_from_lxp,
                                                                      null
                                                                    ) > -1
                                                                  : _vm._q(
                                                                      _vm.hide_from_lxp,
                                                                      1
                                                                    ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm.hide_from_lxp,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? 1
                                                                      : 0
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      (_vm.hide_from_lxp =
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        ))
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      (_vm.hide_from_lxp =
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          ))
                                                                  }
                                                                } else {
                                                                  _vm.hide_from_lxp =
                                                                    $$c
                                                                }
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "swicth-label",
                                                              attrs: {
                                                                for: "hideFromLxp",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "hidefromlxp"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        ),
                                        _c("div", { staticClass: "col-5" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-12" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                        attrs: {
                                                          for: "pushNotificationHeading",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "push_noti_head"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.push_notification_title,
                                                          expression:
                                                            "push_notification_title",
                                                        },
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: "max:40",
                                                          expression:
                                                            "'max:40'",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: {
                                                        "is-danger":
                                                          _vm.errors.has(
                                                            "flipeditable.push_notification_title"
                                                          ),
                                                      },
                                                      attrs: {
                                                        tabindex: "-1",
                                                        type: "text",
                                                        id: "pushNotificationHeading",
                                                        "data-vv-name":
                                                          "push_notification_title",
                                                        "data-vv-scope":
                                                          "flipeditable",
                                                        "data-vv-as":
                                                          _vm.$t(
                                                            "push_noti_head"
                                                          ),
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.push_notification_title,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.push_notification_title =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.dropdownIndex ===
                                                              "dropdownBoxtitle",
                                                            expression:
                                                              "dropdownIndex === 'dropdownBoxtitle'",
                                                          },
                                                          {
                                                            name: "on-clickaway",
                                                            rawName:
                                                              "v-on-clickaway",
                                                            value:
                                                              _vm.hideDropdown,
                                                            expression:
                                                              "hideDropdown",
                                                          },
                                                        ],
                                                        staticClass: "dropdown",
                                                        staticStyle: {
                                                          "z-index": "1000001",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown__menu emoji-dropdown-title",
                                                            attrs: {
                                                              id: "dropdownBoxtitle",
                                                            },
                                                          },
                                                          [
                                                            _c("VEmojiPicker", {
                                                              staticClass:
                                                                "emoji",
                                                              attrs: {
                                                                pack: _vm.emojiPack,
                                                                "label-search":
                                                                  _vm.$t(
                                                                    "search_placeholder"
                                                                  ),
                                                              },
                                                              on: {
                                                                select:
                                                                  _vm.SelectEmojiForTitle,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.errors.has(
                                                      "flipeditable.push_notification_title"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "help is-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "flipeditable.push_notification_title"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-12" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                        attrs: {
                                                          for: "pushNotificationBody",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "push_noti_body"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("textarea", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.push_notification_body,
                                                          expression:
                                                            "push_notification_body",
                                                        },
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: "max:110",
                                                          expression:
                                                            "'max:110'",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: {
                                                        "is-danger":
                                                          _vm.errors.has(
                                                            "flipeditable.push_notification_body"
                                                          ),
                                                      },
                                                      attrs: {
                                                        tabindex: "-1",
                                                        id: "pushNotificationBody",
                                                        "data-vv-name":
                                                          "push_notification_body",
                                                        "data-vv-scope":
                                                          "flipeditable",
                                                        "data-vv-as":
                                                          _vm.$t(
                                                            "push_noti_body"
                                                          ),
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.push_notification_body,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.push_notification_body =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.dropdownIndex ===
                                                              "dropdownBoxbody",
                                                            expression:
                                                              "dropdownIndex === 'dropdownBoxbody'",
                                                          },
                                                          {
                                                            name: "on-clickaway",
                                                            rawName:
                                                              "v-on-clickaway",
                                                            value:
                                                              _vm.hideDropdown,
                                                            expression:
                                                              "hideDropdown",
                                                          },
                                                        ],
                                                        staticClass: "dropdown",
                                                        staticStyle: {
                                                          "z-index": "1000001",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown__menu emoji-dropdown-body",
                                                            attrs: {
                                                              id: "dropdownBoxbody",
                                                            },
                                                          },
                                                          [
                                                            _c("VEmojiPicker", {
                                                              staticClass:
                                                                "emoji",
                                                              attrs: {
                                                                pack: _vm.emojiPack,
                                                                "label-search":
                                                                  _vm.$t(
                                                                    "search_placeholder"
                                                                  ),
                                                              },
                                                              on: {
                                                                select:
                                                                  _vm.SelectEmojiForBody,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.errors.has(
                                                      "flipeditable.push_notification_body"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "help is-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "flipeditable.push_notification_body"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "quiz-content" }, [
                    _c(
                      "div",
                      { staticClass: "quizcontent" },
                      [
                        _c(
                          "collapse-transition",
                          { attrs: { duration: 1, dimension: "height" } },
                          [
                            _vm.showWarningBox
                              ? _c("div", { staticClass: "warning-box" }, [
                                  _c(
                                    "div",
                                    { staticClass: "desc-text" },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: "27",
                                            height: "30",
                                            "view-box": "0 0 27 30",
                                            name: "lamp",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-lamp")],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "description" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("quiz_remark_long")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "main",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showWarningBox,
                                expression: "!showWarningBox",
                              },
                            ],
                            staticClass: "quiz-main",
                            attrs: { id: "scrollbar-quiz" },
                          },
                          [
                            _c(
                              "draggable-comp",
                              _vm._b(
                                {
                                  attrs: {
                                    draggable: ".draggable",
                                    handle: ".quizbox",
                                    delay: "1",
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.isDragging = true
                                    },
                                    end: function ($event) {
                                      _vm.isDragging = false
                                    },
                                  },
                                  model: {
                                    value: _vm.questions,
                                    callback: function ($$v) {
                                      _vm.questions = $$v
                                    },
                                    expression: "questions",
                                  },
                                },
                                "draggable-comp",
                                _vm.quizDragOptions,
                                false
                              ),
                              [
                                _c(
                                  "transition-group",
                                  {
                                    class: "row",
                                    style:
                                      _vm.questions.length > 3
                                        ? "justify-content: flex-start"
                                        : "justify-content: center",
                                    attrs: {
                                      appear: "",
                                      type: "transition",
                                      name: "slide-in",
                                      tag: "div",
                                      id: "quizlist",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.questions,
                                      function (quiz, qindex) {
                                        return _c(
                                          "div",
                                          {
                                            key: qindex + "quiz",
                                            staticClass: "quizbox draggable",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "listing form-label",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      width: "18",
                                                      height: "10",
                                                      "view-box": "0 0 18 12",
                                                      name: "threelines",
                                                      color: "#727d92",
                                                      "is-icon-class": false,
                                                    },
                                                  },
                                                  [_c("icon-threelines")],
                                                  1
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      qindex +
                                                        1 +
                                                        _vm.$t("of") +
                                                        _vm.totalQuizPages
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "quizes",
                                                attrs: { id: "quiz" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "quiz-preview--progress",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "quiz-progress-bar",
                                                      },
                                                      [
                                                        _c("span", {
                                                          style: {
                                                            width:
                                                              (100 /
                                                                _vm.questions
                                                                  .length) *
                                                                (qindex + 1) +
                                                              "%",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-question",
                                                  },
                                                  [
                                                    _c("textarea", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: quiz.question,
                                                          expression:
                                                            "quiz.question",
                                                        },
                                                        {
                                                          name: "validate",
                                                          rawName:
                                                            "v-validate.disable",
                                                          value: {
                                                            required: true,
                                                            max: 200,
                                                            min: 5,
                                                            minAnswer: quiz,
                                                            oneAnswer: quiz,
                                                          },
                                                          expression:
                                                            "{\n                                  required: true,\n                                  max: 200,\n                                  min:5,\n                                  minAnswer: quiz,\n                                  oneAnswer: quiz,\n                                }",
                                                          modifiers: {
                                                            disable: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control textaream",
                                                      class: {
                                                        "is-danger":
                                                          _vm.errors.has(
                                                            `quiz.question${qindex}`
                                                          ),
                                                      },
                                                      attrs: {
                                                        tabindex: "-1",
                                                        type: "text",
                                                        placeholder: _vm.$t(
                                                          "question_placeholder"
                                                        ),
                                                        "data-vv-scope":
                                                          "fliquiz",
                                                        name:
                                                          "question" + qindex,
                                                        "data-vv-as":
                                                          _vm.$t("question_") +
                                                          (qindex + 1),
                                                      },
                                                      domProps: {
                                                        value: quiz.question,
                                                      },
                                                      on: {
                                                        keydown: _vm.autoSizeMe,
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            quiz,
                                                            "question",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "ul",
                                                  _vm._l(
                                                    quiz.choices,
                                                    function (choice, cindex) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key:
                                                            cindex + "choice",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  choice.choice_text,
                                                                expression:
                                                                  "choice.choice_text",
                                                              },
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required|max:100",
                                                                expression:
                                                                  "'required|max:100'",
                                                              },
                                                            ],
                                                            key: `${qindex}${cindex}`,
                                                            class:
                                                              choice.is_correct
                                                                ? "form-control iscorrect"
                                                                : "form-control",
                                                            attrs: {
                                                              tabindex: "-1",
                                                              type: "text",
                                                              name: "choice",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "answer_placeholder"
                                                                ),
                                                              "data-vv-scope":
                                                                "fliquiz",
                                                              "data-vv-name": `answer${qindex}${cindex}`,
                                                              "data-vv-as":
                                                                _vm.$t(
                                                                  "answer"
                                                                ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                choice.choice_text,
                                                            },
                                                            on: {
                                                              input: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  )
                                                                    return
                                                                  _vm.$set(
                                                                    choice,
                                                                    "choice_text",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.addAnswerItem(
                                                                    qindex,
                                                                    cindex
                                                                  )
                                                                },
                                                              ],
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "choice-bar",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "checkbox",
                                                                  staticStyle: {
                                                                    "margin-bottom":
                                                                      "0",
                                                                  },
                                                                  attrs: {
                                                                    for:
                                                                      qindex +
                                                                      "-answers",
                                                                  },
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            choice.is_correct,
                                                                          expression:
                                                                            "choice.is_correct",
                                                                        },
                                                                      ],
                                                                    key:
                                                                      qindex +
                                                                      "-answers",
                                                                    attrs: {
                                                                      tabindex:
                                                                        "-1",
                                                                      type: "checkbox",
                                                                      id: cindex,
                                                                      name:
                                                                        qindex +
                                                                        "-answers",
                                                                      "true-value": 1,
                                                                      "false-value": 0,
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          choice.is_correct
                                                                        )
                                                                          ? _vm._i(
                                                                              choice.is_correct,
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : _vm._q(
                                                                              choice.is_correct,
                                                                              1
                                                                            ),
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              choice.is_correct,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? 1
                                                                                : 0
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  choice,
                                                                                  "is_correct",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  choice,
                                                                                  "is_correct",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              choice,
                                                                              "is_correct",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "correct_answer"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              quiz.choices
                                                                .length > 1
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeAnswerItem(
                                                                              cindex,
                                                                              qindex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "icon-container",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              width: 20,
                                                                              height: 20,
                                                                              "view-box":
                                                                                "0 0 25 25",
                                                                              name: "trash",
                                                                              color:
                                                                                "#727d92",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "icon-trash"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "trashIcon",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteQuizPage(
                                                      qindex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      width: 20,
                                                      height: 20,
                                                      "view-box": "0 0 24 24",
                                                      name: "trash",
                                                      color: "#727d92",
                                                    },
                                                  },
                                                  [_c("icon-trash")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                key: "drop-placeholder",
                                                staticClass:
                                                  "card card--catalog-item drop-placeholder",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card__body" },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("drag_drop")
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _c(
                                      "div",
                                      {
                                        key: "create-quiz",
                                        staticClass: "outer-box",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "create-flip-page" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "imgplus",
                                                on: {
                                                  click: _vm.createQuestion,
                                                },
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      width: "64",
                                                      height: "64",
                                                      "view-box": "0 0 24 24",
                                                      name: "cplus",
                                                      color: "#727d92",
                                                    },
                                                  },
                                                  [_c("icon-circleplus")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "card__footer" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "button button--border size-sm",
                                                    attrs: { role: "button" },
                                                    on: {
                                                      click: _vm.importQuestion,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "or_import_questions"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "footer-content" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.items.length > 0,
                                  expression: "errors.items.length > 0",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert--card alert--error",
                                },
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(_vm.errors, function (error, index) {
                                      return _c("li", { key: index }, [
                                        _vm._v(" " + _vm._s(error.msg) + " "),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-quizfooter" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.showWarningBox,
                                    expression: "!showWarningBox",
                                  },
                                ],
                                staticClass: "desc-text",
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      width: "27",
                                      height: "30",
                                      "view-box": "0 0 27 30",
                                      name: "lamp",
                                      color: "#727d92",
                                    },
                                  },
                                  [_c("icon-lamp")],
                                  1
                                ),
                                _c("div", { staticClass: "description" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("quiz_remark")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "button button--primary no-wrap",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isQuizPending,
                                },
                                on: { click: _vm.completeAll },
                              },
                              [
                                !_vm.isQuizPending
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.editMode
                                              ? _vm.$t("update_button")
                                              : _vm.$t("create_button")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.isQuizPending
                                  ? _c("div", { staticClass: "lds-ellipsis" }, [
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }